import React, { useState, useEffect } from "react";
import { Container, Row, Col, CardBody } from "reactstrap";
import "react-toastify/dist/ReactToastify.css";

import { useNavigate } from "react-router-dom";
import { API_PRODUCT, API_QUOTE } from "../redux/apiRoutes";
import axios from "axios";

import { jsPDF } from "jspdf";

import PhotoAlbum from "react-photo-album";

import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";

// import optional lightbox plugins
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import { File, Printer } from "react-feather";
import { estado } from "../utils/functions";

const Bom = () => {
  const [currentQuote, setcurrentQuote] = useState(null);
  const [boom, setBom] = useState([]);

  const [getParamsUrl, setgetparamsUrl] = useState(false);
  const url = new URL((typeof document === "undefined") ? 'http://localhost' : document.URL);
  const navigate = useNavigate();

  const setURLParams = () => {
    if(url.searchParams.get("uuid")){
      getQuoteData(url.searchParams.get("uuid"));
    }else{
      navigate('/inicio');
    }
  }

  const getQuoteData = async (uuid,type) => {
    await axios.get(`${API_QUOTE}?uuid=${uuid}`).then(async response => {
      if(response.data.docs.length>0){
        setcurrentQuote(response.data.docs[0]);

        let bom = response.data.docs[0].bom ? response.data.docs[0].bom : [];
        const newBom = [];
        await Promise.all(bom.map(async(b)=>{
          await axios.get(`${API_PRODUCT}?serie=${b.key}`).then(response => {
            if(response.data.docs.length>0){
              let newBomItem = b;
              newBomItem.picture = response.data.docs[0].pictures ? response.data.docs[0].pictures[0] : null;
              newBomItem.exist = response.data.docs[0].qty;
              newBomItem.location = response.data.docs[0].locations;
              newBomItem.needs = b.qty < response.data.docs[0].qty ? 0 : b.qty - response.data.docs[0].qty;
              newBom.push(newBomItem);
            }else{
              let newBomItem = b;
              newBomItem.picture = null;
              newBomItem.exist = 0;
              newBomItem.location = 'Sin existencias';
              newBomItem.needs = b.qty;
              newBom.push(newBomItem);
            }
          });
        }));
        setBom(newBom);
      }else{
        navigate('/inicio');
      }
    }).catch(e => {
      console.log(e);
      //navigate('/inicio');
    });
  }

  const handlePrint = () => {
    window.print();
  }

  useEffect(() => {
    if(!getParamsUrl){
        setURLParams();
        setgetparamsUrl(true);
    }
  }, [getParamsUrl,setURLParams,setgetparamsUrl]);

  return (
    <div className="page-wrapper" style={{background:'#444'}}>
      <Container fluid={true} className="p-0">
        <div className="m-0">
          <Row>
            <Col md="12" style={{minWidth:700}}>
            {currentQuote !== null ? 
              <CardBody>
                <div className="mt-4 mb-4 cont text-center b-light">
                  <Row className="mb-3">
                    <Col md="3" xs="3">
                      <img src={require("../assets/images/logo/logo-ciesa.png")} alt="Ciesa" width={150} />
                    </Col>
                    <Col md="9" xs="9">
                      <h4 className="font-weight-bold">BOM - COTIZACION No. {currentQuote.quoteId}</h4>
                      <table border={0} style={{width:'100%'}}>
                        <tr>
                          <td className="font-weight-bold" style={{border:'1px solid black'}}>Fecha:</td>
                          <td style={{border:'1px solid black'}}>{currentQuote.createdAt.split('T')[0]}</td>
                          <td style={{border:'1px solid black'}} className="font-weight-bold">Cliente:</td>
                          <td style={{border:'1px solid black'}}>{currentQuote.customer[0].name}</td>
                        </tr>
                      </table>
                    </Col>
                  </Row>
                  <Row className="mb-3 p-4">
                    <table border={1}>
                      <tr>
                        <td className="font-weight-bold" style={{border:'1px solid black', padding:8, fontSize:12}}>FOTO</td>
                        <td className="font-weight-bold" style={{border:'1px solid black', padding:8, fontSize:12}}>NO. PARTE</td>
                        <td style={{border:'1px solid black', padding:8, fontSize:12}} className="font-weight-bold">DESCRIPCIÓN</td>
                        <td style={{border:'1px solid black', padding:8, fontSize:12}} className="font-weight-bold">CANTIDAD</td>
                        <td style={{border:'1px solid black', padding:8, fontSize:12}} className="font-weight-bold">EXISTENCIA</td>
                        <td style={{border:'1px solid black', padding:8, fontSize:12}} className="font-weight-bold">FALTANTES</td>
                        <td style={{border:'1px solid black', padding:8, fontSize:12}} className="font-weight-bold">UBICACIONES</td>
                        {/* <td style={{border:'1px solid black', padding:8, fontSize:12}} className="font-weight-bold">ACCIONES</td> */}
                      </tr>
                      {boom !== null && boom !== undefined ?
                      boom.map(item =>
                        <tr>
                          <td className="font-weight-bold" style={{border:'1px solid black', padding:8, fontSize:12}}>{item.picture !== null ? <a href={item.picture} target="_blank"><img src={item.picture} style={{maxHeight:100, maxWidth:100}}></img></a> : ''}</td>
                          <td className="font-weight-bold" style={{border:'1px solid black', padding:8, fontSize:12}}>{item.key}</td>
                          <td style={{border:'1px solid black', padding:8, fontSize:12}} className="font-weight-bold">{item.description}</td>
                          <td style={{border:'1px solid black', padding:8, fontSize:12}} className="font-weight-bold">{item.qty}</td>
                          <td style={{border:'1px solid black', padding:8, fontSize:12}} className="font-weight-bold">{item.exist}</td>
                          <td style={{border:'1px solid black', padding:8, fontSize:12}} className="font-weight-bold">{item.needs > 0 ? <b style={{color:'red'}}>{item.needs}</b> : item.needs}</td>
                          <td style={{border:'1px solid black', padding:8, fontSize:12}} className="font-weight-bold">{item.location}</td>
                          {/* <td style={{border:'1px solid black', padding:8, fontSize:12}} className="font-weight-bold">ACCIONES</td> */}
                        </tr>)
                      : null }
                    </table>
                  </Row>
                  <Row className="mb-3 p-3">
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                  </Row>
                </div>
              </CardBody>
            : null }
            </Col>
          </Row>
        </div>

        <div className="hidePrint" onClick={handlePrint} style={{position: 'fixed', fontWeight:'bold', background: 'black', padding: 15, borderRadius: 50, color: 'white', width: 60, height:60, right: 30, bottom: 30, cursor: 'pointer', zIndex:100}}>
          <Printer size={30} />
        </div>
      </Container>
    </div>
  );
};

export default Bom;
