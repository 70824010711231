import React, { Fragment, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Button, Card, CardBody, Col, Container, Form, Label, Modal, ModalBody, ModalHeader, Nav, Row,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Alert, } from "reactstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import axios from "axios";
import { API_QUOTE, API_CUSTOMER, API_USER, API_ITEM } from "../../../redux/apiRoutes";
import { toast } from "react-toastify";
import DataTable from "react-data-table-component";
import Breadcrumb from "../../common/breadcrumb/breadcrumb";
import { useForm } from "react-hook-form";
import { v4 as uuidv4 } from 'uuid';

import CKEditors from "react-ckeditor-component";

import FilterComponent from "../../common/filter";
import SweetAlert from "sweetalert2";
import { quoteEstado } from "../../../utils/functions";

const Quotes = () => {
  const user = useSelector((content) => content.User);
  const navigate = useNavigate();

  const [PrimarycolorLineTab, setPrimarycolorLineTab] = useState("1");
  const [logoCiesa, setlogoCiesa] = useState("https://ciesa.site/logo192.png");
  
  const [modal, setModal] = useState(false);
  const toggle = () => {setModal(!modal);  reset(); setEditinEvent(false); clearModal();};

  const clearModal = () => {
    setCustomerSelected([]);
    setCurrentQuote({});
    setUserSelected([]);
    setEditDocument(true);
    setlogoCiesa("https://ciesa.site/logo192.png");
    setPrimarycolorLineTab('1');

    setvalveSetted(false);
    setvalveError(false);
    setactSetted(false);
    setactError(false);
    setposSetted(false);
    setposError(false);
    setValveTotal(0);
    setValvePrice(0);
    setactTotal(0);
    setactPrice(0);
    setposTotal(0);
    setposPrice(0);
    setsubTotal(0);
    settotal(0);

    setqty("");
    setstatusQuote("PENDING");
    setpriceType("LIST");
    setcreditType("100_FULL");
    setvalveType("");
    setbodyMaterial("");
    setvalveSize("");
    setvalvePackage("");
    setvalveTrim("");
    setvalvePort("");
    setvalveBonet("");
    setvalveAsient("");
    setvalveRating("");
    setvalveChar("");
    setactType("");
    setactSize("");
    setactRange("");
    setactIncludes("");
    setposType("");
    setposCom("");
    setposDia("");
    setposOpc("");
    setposReg("");
    setposIncludes("");
    setvalveCode("");
    setactCode("");
    setposCode("");

    setParts([]);
  }

  const [actIncludes, setactIncludes] = useState("");
  const changeactIncludes = (evt) => {
    const newContent = evt.editor.getData();
    setactIncludes(newContent);
  };

  const [posIncludes, setposIncludes] = useState("");
  const changeposIncludes = (evt) => {
    const newContent = evt.editor.getData();
    setposIncludes(newContent);
  };

  const [userSelected,setUserSelected] = useState([]);
  const [dataUsers,setdataUsers] = useState([]);
  const getUsersList = async () => {
    await axios.get(`${API_USER}?status=ACTIVE&role=COTIZADOR,ADMIN,GERENTE`).then(response => {
      if(response.data.docs.length>0){
        const users = [];
        response.data.docs.map(user=>{
          const addUser = {
            uuid: user.uuid,
            name: user.name,
            eventAmount: user.eventAmount,
          }
          users.push(addUser);
        });
        setdataUsers(users);
      }
    }).catch(e => {
      toast.error("No se pudo obtener el listado de Usuarios: " + e.message, {position: 'bottom-right', style:{color:'white'}});
    });
  }

  const [customerSelected,setCustomerSelected] = useState([]);
  const [dataCustomers,setdataCustomers] = useState([]);
  const getCustomersList = async () => {
    await axios.get(`${API_CUSTOMER}?status=ACTIVE`).then(response => {
      if(response.data.docs.length>0){
        const customers = [];
        response.data.docs.map(customer=>{
          const addCustomer = {
            uuid: customer.uuid,
            name: customer.name,
            price: customer.price,
            products: customer.products,
          }
          customers.push(addCustomer);
        });
        setdataCustomers(customers);
      }
    }).catch(e => {
      toast.error("No se pudo obtener el listado de Servicios: " + e.message, {position: 'bottom-right', style:{color:'white'}});
    });
  }

  const [dataQuoteList, setDataQuoteList] = useState([]);
  const [isGetEventList, setIsGetEventList] = useState(false);
  const [editPermissions, setEditPermissions] = useState(false);
  const getQuoteList = async () => {
    setIsGetEventList(true);

    let queryRule = '';
    if(user.role==='ADMIN' || user.role==='SISTEMAS' || user.role === "GERENTE"){
      setEditPermissions(true);
    }

    if(user.role === "COTIZADOR"){
      queryRule = 'owner='+user.uuid;
      setEditPermissions(false);
    }
    
    await axios.get(`${API_QUOTE}?${queryRule}`).then(response => {
      if(response.data.docs.length>0){
        setDataQuoteList(response.data.docs);
      }else{
        setDataQuoteList([]);
      }
      setIsGetEventList(false);
    }).catch(e => {
      setIsGetEventList(false);
      toast.error("No se pudo obtener el listado de Cotizaciones: " + e.message, {position: 'bottom-right', style:{color:'white'}});
    });
  }

  const columns = [
    {
      name: "Id Cotización",
      selector: (row) => <b>{row.quoteId}</b>,
      sortable: true,
    },
    {
      name: "Cliente",
      selector: (row) => row.customer !== null && row.customer !== undefined && row.customer.length > 0 ? <b>{row.customer[0].name}</b> : '-',
      sortable: true,
      center: true,
    },
    {
      name: "Responsable",
      selector: (row) => row.owner !== null && row.owner !== undefined && row.owner.length > 0 ? <b>{row.owner[0].name}</b> : '-',
      sortable: true,
      center: true,
    },
    {
      name: "Estado",
      selector: (row) => quoteEstado(row.status),
      sortable: true,
      center: true,
    },
    {
      name: "Fecha",
      selector: (row) => row.createdAt ? new Date(row.createdAt).getDate() + '-' + (new Date(row.createdAt).getMonth() + 1) + '-' + new Date(row.createdAt).getFullYear() : '--',
      sortable: true,
      center: true,
    },
    {
      name: "Total",
      selector: (row) => row.subTotal !== null && row.subTotal !== undefined ? '$'+row.subTotal.toFixed(2) : '-',
      sortable: true,
      center: true,
    },
    {
      name: "Acciones",
      selector: (row) => <div style={{padding:5}}>
                          <Button color="light" style={{width: 40, padding: 6, marginRight:5, fontSize: 14}} onClick={()=>{handleEditAction(row)}}>{row.edit ? <i className="fa fa-pencil"></i> : <i className="fa fa-pencil"></i>}</Button>
                          {editPermissions?
                          <Button color="light" style={{width: 40, padding: 6, marginRight:5, fontSize: 14}} onClick={()=>{handleDeleteAction(row)}}><i className="fa fa-trash"></i></Button> : null}
                        </div>,
      width: '160px'
    },
  ];

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm();

  const [savingEvent, setSaveQuote] = useState(false);
  const saveQuote = async (data) => {
    setSaveQuote(true);
    if (data !== "") {
      if(customerSelected.length<=0){
        toast.error("Asigna un cliente", {position: 'bottom-right', style:{color:'white'}});
        setSaveQuote(false);
        return;
      }

      if(userSelected.length<=0){
        toast.error("Asigna un responsable", {position: 'bottom-right', style:{color:'white'}});
        setSaveQuote(false);
        return;
      }

      data.uuid = uuidv4();
      data.customer = customerSelected;
      data.owner = userSelected;
      data.valTotal = valveTotal;
      data.valPrice = valvePrice;
      data.valSetted = valveSetted;
      data.valError = valveError;
      data.actTotal = actTotal;
      data.actIncludes = actIncludes;
      data.posIncludes = posIncludes;
      data.actPrice = actPrice;
      data.actSetted = actSetted;
      data.actError = actError;
      data.posTotal = posTotal;
      data.posPrice = posPrice;
      data.posSetted = posSetted;
      data.posError = posError;
      data.subTotal = subTotal;
      data.total = total;
      data.status = statusQuote;
      data.valveCode = valveCode;
      data.actCode = actCode;
      data.posCode = posCode;
      
      await axios.post(`${API_QUOTE}`,data).then(response => {
        if(response.data){
          getQuoteList();
          setEditinEvent(true);
          toast.success("¡Cotización guardada con éxito! Continua Editando", {position: 'bottom-right', style:{color:'white'}});
        }
        setSaveQuote(false);
        setCurrentQuote(response.data);
      }).catch(e => {
        setSaveQuote(false);
        toast.error("No se pudo crear la cotización: " + e.message, {position: 'bottom-right', style:{color:'white'}});
      });
    } else {
      errors.showMessages();
    }
  };

  const [currentQuote, setCurrentQuote] = useState({});
  const [editDocument, setEditDocument] = useState(true);
  const handleEditAction = (event) => {
    toggle();
    setEditinEvent(true);
    setCurrentQuote(event);
    
    if(event.customer!==undefined && event.customer !== null && event.customer.length>0){
      dataCustomers.map((customer)=>{
        if(customer.uuid===event.customer[0].uuid){
          setCustomerSelected([customer]);
        }
      });
    }

    if(event.owner!==undefined && event.owner !== null){
      dataUsers.map((user)=>{
        if(user.uuid===event.owner[0].uuid){
          setUserSelected([user]);
        }
      });
    }

    populate(event);
    
    setTimeout(()=>{
      setValue('quoteId', event.quoteId);
      setValue('qty', event.qty);
      setValue('part', event.part);
      setValue('priceType', event.priceType);
      setValue('contact', event.contact);
      setValue('creditType', event.creditType);
      setValue('estimatedTime', event.estimatedTime);
      setValue('valveDescription', event.valveDescription);
      setValue('valveBrand', event.valveBrand);
      setValue('valveType', event.valveType);
      setValue('bodyMaterial', event.bodyMaterial);
      setValue('valveSize', event.valveSize);
      setValue('valvePackage', event.valvePackage);
      setValue('valveTrim', event.valveTrim);
      setValue('valvePort', event.valvePort);
      setValue('valveBonet', event.valveBonet);
      setValue('valveAsient', event.valveAsient);
      setValue('valveRating', event.valveRating);
      setValue('valveChar', event.valveChar);
      setValue('actDescription', event.actDescription);
      setValue('actBrand', event.actBrand);
      setValue('actType', event.actType);
      setValue('actSize', event.actSize);
      setValue('actRange', event.actRange);
      setValue('posType', event.posType);
      setValue('posCom', event.posCom);
      setValue('posDia', event.posDia);
      setValue('posOpc', event.posOpc);
      setValue('posReg', event.posReg);
      setValue('logo', event.logo !== "" && event.logo !== null && event.logo !== undefined ? event.logo : logoCiesa);
    },500)
  }

  const populate = (event) => {
    setqty(event.qty);

    setvalveSetted(event.valSetted);
    setactSetted(event.actSetted);
    setposSetted(event.posSetted);
    setvalveError(event.valError);
    setactError(event.actError);
    setposError(event.posError);
    setValveTotal(event.valTotal);
    setactTotal(event.actTotal);
    setactIncludes(event.actIncludes);
    setposIncludes(event.posIncludes)
    setposTotal(event.posTotal);
    setValvePrice(event.valPrice);
    setactPrice(event.actPrice);
    setposPrice(event.posPrice);
    setsubTotal(event.subTotal);
    settotal(event.total);

    setstatusQuote(event.status);
    setpriceType(event.priceType);
    setcreditType(event.creditType);
    setvalveType(event.valveType);
    setbodyMaterial(event.bodyMaterial);
    setvalveSize(event.valveSize);
    setvalvePackage(event.valvePackage);
    setvalveTrim(event.valveTrim);
    setvalvePort(event.valvePort);
    setvalveBonet(event.valveBonet);
    setvalveAsient(event.valveAsient);
    setvalveRating(event.valveRating);
    setvalveChar(event.valveChar);
    setactType(event.actType);
    setactSize(event.actSize);
    setactRange(event.actRange);
    setposType(event.posType);
    setposCom(event.posCom);
    setposDia(event.posDia);
    setposOpc(event.posOpc);
    setposReg(event.posReg);
    setvalveCode(event.valveCode);
    setactCode(event.actCode);
    setposCode(event.posCode);

    if(event.bom!==null && event.bom !== undefined){
      setParts(event.bom);
    }
  }

  const [isEditingEvent, setEditinEvent] = useState(false);
  const [patchingEvent, setPatchingEvent] = useState(false);
  const patchEvent = async (data) => {
    setPatchingEvent(true);

    if (data !== "") {
      if(customerSelected.length<=0){
        toast.error("Asigna un cliente", {position: 'bottom-right', style:{color:'white'}});
        setSaveQuote(false);
        return;
      }

      if(userSelected.length<=0){
        toast.error("Asigna un responsable", {position: 'bottom-right', style:{color:'white'}});
        setSaveQuote(false);
        return;
      }

      data.customer = customerSelected;
      data.owner = userSelected;
      data.valTotal = valveTotal;
      data.valPrice = valvePrice;
      data.valSetted = valveSetted;
      data.valError = valveError;
      data.actTotal = actTotal;
      data.actPrice = actPrice;
      data.actSetted = actSetted;
      data.actError = actError;
      data.actIncludes = actIncludes;
      data.posIncludes = posIncludes;
      data.posTotal = posTotal;
      data.posPrice = posPrice;
      data.posSetted = posSetted;
      data.posError = posError;
      data.subTotal = subTotal;
      data.total = total;
      data.status = statusQuote;
      data.valveCode = valveCode;
      data.actCode = actCode;
      data.posCode = posCode;

      if(parts.length>0 && valveCode !== ""){
        data.bom = parts;

        await axios.get(`${API_ITEM}?country=MX&type=VAL&key=${valveCode}`).then(async response => {
          if(response.data.docs.length>0){
            let bom = response.data.docs[0].bom ? response.data.docs[0].bom : [];
            let newBoms = [];

            parts.map((part)=>{
              let exists = false;

              bom.map(b=>{
                if(b.key===part.key){
                  b.qty = part.qty;
                  newBoms.push(b);
                  exists = true;
                }
              });

              if(!exists){
                newBoms.push(part);
              }
            });

            await axios.patch(`${API_ITEM}/bom/${response.data.docs[0].uuid}`,newBoms);
          }
        }).catch(e => {
          setPatchingEvent(false);
          toast.error("No se pudo actualizar el BOM de la válcula: " + e.message, {position: 'bottom-right', style:{color:'white'}});
        });
      }

      await axios.patch(`${API_QUOTE}/${currentQuote.uuid}`,data).then(response => {
        if(response.data){
          getQuoteList();
          setPatchingEvent(false);
          toast.success("¡Cotización actualizada con éxito!", {position: 'bottom-right', style:{color:'white'}});
        }
        setPatchingEvent(false);
      }).catch(e => {
        setPatchingEvent(false);
        toast.error("No se pudo actualizar la cotización: " + e.message, {position: 'bottom-right', style:{color:'white'}});
      });
    } else {
      errors.showMessages();
    }
  }

  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(
    false
  );

  const handleDeleteAction = (data) => {
    SweetAlert.fire({
      title: "¿Continuamos?",
      text: `Se eliminará la cotización para: ${data.customer[0].name} la información se borrará, la acción es irreversible.`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Si",
      cancelButtonText: "Cancelar",
      reverseButtons: true,
    }).then(async (result) => {
      if (result.value) {

        await axios.delete(`${API_QUOTE}/${data.uuid}`).then(response => {
          getQuoteList();
          SweetAlert.fire("¡Listo!", "cotización eliminada", "success");
        }).catch(error => {
          setTimeout(() => {
            toast.error(
              "Error al eliminar la cotización: " + error
            );
          }, 200);
        });
      }
    });
  }

  const filteredItems = dataQuoteList.filter(
    item =>
      JSON.stringify(item)
        .toLowerCase()
        .indexOf(filterText.toLowerCase()) !== -1
  );

  const subHeaderComponent = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <FilterComponent
        onFilter={e => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  const [statusQuote, setstatusQuote] = useState('PENDING');
  const [priceType, setpriceType] = useState('LIST');
  const [creditType, setcreditType] = useState('100_FULL');

  const [valveType, setvalveType] = useState('');
  const [bodyMaterial, setbodyMaterial] = useState('');
  const [valveSize, setvalveSize] = useState('');
  const [valvePackage, setvalvePackage] = useState('');
  const [valveTrim, setvalveTrim] = useState('');
  const [valvePort, setvalvePort] = useState('');
  const [valveBonet, setvalveBonet] = useState('');
  const [valveAsient, setvalveAsient] = useState('');
  const [valveRating, setvalveRating] = useState('');
  const [valveChar, setvalveChar] = useState('');

  const [valveLoading, setValveLoading] = useState(false);
  const [valveSetted, setvalveSetted] = useState(false);
  const [valveError, setvalveError] = useState(false);
  const [valveCode, setvalveCode] = useState("");
  const [valveTotal, setValveTotal] = useState(0);
  const [valvePrice, setValvePrice] = useState(0);

  const [actLoading, setactLoading] = useState(false);
  const [actType, setactType] = useState('');
  const [actSize, setactSize] = useState('');
  const [actRange, setactRange] = useState('');

  const [actSetted, setactSetted] = useState(false);
  const [actError, setactError] = useState(false);
  const [actCode, setactCode] = useState("");
  const [actTotal, setactTotal] = useState(0);
  const [actPrice, setactPrice] = useState(0);

  const [posSetted, setposSetted] = useState(false);
  const [posError, setposError] = useState(false);
  const [posCode, setposCode] = useState("");
  const [posTotal, setposTotal] = useState(0);
  const [posPrice, setposPrice] = useState(0);

  const [posLoading, setposLoading] = useState(false);
  const [posType, setposType] = useState('');
  const [posCom, setposCom] = useState('');
  const [posDia, setposDia] = useState('');
  const [posOpc, setposOpc] = useState('');
  const [posReg, setposReg] = useState('');

  const [subTotal, setsubTotal] = useState(0);
  const [qty, setqty] = useState(0);
  const [total, settotal] = useState(0);

  const getvalvePrice = async (tag,value) => {
    let valveKey ='';

    switch(tag){
      case 'valveType':
        setvalveType(value);
        valveKey = value+bodyMaterial+valveSize+valvePackage+valveTrim+valvePort+valveBonet+valveAsient+valveRating+valveChar;
      break;
      case 'bodyMaterial':
        setbodyMaterial(value);
        valveKey = valveType+value+valveSize+valvePackage+valveTrim+valvePort+valveBonet+valveAsient+valveRating+valveChar;
      break;
      case 'valveSize':
        setvalveSize(value);
        valveKey = valveType+bodyMaterial+value+valvePackage+valveTrim+valvePort+valveBonet+valveAsient+valveRating+valveChar;
      break;
      case 'valvePackage':
        setvalvePackage(value);
        valveKey = valveType+bodyMaterial+valveSize+value+valveTrim+valvePort+valveBonet+valveAsient+valveRating+valveChar;
      break;
      case 'valveTrim':
        setvalveTrim(value);
        valveKey = valveType+bodyMaterial+valveSize+valvePackage+value+valvePort+valveBonet+valveAsient+valveRating+valveChar;
      break;
      case 'valvePort':
        setvalvePort(value);
        valveKey = valveType+bodyMaterial+valveSize+valvePackage+valveTrim+value+valveBonet+valveAsient+valveRating+valveChar;
      break;
      case 'valveBonet':
        setvalveBonet(value);
        valveKey = valveType+bodyMaterial+valveSize+valvePackage+valveTrim+valvePort+value+valveAsient+valveRating+valveChar;
      break;
      case 'valveAsient':
        setvalveAsient(value);
        valveKey = valveType+bodyMaterial+valveSize+valvePackage+valveTrim+valvePort+valveBonet+value+valveRating+valveChar;
      break;
      case 'valveRating':
        setvalveRating(value);
        valveKey = valveType+bodyMaterial+valveSize+valvePackage+valveTrim+valvePort+valveBonet+valveAsient+value+valveChar;
      break;
      case 'valveChar':
        setvalveChar(value);
        valveKey = valveType+bodyMaterial+valveSize+valvePackage+valveTrim+valvePort+valveBonet+valveAsient+valveRating+value;
      break;
    }
    
    setvalveCode(valveKey);
    if(valveType!==""&&bodyMaterial!==""&&valveSize!==""&&valvePackage!==""&&valveTrim!==""&&valvePort!==""&&valveBonet!==""&&valveAsient!==""&&valveRating!==""){
      setvalveSetted(true);
    }else{
      setvalveSetted(false);
    }

    setValveLoading(true);

    await axios.get(`${API_ITEM}?country=MX&type=VAL&key=${valveKey}`).then(response => {
      if(response.data.docs.length>0){
        setvalveError(false);

        let price = response.data.docs[0].price;
        setValvePrice(Number(price));
        switch(priceType){
          case 'REP':
            price = price * .85 * .82 * .8;
          break;
          case 'SALE':
            price = price * .85 * .9 * .9;
          break;
          case 'LIST':
            price = price;
          break;
        }
        
        if(response.data.docs[0].bom !== undefined && response.data.docs[0].bom !== null){
          toast.success("BOM encontrado y agregado", {position: 'bottom-right', style:{color:'white'}});
          setParts(response.data.docs[0].bom);
        }
        setValveTotal(Number(price).toFixed(2));
        getSubtotal('valve',price);
      }else{
        setvalveError(true);
        getSubtotal('valve',0);
      }
      setValveLoading(false);
    }).catch(e => {
      setvalveError(true);
      getSubtotal('valve',0);
      setValveLoading(false);
      console.log(e)
    });
    
  }

  const getactPrice = async (tag,value) => {
    let actKey ='';
    let stopQry = false;

    switch(tag){
      case 'actType':
        setactType(value);
        actKey = value+actSize;
      break;
      case 'actSize':
        setactSize(value);
        actKey = actType+value;
      break;
      case 'actRange':
        setactRange(value);
        stopQry = true;
      break;
    }

    if(stopQry){
      return;
    }
    
    setactCode(actKey);
    if(actType!==""){
      setactSetted(true);
    }else{
      setactSetted(false);
    }

    setactLoading(true);

    await axios.get(`${API_ITEM}?country=MX&type=ACT&key=${actKey}`).then(response => {
      if(response.data.docs.length>0){
        setactError(false);

        let price = response.data.docs[0].price;
        setactPrice(Number(price));
        switch(priceType){
          case 'REP':
            price = price * .85 * .85 * .8;
          break;
          case 'SALE':
            price = price * .85 * .9 * .9;
          break;
          case 'LIST':
            price = price;
          break;
        }

        setactTotal(Number(price).toFixed(2));
        getSubtotal('act',price);
      }else{
        setactError(true);
        getSubtotal('act',0);
      }
      setactLoading(false);
    }).catch(e => {
      setactError(true);
      getSubtotal('act',0);
      setactLoading(false);
    });
    
  }

  const getposPrice = async (tag,value) => {
    let posKey ='';
    let stopQry = false;

    switch(tag){
      case 'posType':
        setposType(value);
        posKey = value+posCom+posDia+posOpc;
      break;
      case 'posCom':
        setposCom(value);
        posKey = posType+value+posDia+posOpc;
      break;
      case 'posDia':
        setposDia(value);
        posKey = posType+posCom+value+posOpc;
      break;
      case 'posOpc':
        setposOpc(value);
        posKey = posType+posCom+posDia+value;
      break;
      case 'posReg':
        setposReg(value);
        stopQry = true;
      break;
    }

    if(stopQry){
      return;
    }
    
    setposCode(posKey);
    if(posType!==""&&posCom!==""&&posDia!==""){
      setposSetted(true);
    }else{
      setposSetted(false);
    }

    setposLoading(true);

    await axios.get(`${API_ITEM}?country=MX&type=POS&key=${encodeURIComponent(posKey)}`).then(response => {
      if(response.data.docs.length>0){
        setposError(false);

        let price = response.data.docs[0].price;
        setposPrice(Number(price));
        switch(priceType){
          case 'REP':
            price = price * .85 * .82;
          break;
          case 'SALE':
            price = price * .85;
          break;
          case 'LIST':
            price = price;
          break;
        }

        setposTotal(Number(price).toFixed(2));
        getSubtotal('pos',price);
      }else{
        setposError(true);
        getSubtotal('pos',0);
      }
      setposLoading(false);
    }).catch(e => {
      setposError(true);
      getSubtotal('pos',0);
      setposLoading(false);
    });
    
  }

  const calculateDisccount = async (value) => {
    setpriceType(value);
    
    switch(value){
      case 'LIST' :
        setValveTotal(valvePrice);
        setactTotal(actPrice);
        setposTotal(posPrice);
        setsubTotal((Number(valvePrice > 0 ? valvePrice : 0) + Number(actPrice > 0 ? actPrice : 0) + Number(posPrice > 0 ? posPrice : 0)).toFixed(2));
        settotal(((Number(valvePrice > 0 ? valvePrice : 0) + Number(actPrice > 0 ? actPrice : 0) + Number(posPrice > 0 ? posPrice : 0))*qty).toFixed(2));
      break;
      case 'SALE' :
        setValveTotal(valvePrice > 0 ? (valvePrice * .85 * .9 * .9).toFixed(2) : 0);
        setactTotal(actPrice > 0 ? (actPrice * .85 * .9 * .9).toFixed(2) : 0);
        setposTotal(posPrice > 0 ? (posPrice * .85).toFixed(2) : 0);
        setsubTotal((Number(valvePrice > 0 ? (valvePrice * .85 * .9 * .9).toFixed(2) : 0) + Number(actPrice > 0 ? (actPrice * .85 * .9 * .9).toFixed(2) : 0) + Number(posPrice > 0 ? (posPrice * .85).toFixed(2) : 0)).toFixed(2));
        settotal(((Number(valvePrice > 0 ? (valvePrice * .85 * .9 * .9).toFixed(2) : 0) + Number(actPrice > 0 ? (actPrice * .85 * .9 * .9).toFixed(2) : 0) + Number(posPrice > 0 ? (posPrice * .85).toFixed(2) : 0))*qty).toFixed(2));
      break;
      case 'REP' :
        setValveTotal(valvePrice > 0 ? (valvePrice * .85 * .82 * .8).toFixed(2) : 0);
        setactTotal(actPrice > 0 ? (actPrice * .85 * .85 * .8).toFixed(2) : 0);
        setposTotal(posPrice > 0 ? (posPrice * .85 * .82).toFixed(2) : 0);
        setsubTotal((Number(valvePrice > 0 ? (valvePrice * .85 * .82 * .8).toFixed(2) : 0) + Number(actPrice > 0 ? (actPrice * .85 * .85 * .8).toFixed(2) : 0) + Number(posPrice > 0 ? (posPrice * .85 * .82).toFixed(2) : 0)).toFixed(2));
        settotal(((Number(valvePrice > 0 ? (valvePrice * .85 * .82 * .8).toFixed(2) : 0) + Number(actPrice > 0 ? (actPrice * .85 * .85 * .8).toFixed(2) : 0) + Number(posPrice > 0 ? (posPrice * .85 * .82).toFixed(2) : 0))*qty).toFixed(2));
      break;
    }
  }

  const getSubtotal = (tag,value) => {
    let valveP = (valveSetted && !valveError ? valveTotal: 0);
    let actP = (actSetted && !actError ? actTotal: 0);
    let posP = (posSetted && !posError ? posTotal: 0);

    switch(tag){
      case 'valve':
        setsubTotal((Number(value) + Number(actP) + Number(posP)).toFixed(2));
        settotal(((Number(value) + Number(actP) + Number(posP)) * qty).toFixed(2))
      break;
      case 'act':
        setsubTotal((Number(valveP) + Number(value) + Number(posP)).toFixed(2));
        settotal(((Number(valveP) + Number(value) + Number(posP)) * qty).toFixed(2));
      break;
      case 'pos':
        setsubTotal((Number(valveP) + Number(actP) + Number(value)).toFixed(2));
        settotal(((Number(valveP) + Number(actP) + Number(value)) * qty).toFixed(2));
      break;
    }
  }

  const [parts, setParts] = useState([]);
  const [selectedPart, setselectedPart] = useState(null);
  const [partLoading, setPartLoading] = useState(false);
  const [partKeyValue,setpartKeyValue] = useState('');
  const getPartKey = async (value) => {
    setpartKeyValue(value);

    if(value===''){
      setselectedPart(null);
      return;
    }
    setPartLoading(true);

    await axios.get(`${API_ITEM}?country=MX&type=PART&key=${encodeURIComponent(value)}`).then(async response => {
      if(response.data.docs.length>0){            
        const part = {
          uuid: response.data.docs[0].uuid,
          key: response.data.docs[0].key,
          description: response.data.docs[0].description,
          qty: 0,
        }
        setselectedPart(part);
      }else{
        setselectedPart(null);
      }
      setPartLoading(false);
    }).catch(e => {
      setPartLoading(false);
    });
  }

  const addPartNumber = () => {
    let temporalParts = [];
    parts.map((value)=>{
      temporalParts.push(value);
    });
    temporalParts.push(selectedPart);

    setParts(temporalParts);
    setselectedPart(null);
    setpartKeyValue('');
  }

  const deleteItem = (uuid) => {
    let temporalParts = [];
    parts.map((value)=>{
      if(value.uuid!==uuid)
        temporalParts.push(value);
    });

    setParts(temporalParts);
  }

  const restExists = (value,uuid) => {
    let temporalParts = [];
    if(value===""){
      parts.map((part)=>{
        if(part.uuid===uuid){
          part.qty = "";
        }
        temporalParts.push(part);
      });

      setParts(temporalParts);
    }else{
      parts.map((part)=>{
        if(part.uuid===uuid){
          part.qty = Number(value);
        }
        temporalParts.push(part);
      });

      setParts(temporalParts);
    }
  }

  useEffect(() => {
    if(
      user.role !== 'ADMIN' 
      && user.role !== "SISTEMAS"
      && user.role !== "GERENTE"
      && user.role !== "COTIZADOR"
    ){
      navigate('/inicio');
    }
    getCustomersList();
    getQuoteList();
    getUsersList();
  }, [navigate]);

  return (
    <Fragment>
      <Breadcrumb parent="Ventas" title="Cotizar válvulas"/>
      <Container fluid={true}>
        <Row>
          <Col sm="12" style={{display:'flex', justifyContent:'flex-end', marginBottom: 20, paddingRight:30}}>
            <Button color="primary" onClick={toggle}>Crear Cotización</Button>
          </Col>
        </Row>

        <Row>
          <Col sm="12">
            <Card>
              <CardBody className="data-tables">
                <DataTable
                  columns={columns}
                  striped={true}
                  center={true}
                  data={filteredItems}
                  subHeader
                  pagination
                  subHeaderComponent={subHeaderComponent}
                  noDataComponent={<b style={{padding:10}}>No se encontraron cotizaciones</b>}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>

      </Container>

      <Modal isOpen={modal} toggle={toggle} size="lg" style={{minWidth:'55%'}}>
      <ModalHeader toggle={toggle}>{isEditingEvent ? `Editar Cotización` : `Crear Cotización` }
        {isEditingEvent ?
        <>
          <div style={{display:'flex',justifyContent:'left'}}><a style={{color:'blue'}} href={`${process.env.REACT_APP_PUBLIC_URL}/quote?uuid=${currentQuote.uuid}`} target="_blank">Ver Documento</a></div>
          {parts.length>0 ?
          <div style={{display:'flex',justifyContent:'left', marginRight:15}}><a style={{color:'blue'}} href={`${process.env.REACT_APP_PUBLIC_URL}/bom?uuid=${currentQuote.uuid}`} target="_blank">Ver BOM</a></div>: null}
        </>
        : null }
        <button className="btn-close invisible" type="button">
          <span aria-hidden="true" className="visible" onClick={toggle}></span>
        </button>
        </ModalHeader>
        <ModalBody>
          <div className="container">
            <h5>Resumen</h5>
            <div className="container mt-3 mb-3 border p-2">
              <Row className="flex d-flex" md="12">
                <Col md="6">
                  <Label className="form-label font-weight-bold">
                    Cliente
                  </Label>
                </Col>
                <Col md="6">
                  <Label className="form-label font-weight-bold">
                    {
                      customerSelected.length > 0 ?
                        customerSelected[0].name
                      : <Alert color="warning" className="p-1 m-0">Sin elegir</Alert>
                    }
                  </Label>
                </Col>
                <Col md="6">
                  <Label className="form-label font-weight-bold">
                    Tipo de Precio
                  </Label>
                </Col>
                <Col md="6">
                  <Label className="form-label font-weight-bold">
                    {priceType === 'REP' ? 'REP' : null}
                    {priceType === 'SALE' ? 'Venta' : null}
                    {priceType === 'LIST' ? 'Lista F' : null}
                  </Label>
                </Col>
                <Col md="6">
                  <Label className="form-label font-weight-bold">
                    Tipo de Crédito
                  </Label>
                </Col>
                <Col md="6">
                  <Label className="form-label font-weight-bold">
                    {creditType === '100_FULL' ? '100% anticipo' : null}
                    {creditType === '30_DAYS' ? '30 días de crédito' : null}
                    {creditType === '50_50' ? '50% anticipo y 50% contra aviso de embarque' : null}
                    {creditType === '100_ADVICE_SHIPPING' ? '100% contra aviso de embarque' : null}
                  </Label>
                </Col>
              </Row>
            </div>
            <div className="container mt-3 mb-3 border p-2">
              <Row className="flex d-flex" md="12">
                <Col md="6">
                  <Label className="form-label font-weight-bold">
                    Estado
                  </Label>
                </Col>
                <Col md="6">
                  <Label className="form-label font-weight-bold">
                    {quoteEstado(statusQuote)}
                  </Label>
                </Col>
              </Row>
            </div>
            <div className="container mt-3 mb-3 border p-2">
              <Row className="flex d-flex" md="12">
                <Col md="6">
                  <Label className="form-label font-weight-bold">
                    Válvula <br/>{valveCode} {!valveLoading && !valveError &&valveSetted ? <i className="fa fa-check" style={{color:'green'}}></i> : ''}
                  </Label>
                </Col>
                <Col md="6" style={{textAlign:'right'}}>
                  <Label className="form-label font-weight-bold">
                    {
                      valveLoading ? 
                        'Buscando...'
                      :
                        valveSetted ?
                          !valveError ?
                            valveTotal > 0 ? '$'+valveTotal : '$0'
                          : 
                            <Alert color="danger" className="p-1 m-0">No encontrado</Alert>
                        :
                          <Alert color="info" className="p-1 m-0">Por configurar</Alert>
                    }
                  </Label>
                </Col>
              </Row>
              <Row className="flex d-flex" md="12">
                <Col md="6">
                  <Label className="form-label font-weight-bold">
                    Actuador <br/>{actCode}{actRange} {!actLoading && !actError &&actSetted ? <i className="fa fa-check" style={{color:'green'}}></i> : ''}
                  </Label>
                </Col>
                <Col md="6" style={{textAlign:'right'}}>
                  <Label className="form-label font-weight-bold">
                    {
                      actSetted ?
                        !actError ?
                            actTotal > 0 ? '$'+actTotal : '$0'
                        :
                          <Alert color="danger" className="p-1 m-0">No encontrado</Alert>
                      :
                        <Alert color="info" className="p-1 m-0">Por configurar</Alert>
                    }
                  </Label>
                </Col>
              </Row>
              <Row className="flex d-flex" md="12">
                <Col md="6">
                  <Label className="form-label font-weight-bold">
                    Posicionador <br/>{posCode}{posReg} {!posLoading && !posError &&posSetted ? <i className="fa fa-check" style={{color:'green'}}></i> : ''}
                  </Label>
                </Col>
                <Col md="6" style={{textAlign:'right'}}>
                  <Label className="form-label font-weight-bold">
                    {
                      posSetted ?
                        !posError ?
                            posTotal > 0 ? '$'+posTotal : '$0'
                        : 
                          <Alert color="danger" className="p-1 m-0">No encontrado</Alert>
                      :
                        <Alert color="info" className="p-1 m-0">Por configurar</Alert>
                    }
                  </Label>
                </Col>
              </Row>
              <hr/>
              <Row className="flex d-flex" md="12">
                <Col md="9" style={{textAlign:'right'}}>
                  <Label className="form-label font-weight-bold">
                    Sub Total USD
                  </Label>
                </Col>
                <Col md="3" style={{textAlign:'right'}}>
                  <Label className="form-label font-weight-bold">
                    ${subTotal}
                  </Label>
                </Col>
              </Row>
              <Row className="flex d-flex" md="12">
                <Col md="9" style={{textAlign:'right'}}>
                  <Label className="form-label font-weight-bold">
                    Gran Total USD (x{qty})
                  </Label>
                </Col>
                <Col md="3" style={{textAlign:'right'}}>
                  <Label className="form-label font-weight-bold">
                    ${total}
                  </Label>
                </Col>
              </Row>
            </div>
            {
              editDocument ?
                <>
                
                <Form className="needs-validation" noValidate="" onSubmit={handleSubmit( isEditingEvent ? patchEvent : saveQuote)} style={{padding:20}}>

                  <Nav className="nav-primary" tabs>
                    <NavItem>
                      <NavLink
                        style={{cursor:'pointer'}}
                        className={PrimarycolorLineTab === "1" ? "active" : ""}
                        onClick={() => setPrimarycolorLineTab("1")}
                      >
                        <i className="icofont icofont-list"></i>Detalles
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{cursor:'pointer'}}
                        className={PrimarycolorLineTab === "2" ? "active" : ""}
                        onClick={() => setPrimarycolorLineTab("2")}
                      >
                        <i className="icofont icofont-dashboard"></i>Válvula
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{cursor:'pointer'}}
                        className={PrimarycolorLineTab === "3" ? "active" : ""}
                        onClick={() => setPrimarycolorLineTab("3")}
                      >
                        <i className="icofont icofont-circuit"></i>Actuador
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{cursor:'pointer'}}
                        className={PrimarycolorLineTab === "4" ? "active" : ""}
                        onClick={() => setPrimarycolorLineTab("4")}
                      >
                        <i className="icofont icofont-touch"></i>Posicionador
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{cursor:'pointer'}}
                        className={PrimarycolorLineTab === "5" ? "active" : ""}
                        onClick={() => setPrimarycolorLineTab("5")}
                      >
                        <i className="icofont icofont-tree-alt"></i>BOM
                      </NavLink>
                    </NavItem>
                  </Nav>
                  <TabContent activeTab={PrimarycolorLineTab}>
                    <TabPane className="fade show" tabId="1">
                        <Row className="g-3 dflex mb-3">
                          <Col md="12">
                            <Label className="form-label font-weight-bold" for="validquoteId">
                              No. Cotización
                            </Label>
                            <input className="form-control" id="validquoteId" type="text" placeholder="No. Cotización" name="quoteId" {...register("quoteId", { required: true })} />
                          </Col>
                        </Row>
                        <Row className="g-3 dflex mb-3">
                          <Col md="12">
                            <Label className="form-label font-weight-bold" for="validquoteId">
                              Estado
                            </Label>
                            <select defaultValue={statusQuote} name="status" {...register("status", { required: true })} className="form-control" onChange={(e)=>setstatusQuote(e.target.value)}>
                              <option value="CANCELLED">CANCELADA</option>
                              <option value="SENT">ENVIADA</option>
                              <option value="WORKING">EN PRODUCCIÓN</option>
                              <option value="PENDING">EN REVISIÓN</option>
                              <option value="WAITING">POR ACEPTAR CLIENTE</option>
                              <option value="FINISHED">ENTREGADA</option>
                            </select>
                          </Col>
                        </Row>
                        <Row className="g-3 dflex mb-3">
                          <Col md="12">
                            <Label className="form-label font-weight-bold" for="validPriceType">
                              Tipo Precio
                            </Label>
                            <select className="form-control" defaultValue={priceType} name="priceType" {...register("priceType", { required: true })} onChange={(e)=>calculateDisccount(e.target.value)} >
                              <option value="LIST" selected>LISTA F</option>
                              <option value="SALE">VENTA</option>
                              <option value="REP">REP</option>
                            </select>
                            <span>{errors.priceType && "Tipo de Precio requerido"}</span>
                          </Col>
                        </Row>
                        <Row className="g-3 dflex mb-3">
                          <Col md="12">
                            <Label className="form-label font-weight-bold">
                              Cliente
                            </Label>
                            <Typeahead
                              id="customers"
                              clearButton
                              defaultSelected={customerSelected}
                              labelKey="name"
                              options={dataCustomers}
                              placeholder="Buscar Cliente..."
                              onChange={(e)=>{setCustomerSelected(e)}}
                            />
                          </Col>
                        </Row>
                        <Row className="g-3 dflex mb-3">
                          <Col md="12">
                            <Label className="form-label font-weight-bold" for="validContact">
                              Atención
                            </Label>
                            <input className="form-control" id="validContact" type="text" placeholder="Persona de contacto" name="contact" {...register("contact", { required: true })} />
                          </Col>
                        </Row>
                        <Row className="g-3 dflex mb-3">
                          <Col md="12">
                            <Label className="form-label font-weight-bold" for="validCredit">
                              Crédito
                            </Label>
                            <select className="form-control" name="creditType" {...register("creditType", { required: true })} onChange={(e)=>setcreditType(e.target.value)} >
                              <option value="30_DAYS">30 días de crédito</option>
                              <option value="50_50">50% anticipo y 50% contra aviso de embarque</option>
                              <option value="100_ADVICE_SHIPPING">100% contra aviso de embarque</option>
                              <option value="100_FULL" selected>100% anticipo</option>
                            </select>
                            <span>{errors.creditType && "Tipo de Crédito requerido"}</span>
                          </Col>
                        </Row>
                        <Row className="g-3 dflex mb-3">
                          <Col md="12">
                            <Label className="form-label font-weight-bold" for="validPart">
                              Partida
                            </Label>
                            <input className="form-control" id="validPart" type="text" placeholder="No. partida" name="part" {...register("part", { required: true })} />
                          </Col>
                        </Row>
                        <Row className="g-3 dflex mb-3">
                          <Col md="12">
                            <Label className="form-label font-weight-bold" for="validQty">
                              Cantidad
                            </Label>
                            <input className="form-control" id="validQty" type="number" placeholder="Cantidad" name="qty" {...register("qty", { required: true })} value={qty} onChange={(e)=>{setqty(e.target.value)}} onBlurCapture={(e)=>{calculateDisccount(priceType);}} />
                          </Col>
                        </Row>
                        <Row className="g-3 dflex mb-3">
                          <Col md="12">
                            <Label className="form-label font-weight-bold" for="validEstimated">
                              Tiempo estimado de entrega
                            </Label>
                            <input className="form-control" id="validEstimated" type="text" placeholder="Tiempo estimado" name="estimatedTime" {...register("estimatedTime", { required: false })} />
                          </Col>
                        </Row>
                        <Row className="g-3 dflex mb-3">
                          <Col md="12">
                            <Label className="form-label font-weight-bold">
                              Realiza
                            </Label>
                            <Typeahead
                              id="users"
                              clearButton
                              defaultSelected={userSelected}
                              labelKey="name"
                              options={dataUsers}
                              placeholder="Buscar Usuarios..."
                              onChange={(e)=>{setUserSelected(e)}}
                            />
                          </Col>
                        </Row>
                        <Row className="g-3 dflex mb-3">
                          <Col md="12">
                            <Label className="form-label font-weight-bold" for="validLogo">
                              Logo
                            </Label>
                            <input className="form-control" id="validLogo" type="text" placeholder="Enlace a Logo" value={logoCiesa} name="logo" {...register("logo", { required: false })} onChange={(e)=>setlogoCiesa(e.target.value)} />
                          </Col>
                        </Row>
                    </TabPane>
                    <TabPane className="fade show" tabId="2">
                        <Row className="g-3 dflex mb-3">
                          <Col md="12">
                            <Label className="form-label font-weight-bold">
                              Descripción de la Válvula
                            </Label>
                            <input className="form-control" type="text" placeholder="Descripción" name="valveDescription" {...register("valveDescription", { required: false })} />
                          </Col>
                        </Row>
                        <Row className="g-3 dflex mb-3">
                          <Col md="12">
                            <Label className="form-label font-weight-bold">
                              Marca de la Válvula
                            </Label>
                            <input className="form-control" type="text" placeholder="Marca" name="valveBrand" {...register("valveBrand", { required: false })} />
                          </Col>
                        </Row>
                        <Row className="g-3 dflex mb-3">
                          <Col md="12">
                            <Label className="form-label font-weight-bold">
                              Válvula
                            </Label>
                            <select className="form-control" name="valveType" {...register("valveType", { required: false })} value={valveType} onChange={(e)=>getvalvePrice('valveType',e.target.value)} >
                              <option value="" selected>--ELEGIR--</option>
                              <option value="TRZ-" selected>TRZ</option>
                              <option value="TRT-" selected>TRT</option>
                              <option value="TRD-" selected>TRD</option>
                              <option value="TRS-" selected>TRS</option>
                            </select>
                          </Col>
                        </Row>
                        <Row className="g-3 dflex mb-3">
                          <Col md="12">
                            <Label className="form-label font-weight-bold">
                              Material del Cuerpo
                            </Label>
                            <select className="form-control" name="bodyMaterial" {...register("bodyMaterial", { required: false })} value={bodyMaterial} onChange={(e)=>getvalvePrice('bodyMaterial',e.target.value)} >
                              <option value="" selected>--ELEGIR--</option>
                              <option value="WCC" selected>WCC</option>
                              <option value="CF8M" selected>CF8M</option>
                            </select>
                          </Col>
                        </Row>
                        <Row className="g-3 dflex mb-3">
                          <Col md="12">
                            <Label className="form-label font-weight-bold">
                              Tamaño
                            </Label>
                            <select className="form-control" name="valveSize" {...register("valveSize", { required: false })} value={valveSize} onChange={(e)=>getvalvePrice('valveSize',e.target.value)} >
                              <option value="" selected>--ELEGIR--</option>
                              <option value="1" selected>1</option>
                              <option value="1.5" selected>1 1/2</option>
                              <option value="2" selected>2</option>
                              <option value="2.5" selected>2 1/2</option>
                              <option value="3" selected>3</option>
                              <option value="4" selected>4</option>
                              <option value="5" selected>5</option>
                              <option value="6" selected>6</option>
                              <option value="8" selected>8</option>
                            </select>
                          </Col>
                        </Row>
                        <Row className="g-3 dflex mb-3">
                          <Col md="12">
                            <Label className="form-label font-weight-bold">
                              Empaques
                            </Label>
                            <select className="form-control" name="valvePackage" {...register("valvePackage", { required: false })} value={valvePackage} onChange={(e)=>getvalvePrice('valvePackage',e.target.value)} >
                              <option value="" selected>--ELEGIR--</option>
                              <option value="PTFE" selected>PTFE</option>
                              <option value="GRAPH" selected>GRAPH</option>
                              <option value="DOBLE PTFE" selected>DOBLE PTFE</option>
                              <option value="DOBLE GRAPH" selected>DOBLE GRAPH</option>
                              <option value="Enviro seal" selected>Enviro seal</option>
                            </select>
                          </Col>
                        </Row>
                        <Row className="g-3 dflex mb-3">
                          <Col md="12">
                            <Label className="form-label font-weight-bold">
                              Trim
                            </Label>
                            <select className="form-control" name="valveTrim" {...register("valveTrim", { required: false })} value={valveTrim} onChange={(e)=>getvalvePrice('valveTrim',e.target.value)} >
                              <option value="" selected>--ELEGIR--</option>
                              <option value="101" selected>101</option>
                              <option value="104" selected>104</option>
                              <option value="127" selected>127</option>
                              <option value="128" selected>128</option>
                              <option value="129" selected>129</option>
                              <option value="137" selected>137</option>
                              <option value="139" selected>139</option>
                              <option value="152" selected>152</option>
                              <option value="1" selected>1</option>
                              <option value="3" selected>3</option>
                              <option value="4" selected>4</option>
                              <option value="27" selected>27</option>
                              <option value="27C" selected>27C</option>
                              <option value="28" selected>28</option>
                              <option value="29" selected>29</option>
                              <option value="37, 37H" selected>37, 37H</option>
                              <option value="57" selected>57</option>
                              <option value="4C" selected>4C</option>
                              <option value="29C" selected>29C</option>
                            </select>
                          </Col>
                        </Row>
                        <Row className="g-3 dflex mb-3">
                          <Col md="12">
                            <Label className="form-label font-weight-bold">
                              Puerto
                            </Label>
                            <select className="form-control" name="valvePort" {...register("valvePort", { required: false })} value={valvePort} onChange={(e)=>getvalvePrice('valvePort',e.target.value)} >
                              <option value="" selected>--ELEGIR--</option>
                              <option value='1/4"' selected>1/4"</option>
                              <option value='1/4" Mflute 1F' selected>1/4" Mflute 1F</option>
                              <option value='1/4" Mflute 3F' selected>1/4" Mflute 3F</option>
                              <option value='3/16"( 1 deg )' selected>3/16"( 1 deg )</option>
                              <option value='3/16"( 1 deg  8 min)' selected>3/16"( 1 deg  8 min)</option>
                              <option value='3/16"( 1 deg  25 min)' selected>3/16"( 1 deg  25 min)</option>
                              <option value='3/8"' selected>3/8"</option>
                              <option value='1/2"' selected>1/2"</option>
                              <option value='3/4"' selected>3/4"</option>
                              <option value='1"' selected>1"</option>
                              <option value='1 1/2"' selected>1 1/2"</option>
                              <option value='2"' selected>2"</option>
                              <option value='1 5/16"' selected>1 5/16"</option>
                              <option value='1 7/8"' selected>1 7/8"</option>
                              <option value='2 5/16"' selected>2 5/16"</option>
                              <option value='2 7/8"' selected>2 7/8"</option>
                              <option value='3 7/16"' selected>3 7/16"</option>
                              <option value='4 3/8"' selected>4 3/8"</option>
                              <option value='7"' selected>7"</option>
                              <option value='8"' selected>8"</option>
                            </select>
                          </Col>
                        </Row>
                        <Row className="g-3 dflex mb-3">
                          <Col md="12">
                            <Label className="form-label font-weight-bold">
                              Bonete
                            </Label>
                            <select className="form-control" name="valveBonet" {...register("valveBonet", { required: false })} value={valveBonet} onChange={(e)=>getvalvePrice('valveBonet',e.target.value)} >
                              <option value="" selected>--ELEGIR--</option>
                              <option value="STD" selected>STD</option>
                              <option value="Ext1" selected>Ext1</option>
                              <option value="Ext2" selected>Ext2</option>
                            </select>
                          </Col>
                        </Row>
                        <Row className="g-3 dflex mb-3">
                          <Col md="12">
                            <Label className="form-label font-weight-bold">
                              Asiento
                            </Label>
                            <select className="form-control" name="valveAsient" {...register("valveAsient", { required: false })} value={valveAsient} onChange={(e)=>getvalvePrice('valveAsient',e.target.value)} >
                              <option value="" selected>--ELEGIR--</option>
                              <option value="Metal" selected>Metal</option>
                              <option value="PTFE" selected>PTFE</option>
                            </select>
                          </Col>
                        </Row>
                        <Row className="g-3 dflex mb-3">
                          <Col md="12">
                            <Label className="form-label font-weight-bold">
                              Rating
                            </Label>
                            <select className="form-control" name="valveRating" {...register("valveRating", { required: false })} value={valveRating} onChange={(e)=>getvalvePrice('valveRating',e.target.value)} >
                              <option value="" selected>--ELEGIR--</option>
                              <option value="150" selected>150</option>
                              <option value="300" selected>300</option>
                              <option value="600" selected>600</option>
                            </select>
                          </Col>
                        </Row>
                        <Row className="g-3 dflex mb-3">
                          <Col md="12">
                            <Label className="form-label font-weight-bold">
                              Char
                            </Label>
                            <select className="form-control" name="valveChar" {...register("valveChar", { required: false })} value={valveChar} onChange={(e)=>getvalvePrice('valveChar',e.target.value)} >
                              <option value="" selected>--ELEGIR--</option>
                              <option value="EQ" selected>EQ</option>
                              <option value="Q.O" selected>Q.O</option>
                              <option value="LIN" selected>LIN</option>
                            </select>
                          </Col>
                        </Row>
                    </TabPane>
                    <TabPane className="fade show" tabId="3">
                        <Row className="g-3 dflex mb-3">
                          <Col md="12">
                            <Label className="form-label font-weight-bold">
                              Descripción del Actuador
                            </Label>
                            <input className="form-control" type="text" placeholder="Descripción" name="actDescription" {...register("actDescription", { required: false })} />
                          </Col>
                        </Row>
                        <Row className="g-3 dflex mb-3">
                          <Col md="12">
                            <Label className="form-label font-weight-bold">
                              Marca del Actuador
                            </Label>
                            <input className="form-control" type="text" placeholder="Marca" name="actBrand" {...register("actBrand", { required: false })} />
                          </Col>
                        </Row>
                        <Row className="g-3 dflex mb-3">
                          <Col md="12">
                            <Label className="form-label font-weight-bold">
                              Actuador
                            </Label>
                            <select className="form-control" name="actType" {...register("actType", { required: false })} value={actType} onChange={(e)=>getactPrice('actType',e.target.value)} >
                              <option value="" selected>--ELEGIR--</option>
                              <option value="AT67-" selected>AT67</option>
                              <option value="AT57-" selected>AT57</option>
                            </select>
                          </Col>
                        </Row>
                        <Row className="g-3 dflex mb-3">
                          <Col md="12">
                            <Label className="form-label font-weight-bold">
                              Tamaño
                            </Label>
                            <select className="form-control" name="actSize" {...register("actSize", { required: false })} value={actSize} onChange={(e)=>getactPrice('actSize',e.target.value)} >
                              <option value="" selected>--ELEGIR--</option>
                              <option value="30" selected>30</option>
                              <option value="34" selected>34</option>
                              <option value="40" selected>40</option>
                              <option value="45" selected>45</option>
                              <option value="46" selected>46</option>
                              <option value="50" selected>50</option>
                              <option value="60" selected>60</option>
                              <option value="70" selected>70</option>
                              <option value="70-4" selected>70-4</option>
                            </select>
                          </Col>
                        </Row>
                        <Row className="g-3 dflex mb-3">
                          <Col md="12">
                            <Label className="form-label font-weight-bold">
                              Rango
                            </Label>
                            <select className="form-control" name="actRange" {...register("actRange", { required: false })} value={actRange} onChange={(e)=>getactPrice('actRange',e.target.value)} >
                              <option value="" selected>--ELEGIR--</option>
                              <option value="6-30" selected>6-30</option>
                              <option value="3-15" selected>3-15</option>
                            </select>
                          </Col>
                        </Row>
                        <Row className="g-3 dflex mb-3">
                          <Col md="12">
                            <Label className="form-label font-weight-bold">
                              Incluido:
                            </Label>
                            <CKEditors
                              activeclassName="p10"
                              content={actIncludes}
                              events={{
                                change: changeactIncludes,
                              }}
                            />
                          </Col>
                        </Row>
                    </TabPane>
                    <TabPane className="fade show" tabId="4">
                        <Row className="g-3 dflex mb-3">
                          <Col md="12">
                            <Label className="form-label font-weight-bold">
                              Posicionador
                            </Label>
                            <select className="form-control" name="posType" {...register("posType", { required: false })} value={posType} onChange={(e)=>getposPrice('posType',e.target.value)} >
                              <option value="" selected>--ELEGIR--</option>
                              <option value="SRD991" selected>SRD991</option>
                              <option value="SRD960" selected>SRD960</option>
                              <option value="1000L" selected>1000L</option>
                              <option value="1200L" selected>1200L</option>
                              <option value="3582I" selected>3582I</option>
                              <option value="DVC6200" selected>DVC6200</option>
                            </select>
                          </Col>
                        </Row>
                        <Row className="g-3 dflex mb-3">
                          <Col md="12">
                            <Label className="form-label font-weight-bold">
                              Comunicación
                            </Label>
                            <select className="form-control" name="posCom" {...register("posCom", { required: false })} value={posCom} onChange={(e)=>getposPrice('posCom',e.target.value)} >
                              <option value="" selected>--ELEGIR--</option>
                              <option value="4-20 mA sin Hart" selected>4-20 mA sin Hart</option>
                              <option value="4-20 mA + Hart" selected>4-20 mA + Hart</option>
                              <option value="FieldBus" selected>FieldBus</option>
                              <option value="Neumatico" selected>Neumatico</option>
                            </select>
                          </Col>
                        </Row>
                        <Row className="g-3 dflex mb-3">
                          <Col md="12">
                            <Label className="form-label font-weight-bold">
                              Diágnosticos
                            </Label>
                            <select className="form-control" name="posDia" {...register("posDia", { required: false })} value={posDia} onChange={(e)=>getposPrice('posDia',e.target.value)} >
                              <option value="" selected>--ELEGIR--</option>
                              <option value="Diagnosticos avanzados" selected>Diagnosticos avanzados</option>
                              <option value="Diagnosticos base" selected>Diagnosticos base</option>
                              <option value="N/A" selected>N/A</option>
                            </select>
                          </Col>
                        </Row>
                        <Row className="g-3 dflex mb-3">
                          <Col md="12">
                            <Label className="form-label font-weight-bold">
                              Opción
                            </Label>
                            <select className="form-control" name="posOpc" {...register("posOpc", { required: false })} value={posOpc} onChange={(e)=>getposPrice('posOpc',e.target.value)} >
                              <option value="" selected>--ELEGIR--</option>
                              <option value="Feedback" selected>Feedback</option>
                              <option value="Sin Feedback" selected>Sin Feedback</option>
                            </select>
                          </Col>
                        </Row>
                        <Row className="g-3 dflex mb-3">
                          <Col md="12">
                            <Label className="form-label font-weight-bold">
                              Rango regulador
                            </Label>
                            <select className="form-control" name="posReg" {...register("posReg", { required: false })} value={posReg} onChange={(e)=>getposPrice('posReg',e.target.value)} >
                              <option value="" selected>--ELEGIR--</option>
                              <option value="0-125" selected>0-125</option>
                              <option value="0-60" selected>0-60</option>
                              <option value="0-35" selected>0-35</option>
                            </select>
                          </Col>
                        </Row>
                        <Row className="g-3 dflex mb-3">
                          <Col md="12">
                            <Label className="form-label font-weight-bold">
                              Incluido:
                            </Label>
                            <CKEditors
                              activeclassName="p10"
                              content={posIncludes}
                              events={{
                                change: changeposIncludes,
                              }}
                            />
                          </Col>
                        </Row>
                    </TabPane>
                    <TabPane className="fade show" tabId="5">
                        <Row className="g-3 dflex mb-3">
                          <Col md="12">
                            <input value={partKeyValue} onChange={(e)=>getPartKey(e.target.value)} className="form-control" type="text" placeholder="Buscar número de parte" />
                            {
                              partLoading ? 'Bucando...' :
                                selectedPart!== null ?
                                <Button onClick={addPartNumber} className="mt-2"><i className="fa fa-plus"></i> Agregar</Button> : partKeyValue !== '' ? <p className="mt-1" style={{color:'red'}}>No encontrado</p> : null
                            }
                          </Col>
                        </Row>
                        {parts.length > 0 ?
                          parts.map(part=>{
                            return(
                              <Row key={part.uuid} className="g-3 dflex mb-3 p-2 mt-1" style={{backgroundColor:'#f1f1f1'}}>
                                <div style={{color:'white', background:'red', position:'absolute', right:60, marginTop:0-10, padding:5, borderRadius:5, width:20, textAlign:'center', cursor:'pointer'}} onClick={()=>deleteItem(part.uuid)}>X</div>
                                <Col md="12">
                                  <Label className="form-label font-weight-bold">
                                    No. Parte: {part.key}
                                  </Label><br/>
                                  <Label className="form-label font-weight-bold">
                                    Descripción: <b style={{color:'green'}}>{part.description}</b>
                                  </Label><br/>
                                  <Label className="form-label font-weight-bold" for="validQty">
                                    Cantidad Requerida:
                                  </Label>
                                  <input className="form-control" type="number" placeholder="Cantidad" value={part.qty} onChange={(e)=>{restExists(e.target.value, part.uuid);}} />
                                </Col>
                              </Row>
                            )
                          })
                        : null }
                    </TabPane>
                  </TabContent>

                  <Row style={{display:'flex', justifyContent:'end', paddingTop:20}}>
                    <span style={{color:'red'}}>{errors.quoteId && "- No. Cotización Requerido"}</span>
                    <span style={{color:'red'}}>{errors.contact && "- Persona de Atención Requerida"}</span>
                    <span style={{color:'red'}}>{errors.qty && "- Cantidad Requerida"}</span>
                    <span style={{color:'red'}}>{errors.part && "- Partida Requerida"}</span>
                    {
                      isEditingEvent ? 
                        <Button disabled={patchingEvent} className="btn btn-primary" style={{width:150}} color="primary">{"Guardar"}</Button> 
                      : 
                        <Button disabled={savingEvent} className="btn btn-primary" style={{width:150}} color="primary">{"Guardar"}</Button> 
                    }
                  </Row>

                </Form>
                </>
              :
              null
            }
          </div>
        </ModalBody>
      </Modal>
    </Fragment>
  );
};

export default Quotes;
