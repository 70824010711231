import React, { Fragment, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Button, Card, CardBody, Col, Container, Form, Label, Modal, ModalBody, ModalHeader, Nav, Row,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  CardHeader,
  Collapse, } from "reactstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import axios from "axios";
import { API_SERVICE, API_CUSTOMER, API_USER } from "../../../redux/apiRoutes";
import { toast } from "react-toastify";
import DataTable from "react-data-table-component";
import Breadcrumb from "../../common/breadcrumb/breadcrumb";
import { useForm } from "react-hook-form";
import { v4 as uuidv4 } from 'uuid';
import DatePicker from 'react-datepicker';
import es from "date-fns/locale/es";
import AWS from 'aws-sdk';

import CKEditors from "react-ckeditor-component";

import FilterComponent from "../../common/filter";
import SweetAlert from "sweetalert2";
import { checkRole, estado, estadoPago } from "../../../utils/functions";
import { WebcamCapture } from "../../common/camera";
import { HydroCapture } from "../../common/hydro";

const S3_BUCKET = process.env.REACT_APP_AWS_BUCKET;
const REGION = process.env.REACT_APP_AWS_REGION;

AWS.config.update({
    accessKeyId: process.env.REACT_APP_AWS_KEY,
    secretAccessKey: process.env.REACT_APP_AWS_KEY_SECRET,
})

const myBucket = new AWS.S3({
    params: { Bucket: S3_BUCKET},
    region: REGION,
});

const Services = () => {
  const user = useSelector((content) => content.User);
  const navigate = useNavigate();

  const [PrimarycolorLineTab, setPrimarycolorLineTab] = useState("1");
  const [activeCamera, setactiveCamera] = useState(false);
  const [logoCiesa, setlogoCiesa] = useState("https://ciesa.site/logo192.png");
  const [place, setPlace] = useState("Centro CIESA");

  const handleviewCamera = () => {setactiveCamera(!activeCamera)}

  const [pictures, setPictures] = useState([]);
  const [attaching, setAttaching] = useState(false);
  const attach = async (pic) => {
    setAttaching(true);

    await axios.patch(`${API_SERVICE}/${currentEvent.uuid}`,{newPic:pic[0]}).then(response => {
      if(response.data){
        getEventList();
        setPictures(response.data.pictures)
        setAttaching(false);
        toast.success("¡Foto agregada con éxito!", {position: 'bottom-right', style:{color:'white'}});
      }
      setAttaching(false);
    }).catch(e => {
      setAttaching(false);
      toast.error("No se pudo agregar la foto al servicio: " + e.message, {position: 'bottom-right', style:{color:'white'}});
    });
  }

  const clearPediments = [
    {
      pid:1,
      number: "",
      description: "",
      qty: "",
      pediment: "",
    },
    {
      pid:2,
      number: "",
      description: "",
      qty: "",
      pediment: "",
    },
    {
      pid:3,
      number: "",
      description: "",
      qty: "",
      pediment: "",
    },
    {
      pid:4,
      number: "",
      description: "",
      qty: "",
      pediment: "",
    },
    {
      pid:5,
      number: "",
      description: "",
      qty: "",
      pediment: "",
    },
    {
      pid:6,
      number: "",
      description: "",
      qty: "",
      pediment: "",
    },
    {
      pid:7,
      number: "",
      description: "",
      qty: "",
      pediment: "",
    },
    {
      pid:8,
      number: "",
      description: "",
      qty: "",
      pediment: "",
    },
    {
      pid:9,
      number: "",
      description: "",
      qty: "",
      pediment: "",
    },
    {
      pid:10,
      number: "",
      description: "",
      qty: "",
      pediment: "",
    },
    {
      pid:11,
      number: "",
      description: "",
      qty: "",
      pediment: "",
    },
    {
      pid:12,
      number: "",
      description: "",
      qty: "",
      pediment: "",
    },
    {
      pid:13,
      number: "",
      description: "",
      qty: "",
      pediment: "",
    },
    {
      pid:14,
      number: "",
      description: "",
      qty: "",
      pediment: "",
    },
    {
      pid:15,
      number: "",
      description: "",
      qty: "",
      pediment: "",
    },
    {
      pid:16,
      number: "",
      description: "",
      qty: "",
      pediment: "",
    },
    {
      pid:17,
      number: "",
      description: "",
      qty: "",
      pediment: "",
    },
    {
      pid:18,
      number: "",
      description: "",
      qty: "",
      pediment: "",
    },
    {
      pid:19,
      number: "",
      description: "",
      qty: "",
      pediment: "",
    },
    {
      pid:20,
      number: "",
      description: "",
      qty: "",
      pediment: "",
    },
  ];

  const changePedimentValue = (id,type,value) => {
    let pedimentValues = pediment.filter((pediment)=>{
      if(pediment.pid===id){
        switch(type){
          case 'number':
            pediment.number = value;
          break;
          case 'description':
            pediment.description = value;
          break;
          case 'qty':
            pediment.qty = value;
          break;
          case 'pediment':
            pediment.pediment = value;
          break;
        }
      }
      return pediment;
    });

    setPediments(pedimentValues);
  }

  const [hydros, setHydros] = useState([]);
  const [pediment, setPediments] = useState(clearPediments);
  const [attachingHydro, setAttachingHydro] = useState(false);
  const attachHydro = async (hydro) => {
    setAttachingHydro(true);

    await axios.patch(`${API_SERVICE}/${currentEvent.uuid}`,{newHydro:hydro[0]}).then(response => {
      if(response.data){
        getEventList();
        setHydros(response.data.hydro)
        setAttachingHydro(false);
        toast.success("¡Reporte Hydro agregado con éxito!", {position: 'bottom-right', style:{color:'white'}});
      }
      setAttachingHydro(false);
    }).catch(e => {
      setAttachingHydro(false);
      toast.error("No se pudo agregar el reporte Hydro al servicio: " + e.message, {position: 'bottom-right', style:{color:'white'}});
    });
  }

  const handleDeleteActionPicture = (file) => {
    SweetAlert.fire({
      title: "¿Continuamos?",
      text: `Se eliminará la foto del servicio.`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Si",
      cancelButtonText: "Cancelar",
      reverseButtons: true,
    }).then(async (result) => {
      if (result.value) {
        const deleteParams = {
          Bucket: S3_BUCKET,
          Key: `posts/${file.split('/posts/')[1]}`
        }
        myBucket.deleteObject(deleteParams).send();
        const newPictures = pictures.filter((item)=>item!==file);

        await axios.patch(`${API_SERVICE}/${currentEvent.uuid}`,{pictures: newPictures}).then(response => {
          if(response.data){
            setPictures(newPictures);
            getEventList();
          }
        }).catch(error => {
          setTimeout(() => {
            toast.error(
              "Error al eliminar la imagen: " + error
            );
          }, 200);
        });
      }
    });
  }

  const handleDeleteActionHydro = (file) => {
    SweetAlert.fire({
      title: "¿Continuamos?",
      text: `Se eliminará la foto del reporte Hydro.`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Si",
      cancelButtonText: "Cancelar",
      reverseButtons: true,
    }).then(async (result) => {
      if (result.value) {
        const deleteParams = {
          Bucket: S3_BUCKET,
          Key: `posts/${file.split('/posts/')[1]}`
        }
        myBucket.deleteObject(deleteParams).send();
        const newHydros = hydros.filter((item)=>item!==file);

        await axios.patch(`${API_SERVICE}/${currentEvent.uuid}`,{hydro: newHydros}).then(response => {
          if(response.data){
            setHydros(newHydros);
            getEventList();
          }
        }).catch(error => {
          setTimeout(() => {
            toast.error(
              "Error al eliminar la imagen del reporte Hydro: " + error
            );
          }, 200);
        });
      }
    });
  }
  
  const [modal, setModal] = useState(false);
  const toggle = () => {setModal(!modal);  reset(); setEditinEvent(false); clearModal(); setPediments(clearPediments)};

  const [isOpen, setIsOpen] = useState(1);
  const toggleAccordion = (id) => (isOpen === id ? setIsOpen(null) : setIsOpen(id));

  const clearModal = () => {
    setactiveCamera(false);
    setCustomerSelected([]);
    setPictures([]);
    setHydros([]);
    setCurrentEvent({});
    setUserSelected([]);
    setSupervisorSelected([]);
    setstartDate(new Date());
    setendDate(new Date());
    setEditDocument(true);
    setPrimarycolorLineTab('1')
    setContent('')
    setlogoCiesa("https://ciesa.site/logo192.png");
    setPlace('Centro CIESA');

    setasbodyBrand('')
    setasbodyBrandChange('')
    setasbodySerie('')
    setasbodySerieChange('')
    setasbodyModel('')
    setasbodyModelChange('')
    setasbodySize('')
    setasbodySizeChange('')
    setasbodyRating('')
    setasbodyRatingChange('')
    setasbodyConnection('')
    setasbodyConnectionChange('')
    setasbodyDirection('')
    setasbodyDirectionChange('')
    setasbodyTrim('')
    setasbodyTrimChange('')
    setasbodyPort('')
    setasbodyPortChange('')
    setasbodyCv('')
    setasbodyCvChange('')
    setasbodyMpackage('')
    setasbodyMpackageChange('')
    setasbodyMjuntas('')
    setasbodyMjuntasChange('')
    setasbodyMbody('')
    setasbodyMbodyChange('')
    setasbodyMseat('')
    setasbodyMseatChange('')
    setasbodyMbox('')
    setasbodyMboxChange('')
    setasbodyMguide('')
    setasbodyMguideChange('')
    setasbodyMtap('')
    setasbodyMtapChange('')
    setasbodyMvas('')
    setasbodyMvasChange('')

    setasactorBrand('')
    setasactorBrandChange('')
    setasactorSerie('')
    setasactorSerieChange('')
    setasactorModel('')
    setasactorModelChange('')
    setasactorSize('')
    setasactorSizeChange('')
    setasactorAction('')
    setasactorActionChange('')
    setasactorRace('')
    setasactorRaceChange('')
    setasactorAjust('')
    setasactorAjustChange('')
    setasactorVol('')
    setasactorVolChange('')
    setasactorSignal('')
    setasactorSignalChange('')

    setasposBrand('')
    setasposBrandChange('')
    setasposModel('')
    setasposModelChange('')
    setasposSerie('')
    setasposSerieChange('')
    setasposSignal('')
    setasposSignalChange('')
    setasposAction('')
    setasposActionChange('')
    setasposChar('')
    setasposCharChange('')
    setasposBypass('')
    setasposBypassChange('')

    setaothersTrans('')
    setaothersTransChange('')
    setaothersSignalIn('')
    setaothersSignalInChange('')
    setaothersSignalOut('')
    setaothersSignalOutChange('')
    setaothersSelen('')
    setaothersSelenChange('')
    setaothersAction('')
    setaothersActionChange('')
    setaothersVals('')
    setaothersValsChange('')
    setaothersTrip('')
    setaothersTripChange('')
    setaothersFilter('')
    setaothersFilterChange('')
    setaothersSwitch('')
    setaothersSwitchChange('')
    setaothersSpecial('')
    setaothersSpecialChange('')
    setaothersMeta([])

    setfixbodyBrida({ok:false,des:false,lav:false,pic:false,ral:false,san:false,pul:false,sol:false,maq:false,rem:false,pin:false});
    setfixbodySuper({ok:false,des:false,lav:false,pic:false,ral:false,san:false,pul:false,sol:false,maq:false,rem:false,pin:false});
    setfixbodyInf({ok:false,des:false,lav:false,pic:false,ral:false,san:false,pul:false,sol:false,maq:false,rem:false,pin:false});
    setfixbodyBon({ok:false,des:false,lav:false,pic:false,ral:false,san:false,pul:false,sol:false,maq:false,rem:false,pin:false});
    setfixbodyBox({ok:false,des:false,lav:false,pic:false,ral:false,san:false,pul:false,sol:false,maq:false,rem:false,pin:false});
    setfixbodyNotes('');

    setfixtrimTBD({ok:false,des:false,lav:false,pic:false,ral:false,san:false,pul:false,sol:false,maq:false,rem:false});
    setfixtrimCGR({ok:false,des:false,lav:false,pic:false,ral:false,san:false,pul:false,sol:false,maq:false,rem:false});
    setfixtrimAni({ok:false,des:false,lav:false,pic:false,ral:false,san:false,pul:false,sol:false,maq:false,rem:false});
    setfixtrimVf({ok:false,des:false,lav:false,pic:false,ral:false,san:false,pul:false,sol:false,maq:false,rem:false});
    setfixtrimSp({ok:false,des:false,lav:false,pic:false,ral:false,san:false,pul:false,sol:false,maq:false,rem:false});
    setfixtrimBuj({ok:false,des:false,lav:false,pic:false,ral:false,san:false,pul:false,sol:false,maq:false,rem:false});
    setfixtrimNotes('');

    setfixactTap({ok:false,des:false,lav:false,pic:false,ral:false,san:false,pul:false,sol:false,maq:false,rem:false,det:false});
    setfixactYug({ok:false,des:false,lav:false,pic:false,ral:false,san:false,pul:false,sol:false,maq:false,rem:false,det:false});
    setfixactFle({ok:false,des:false,lav:false,pic:false,ral:false,san:false,pul:false,sol:false,maq:false,rem:false,det:false});
    setfixactDiafra({ok:false,des:false,lav:false,pic:false,ral:false,san:false,pul:false,sol:false,maq:false,rem:false,det:false});
    setfixactSellos({ok:false,des:false,lav:false,pic:false,ral:false,san:false,pul:false,sol:false,maq:false,rem:false,det:false});
    setfixactTorn({ok:false,des:false,lav:false,pic:false,ral:false,san:false,pul:false,sol:false,maq:false,rem:false,det:false});
    setfixactPlaca({ok:false,des:false,lav:false,pic:false,ral:false,san:false,pul:false,sol:false,maq:false,rem:false,det:false});
    setfixactTest({paso:false});
    setfixactNotes('');

    setfixotherPos({rep:false,ree:false,reu:false});
    setfixotherBypass({rep:false,ree:false,reu:false});
    setfixotherIp({rep:false,ree:false,reu:false});
    setfixotherTrip({rep:false,ree:false,reu:false});
    setfixotherFilter({rep:false,ree:false,reu:false});
    setfixotherMan({rep:false,ree:false,reu:false});
    setfixotherSwitch({rep:false,ree:false,reu:false});
    setfixotherSole({rep:false,ree:false,reu:false});
    setfixotherVol({rep:false,ree:false,reu:false});
    setfixotherTub({rep:false,ree:false,reu:false});
    setfixotherFit({rep:false,ree:false,reu:false});
    setfixotherMeta({rep:false,ree:false,reu:false});
    setfixotherNotes('');
  }

  const [userSelected,setUserSelected] = useState([]);
  const [supervisorSelected,setSupervisorSelected] = useState([]);
  const [dataUsers,setdataUsers] = useState([]);
  const getUsersList = async () => {
    await axios.get(`${API_USER}?status=ACTIVE&role=CAPTURISTA,SUPERVISOR,GERENTE`).then(response => {
      if(response.data.docs.length>0){
        const users = [];
        response.data.docs.map(user=>{
          const addUser = {
            uuid: user.uuid,
            name: user.name,
            eventAmount: user.eventAmount,
          }
          users.push(addUser);
        });
        setdataUsers(users);
      }
    }).catch(e => {
      toast.error("No se pudo obtener el listado de Usuarios: " + e.message, {position: 'bottom-right', style:{color:'white'}});
    });
  }

  const [customerSelected,setCustomerSelected] = useState([]);
  const [dataCustomers,setdataCustomers] = useState([]);
  const getCustomersList = async () => {
    await axios.get(`${API_CUSTOMER}?status=ACTIVE`).then(response => {
      if(response.data.docs.length>0){
        const customers = [];
        response.data.docs.map(customer=>{
          const addCustomer = {
            uuid: customer.uuid,
            name: customer.name,
            price: customer.price,
            products: customer.products,
          }
          customers.push(addCustomer);
        });
        setdataCustomers(customers);
      }
    }).catch(e => {
      toast.error("No se pudo obtener el listado de Servicios: " + e.message, {position: 'bottom-right', style:{color:'white'}});
    });
  }

  const [dataEventList, setDataEventList] = useState([]);
  const [isGetEventList, setIsGetEventList] = useState(false);
  const [editPermissions, setEditPermissions] = useState(false);
  const getEventList = async () => {
    setIsGetEventList(true);

    let queryRule = '';
    if(user.role==='ADMIN' || user.role==='SISTEMAS' || user.role === "GERENTE"){
      setEditPermissions(true);
      queryRule = `type=SERVICE`;
    }

    if(user.role === "CAPTURISTA"){
      setEditPermissions(false);
      queryRule = `type=SERVICE`;
    }
    
    await axios.get(`${API_SERVICE}?${queryRule}`).then(response => {
      if(response.data.docs.length>0){
        setDataEventList(response.data.docs);
      }else{
        setDataEventList([]);
      }
      setIsGetEventList(false);
    }).catch(e => {
      setIsGetEventList(false);
      toast.error("No se pudo obtener el listado de Servicios: " + e.message, {position: 'bottom-right', style:{color:'white'}});
    });
  }

  const columns = [
    {
      name: "Id Servicio",
      selector: (row) => <b>{row.eventId}</b>,
      sortable: true,
      center: true,
      width: '160px'
    },
    {
      name: "Equipo",
      selector: (row) => <b>{row.device}</b>,
      sortable: true,
      center: true,
      width: '150px'
    },
    {
      name: "Modelo",
      selector: (row) => row.modelo,
      sortable: true,
      center: true,
      width: '150px'
    },
    {
      name: "Estado",
      selector: (row) => estado(row.status),
      sortable: true,
      center: true,
      width: '160px'
    },
    {
      name: "Cliente",
      selector: (row) => row.customer !== null && row.customer !== undefined && row.customer.length > 0 ? <b>{row.customer[0].name}</b> : '-',
      sortable: true,
      center: true,
    },
    {
      name: "Fecha Inicio",
      selector: (row) => row.startTime ? new Date(row.startTime).getDate() + '-' + (new Date(row.startTime).getMonth() + 1) + '-' + new Date(row.startTime).getFullYear() : '--',
      sortable: true,
      center: true,
    },
    {
      name: "Fecha Fin",
      selector: (row) => row.endTime ? new Date(row.endTime).getDate() + '-' + (new Date(row.endTime).getMonth() + 1) + '-' + new Date(row.endTime).getFullYear() : '--',
      sortable: true,
      center: true,
    },
    {
      name: "Acciones",
      selector: (row) => <div style={{padding:5}}>
                          <Button color="light" style={{width: 40, padding: 6, marginRight:5, fontSize: 14}} onClick={()=>{handleEditAction(row)}}>{row.edit ? <i className="fa fa-pencil"></i> : <i className="fa fa-pencil"></i>}</Button>
                          {editPermissions?
                          <Button color="light" style={{width: 40, padding: 6, marginRight:5, fontSize: 14}} onClick={()=>{handleDeleteAction(row)}}><i className="fa fa-trash"></i></Button> : null}
                        </div>,
      width: '160px'
    },
  ];

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm();

  const [startdate, setstartDate] = useState(null);
  const handleChangeStartDate = (date) => {
    setstartDate(date);
  };

  const [enddate, setendDate] = useState(null);
  const handleChangeEndDate = (date) => {
    setendDate(date);
  };

  //AssemblyBody items
  const [asbodyBrand,setasbodyBrand] = useState('');
  const [asbodyBrandChange,setasbodyBrandChange] = useState('');
  const [asbodySerie,setasbodySerie] = useState('');
  const [asbodySerieChange,setasbodySerieChange] = useState('');
  const [asbodyModel,setasbodyModel] = useState('');
  const [asbodyModelChange,setasbodyModelChange] = useState('');
  const [asbodySize,setasbodySize] = useState('');
  const [asbodySizeChange,setasbodySizeChange] = useState('');
  const [asbodyRating,setasbodyRating] = useState('');
  const [asbodyRatingChange,setasbodyRatingChange] = useState('');
  const [asbodyConnection,setasbodyConnection] = useState('');
  const [asbodyConnectionChange,setasbodyConnectionChange] = useState('');
  const [asbodyDirection,setasbodyDirection] = useState('');
  const [asbodyDirectionChange,setasbodyDirectionChange] = useState('');
  const [asbodyTrim,setasbodyTrim] = useState('');
  const [asbodyTrimChange,setasbodyTrimChange] = useState('');
  const [asbodyPort,setasbodyPort] = useState('');
  const [asbodyPortChange,setasbodyPortChange] = useState('');
  const [asbodyCv,setasbodyCv] = useState('');
  const [asbodyCvChange,setasbodyCvChange] = useState('');
  const [asbodyMpackage,setasbodyMpackage] = useState('');
  const [asbodyMpackageChange,setasbodyMpackageChange] = useState('');
  const [asbodyMjuntas,setasbodyMjuntas] = useState('');
  const [asbodyMjuntasChange,setasbodyMjuntasChange] = useState('');
  const [asbodyMbody,setasbodyMbody] = useState('');
  const [asbodyMbodyChange,setasbodyMbodyChange] = useState('');
  const [asbodyMseat,setasbodyMseat] = useState('');
  const [asbodyMseatChange,setasbodyMseatChange] = useState('');
  const [asbodyMbox,setasbodyMbox] = useState('');
  const [asbodyMboxChange,setasbodyMboxChange] = useState('');
  const [asbodyMguide,setasbodyMguide] = useState('');
  const [asbodyMguideChange,setasbodyMguideChange] = useState('');
  const [asbodyMtap,setasbodyMtap] = useState('');
  const [asbodyMtapChange,setasbodyMtapChange] = useState('');
  const [asbodyMvas,setasbodyMvas] = useState('');
  const [asbodyMvasChange,setasbodyMvasChange] = useState('');

  //Assembly Actor items
  const [asactorBrand,setasactorBrand] = useState('');
  const [asactorBrandChange,setasactorBrandChange] = useState('');
  const [asactorSerie,setasactorSerie] = useState('');
  const [asactorSerieChange,setasactorSerieChange] = useState('');
  const [asactorModel,setasactorModel] = useState('');
  const [asactorModelChange,setasactorModelChange] = useState('');
  const [asactorSize,setasactorSize] = useState('');
  const [asactorSizeChange,setasactorSizeChange] = useState('');
  const [asactorAction,setasactorAction] = useState('');
  const [asactorActionChange,setasactorActionChange] = useState('');
  const [asactorRace,setasactorRace] = useState('');
  const [asactorRaceChange,setasactorRaceChange] = useState('');
  const [asactorAjust,setasactorAjust] = useState('');
  const [asactorAjustChange,setasactorAjustChange] = useState('');
  const [asactorVol,setasactorVol] = useState('');
  const [asactorVolChange,setasactorVolChange] = useState('');
  const [asactorSignal,setasactorSignal] = useState('');
  const [asactorSignalChange,setasactorSignalChange] = useState('');

  //Assembly positionator items
  const [asposBrand,setasposBrand] = useState('');
  const [asposBrandChange,setasposBrandChange] = useState('');
  const [asposModel,setasposModel] = useState('');
  const [asposModelChange,setasposModelChange] = useState('');
  const [asposSerie,setasposSerie] = useState('');
  const [asposSerieChange,setasposSerieChange] = useState('');
  const [asposSignal,setasposSignal] = useState('');
  const [asposSignalChange,setasposSignalChange] = useState('');
  const [asposAction,setasposAction] = useState('');
  const [asposActionChange,setasposActionChange] = useState('');
  const [asposChar,setasposChar] = useState('');
  const [asposCharChange,setasposCharChange] = useState('');
  const [asposBypass,setasposBypass] = useState('');
  const [asposBypassChange,setasposBypassChange] = useState('');
  
  //assembly other items
  const [aothersTrans,setaothersTrans] = useState('');
  const [aothersTransChange,setaothersTransChange] = useState('');
  const [aothersSignalIn,setaothersSignalIn] = useState('');
  const [aothersSignalInChange,setaothersSignalInChange] = useState('');
  const [aothersSignalOut,setaothersSignalOut] = useState('');
  const [aothersSignalOutChange,setaothersSignalOutChange] = useState('');
  const [aothersSelen,setaothersSelen] = useState('');
  const [aothersSelenChange,setaothersSelenChange] = useState('');
  const [aothersAction,setaothersAction] = useState('');
  const [aothersActionChange,setaothersActionChange] = useState('');
  const [aothersVals,setaothersVals] = useState('');
  const [aothersValsChange,setaothersValsChange] = useState('');
  const [aothersTrip,setaothersTrip] = useState('');
  const [aothersTripChange,setaothersTripChange] = useState('');
  const [aothersFilter,setaothersFilter] = useState('');
  const [aothersFilterChange,setaothersFilterChange] = useState('');
  const [aothersSwitch,setaothersSwitch] = useState('');
  const [aothersSwitchChange,setaothersSwitchChange] = useState('');
  const [aothersSpecial,setaothersSpecial] = useState('');
  const [aothersSpecialChange,setaothersSpecialChange] = useState('');
  const [aothersMeta,setaothersMeta] = useState([]);

  //fixes body items
  const [fixbodyBrida, setfixbodyBrida] = useState({ok:false,des:false,lav:false,pic:false,ral:false,san:false,pul:false,sol:false,maq:false,rem:false,pin:false});
  const [fixbodySuper, setfixbodySuper] = useState({ok:false,des:false,lav:false,pic:false,ral:false,san:false,pul:false,sol:false,maq:false,rem:false,pin:false});
  const [fixbodyInf, setfixbodyInf] = useState({ok:false,des:false,lav:false,pic:false,ral:false,san:false,pul:false,sol:false,maq:false,rem:false,pin:false});
  const [fixbodyBon, setfixbodyBon] = useState({ok:false,des:false,lav:false,pic:false,ral:false,san:false,pul:false,sol:false,maq:false,rem:false,pin:false});
  const [fixbodyBox, setfixbodyBox] = useState({ok:false,des:false,lav:false,pic:false,ral:false,san:false,pul:false,sol:false,maq:false,rem:false,pin:false});
  const [fixbodyNotes, setfixbodyNotes] = useState('');

  //fixes trim
  const [fixtrimTBD, setfixtrimTBD] = useState({ok:false,des:false,lav:false,pic:false,ral:false,san:false,pul:false,sol:false,maq:false,rem:false});
  const [fixtrimCGR, setfixtrimCGR] = useState({ok:false,des:false,lav:false,pic:false,ral:false,san:false,pul:false,sol:false,maq:false,rem:false});
  const [fixtrimAni, setfixtrimAni] = useState({ok:false,des:false,lav:false,pic:false,ral:false,san:false,pul:false,sol:false,maq:false,rem:false});
  const [fixtrimVf, setfixtrimVf] = useState({ok:false,des:false,lav:false,pic:false,ral:false,san:false,pul:false,sol:false,maq:false,rem:false});
  const [fixtrimSp, setfixtrimSp] = useState({ok:false,des:false,lav:false,pic:false,ral:false,san:false,pul:false,sol:false,maq:false,rem:false});
  const [fixtrimBuj, setfixtrimBuj] = useState({ok:false,des:false,lav:false,pic:false,ral:false,san:false,pul:false,sol:false,maq:false,rem:false});
  const [fixtrimNotes, setfixtrimNotes] = useState('');

  //fixes actor
  const [fixactTap, setfixactTap] = useState({ok:false,des:false,lav:false,pic:false,ral:false,san:false,pul:false,sol:false,maq:false,rem:false,det:false});
  const [fixactYug, setfixactYug] = useState({ok:false,des:false,lav:false,pic:false,ral:false,san:false,pul:false,sol:false,maq:false,rem:false,det:false});
  const [fixactFle, setfixactFle] = useState({ok:false,des:false,lav:false,pic:false,ral:false,san:false,pul:false,sol:false,maq:false,rem:false,det:false});
  const [fixactDiafra, setfixactDiafra] = useState({ok:false,des:false,lav:false,pic:false,ral:false,san:false,pul:false,sol:false,maq:false,rem:false,det:false});
  const [fixactSellos, setfixactSellos] = useState({ok:false,des:false,lav:false,pic:false,ral:false,san:false,pul:false,sol:false,maq:false,rem:false,det:false});
  const [fixactTorn, setfixactTorn] = useState({ok:false,des:false,lav:false,pic:false,ral:false,san:false,pul:false,sol:false,maq:false,rem:false,det:false});
  const [fixactPlaca, setfixactPlaca] = useState({ok:false,des:false,lav:false,pic:false,ral:false,san:false,pul:false,sol:false,maq:false,rem:false,det:false});
  const [fixactTest, setfixactTest] = useState({paso:false});
  const [fixactNotes, setfixactNotes] = useState('');

  //fixes other
  const [fixotherPos, setfixotherPos] = useState({rep:false,ree:false,reu:false});
  const [fixotherBypass, setfixotherBypass] = useState({rep:false,ree:false,reu:false});
  const [fixotherIp, setfixotherIp] = useState({rep:false,ree:false,reu:false});
  const [fixotherTrip, setfixotherTrip] = useState({rep:false,ree:false,reu:false});
  const [fixotherFilter, setfixotherFilter] = useState({rep:false,ree:false,reu:false});
  const [fixotherMan, setfixotherMan] = useState({rep:false,ree:false,reu:false});
  const [fixotherSwitch, setfixotherSwitch] = useState({rep:false,ree:false,reu:false});
  const [fixotherSole, setfixotherSole] = useState({rep:false,ree:false,reu:false});
  const [fixotherVol, setfixotherVol] = useState({rep:false,ree:false,reu:false});
  const [fixotherTub, setfixotherTub] = useState({rep:false,ree:false,reu:false});
  const [fixotherFit, setfixotherFit] = useState({rep:false,ree:false,reu:false});
  const [fixotherMeta, setfixotherMeta] = useState({rep:false,ree:false,reu:false});
  const [fixotherNotes, setfixotherNotes] = useState('');

  //fixes mutations
  const handleFixeds = (value,section,type) => {
    switch(section){
      case 'brida':
        fixbodyBrida[type] = value;
        setfixbodyBrida(fixbodyBrida);
      break;
      case 'super':
        fixbodySuper[type] = value;
        setfixbodySuper(fixbodySuper);
      break;
      case 'inf':
        fixbodyInf[type] = value;
        setfixbodyInf(fixbodyInf);
      break;
      case 'bon':
        fixbodyBon[type] = value;
        setfixbodyBon(fixbodyBon);
      break;
      case 'box':
        fixbodyBox[type] = value;
        setfixbodyBox(fixbodyBox);
      break;
      case 'tbd':
        fixtrimTBD[type] = value;
        setfixtrimTBD(fixtrimTBD);
      break;
      case 'cgr':
        fixtrimCGR[type] = value;
        setfixtrimCGR(fixtrimCGR);
      break;
      case 'ani':
        fixtrimAni[type] = value;
        setfixtrimAni(fixtrimAni);
      break;
      case 'vf':
        fixtrimVf[type] = value;
        setfixtrimVf(fixtrimVf);
      break;
      case 'sp':
        fixtrimSp[type] = value;
        setfixtrimSp(fixtrimSp);
      break;
      case 'buj':
        fixtrimBuj[type] = value;
        setfixtrimBuj(fixtrimBuj);
      break;
      case 'tap':
        fixactTap[type] = value;
        setfixactTap(fixactTap);
      break;
      case 'yug':
        fixactYug[type] = value;
        setfixactYug(fixactYug);
      break;
      case 'fle':
        fixactFle[type] = value;
        setfixactFle(fixactFle);
      break;
      case 'diafra':
        fixactDiafra[type] = value;
        setfixactDiafra(fixactDiafra);
      break;
      case 'sellos':
        fixactSellos[type] = value;
        setfixactSellos(fixactSellos);
      break;
      case 'torn':
        fixactTorn[type] = value;
        setfixactTorn(fixactTorn);
      break;
      case 'placa':
        fixactPlaca[type] = value;
        setfixactPlaca(fixactPlaca);
      break;
      case 'test':
        fixactTest[type] = value;
        setfixactTest(fixactTest);
      break;
      case 'pos':
        fixotherPos[type] = value;
        setfixotherPos(fixotherPos);
      break;
      case 'bypass':
        fixotherBypass[type] = value;
        setfixotherBypass(fixotherBypass);
      break;
      case 'ip':
        fixotherIp[type] = value;
        setfixotherIp(fixotherIp);
      break;
      case 'trip':
        fixotherTrip[type] = value;
        setfixotherTrip(fixotherTrip);
      break;
      case 'filter':
        fixotherFilter[type] = value;
        setfixotherFilter(fixotherFilter);
      break;
      case 'man':
        fixotherMan[type] = value;
        setfixotherMan(fixotherMan);
      break;
      case 'switch':
        fixotherSwitch[type] = value;
        setfixotherSwitch(fixotherSwitch);
      break;
      case 'sole':
        fixotherSole[type] = value;
        setfixotherSole(fixotherSole);
      break;
      case 'vol':
        fixotherVol[type] = value;
        setfixotherVol(fixotherVol);
      break;
      case 'tub':
        fixotherTub[type] = value;
        setfixotherTub(fixotherTub);
      break;
      case 'fit':
        fixotherFit[type] = value;
        setfixotherFit(fixotherFit);
      break;
      case 'otr':
        fixotherMeta[type] = value;
        setfixotherMeta(fixotherMeta);
      break;
    }
  }

  const [savingEvent, setSaveEvent] = useState(false);
  const saveEvent = async (data) => {
    setSaveEvent(true);
    if (data !== "") {
      if(customerSelected.length<=0){
        toast.error("Asigna un cliente", {position: 'bottom-right', style:{color:'white'}});
        setSaveEvent(false);
        return;
      }

      if(userSelected.length<=0){
        toast.error("Asigna un responsable", {position: 'bottom-right', style:{color:'white'}});
        setSaveEvent(false);
        return;
      }

      if(supervisorSelected.length<=0){
        toast.error("Asigna un supervisor", {position: 'bottom-right', style:{color:'white'}});
        setSaveEvent(false);
        return;
      }

      data.uuid = uuidv4();
      data.customer = customerSelected;
      data.startTime = startdate !== null ? startdate : null;
      data.endTime = enddate !== null ? enddate : null;
      data.customer = customerSelected;
      data.owner = userSelected;
      data.supervisor = supervisorSelected;
      data.type = 'SERVICE';
      data.report = content;

      await axios.post(`${API_SERVICE}`,data).then(response => {
        if(response.data){
          getEventList();
          setEditinEvent(true);
          setTimeout(()=>{
            document.getElementById("assemblyTab").click();
          },700)
          toast.success("¡Servicio creado con éxito! Continua Editando", {position: 'bottom-right', style:{color:'white'}});
        }
        setSaveEvent(false);
        setCurrentEvent(response.data);
      }).catch(e => {
        setSaveEvent(false);
        toast.error("No se pudo crear el servicio: " + e.message, {position: 'bottom-right', style:{color:'white'}});
      });
    } else {
      errors.showMessages();
    }
  };

  const [currentEvent, setCurrentEvent] = useState({});
  const [editDocument, setEditDocument] = useState(true);
  const handleEditAction = (event) => {
    toggle();
    setEditinEvent(true);
    setCurrentEvent(event);
    
    if(event.customer!==undefined && event.customer !== null && event.customer.length>0){
      dataCustomers.map((customer)=>{
        if(customer.uuid===event.customer[0].uuid){
          setCustomerSelected([customer]);
        }
      });
    }

    if(event.owner!==undefined && event.owner !== null){
      dataUsers.map((user)=>{
        if(user.uuid===event.owner[0].uuid){
          setUserSelected([user]);
        }
      });
    }

    if(event.supervisor!==undefined && event.supervisor !== null){
      dataUsers.map((user)=>{
        if(user.uuid===event.supervisor[0].uuid){
          setSupervisorSelected([user]);
        }
      });
    }
    populate(event);
    
    setTimeout(()=>{
      setValue('eventId', event.eventId);
      setValue('place', event.place);
      setValue('contact', event.contact);
      setValue('line', event.line);
      setValue('device', event.device);
      setValue('modelo', event.modelo);
      setValue('brand', event.brand);
      setValue('status', event.status);
      setValue('jobDescription', event.jobDescription);
      setValue('target', event.target);
      setValue('stateIn', event.stateIn);
      setValue('stateOut', event.stateOut);
      setValue('report', event.report);
      setstartDate(event.startTime !== null && event.startTime !== undefined ? new Date(event.startTime) : null);
      setendDate(event.endTime !== null && event.endTime !== undefined ? new Date(event.endTime) : null);
      setContent(event.report);
      setValue('logo', event.logo !== "" && event.logo !== null && event.logo !== undefined ? event.logo : logoCiesa);
      setValue('repName', event.repName);
      setValue('repAddress', event.repAddress);
      setValue('repEmail', event.repEmail);
      setValue('repPhone', event.repPhone);
      setValue('repRfc', event.repRfc);
      setPlace(event.place);
    },500)
  }

  const populate = (event) => {
    if(event.assembly !== undefined && event.assembly !== null){
      //AssemblyBody items
      if(event.assembly.body !== null && event.assembly.body !== undefined){
        setasbodyBrand(event.assembly.body.brand_value)
        setasbodyBrandChange(event.assembly.body.brand_change)
        setasbodySerie(event.assembly.body.serie_value)
        setasbodySerieChange(event.assembly.body.serie_change)
        setasbodyModel(event.assembly.body.model_value)
        setasbodyModelChange(event.assembly.body.model_change)
        setasbodySize(event.assembly.body.size_value)
        setasbodySizeChange(event.assembly.body.size_change)
        setasbodyRating(event.assembly.body.rating_value)
        setasbodyRatingChange(event.assembly.body.rating_change)
        setasbodyConnection(event.assembly.body.connection_value)
        setasbodyConnectionChange(event.assembly.body.connection_change)
        setasbodyDirection(event.assembly.body.direction_value)
        setasbodyDirectionChange(event.assembly.body.direction_change)
        setasbodyTrim(event.assembly.body.trim_value)
        setasbodyTrimChange(event.assembly.body.trim_change)
        setasbodyPort(event.assembly.body.port_value)
        setasbodyPortChange(event.assembly.body.port_change)
        setasbodyCv(event.assembly.body.cv_value)
        setasbodyCvChange(event.assembly.body.cv_change)
        setasbodyMpackage(event.assembly.body.mpackage_value)
        setasbodyMpackageChange(event.assembly.body.mpackage_change)
        setasbodyMjuntas(event.assembly.body.mjuntas_value)
        setasbodyMjuntasChange(event.assembly.body.mjuntas_change)
        setasbodyMbody(event.assembly.body.mbody_value)
        setasbodyMbodyChange(event.assembly.body.mbody_change)
        setasbodyMseat(event.assembly.body.mseat_value)
        setasbodyMseatChange(event.assembly.body.mseat_change)
        setasbodyMbox(event.assembly.body.mbox_value)
        setasbodyMboxChange(event.assembly.body.mbox_change)
        setasbodyMguide(event.assembly.body.mguide_value)
        setasbodyMguideChange(event.assembly.body.mguide_change)
        setasbodyMtap(event.assembly.body.mtap_value)
        setasbodyMtapChange(event.assembly.body.mtap_change)
        setasbodyMvas(event.assembly.body.mvas_value)
        setasbodyMvasChange(event.assembly.body.mvas_change)
      }

      //Assembly Actor items
      if(event.assembly.actor !== null && event.assembly.actor !== undefined){
        setasactorBrand(event.assembly.actor.brand_value)
        setasactorBrandChange(event.assembly.actor.brand_change)
        setasactorSerie(event.assembly.actor.serie_value)
        setasactorSerieChange(event.assembly.actor.serie_change)
        setasactorModel(event.assembly.actor.model_value)
        setasactorModelChange(event.assembly.actor.model_change)
        setasactorSize(event.assembly.actor.size_value)
        setasactorSizeChange(event.assembly.actor.size_change)
        setasactorAction(event.assembly.actor.action_value)
        setasactorActionChange(event.assembly.actor.action_change)
        setasactorRace(event.assembly.actor.race_value)
        setasactorRaceChange(event.assembly.actor.race_change)
        setasactorAjust(event.assembly.actor.adjust_value)
        setasactorAjustChange(event.assembly.actor.adjust_change)
        setasactorVol(event.assembly.actor.vol_value)
        setasactorVolChange(event.assembly.actor.vol_change)
        setasactorSignal(event.assembly.actor.signal_value)
        setasactorSignalChange(event.assembly.actor.signal_change)
      }

      //Assembly positionator items
      if(event.assembly.positionator !== null && event.assembly.positionator !== undefined){
        setasposBrand(event.assembly.positionator.brand_value)
        setasposBrandChange(event.assembly.positionator.brand_change)
        setasposModel(event.assembly.positionator.model_value)
        setasposModelChange(event.assembly.positionator.model_change)
        setasposSerie(event.assembly.positionator.serie_value)
        setasposSerieChange(event.assembly.positionator.serie_change)
        setasposSignal(event.assembly.positionator.signal_value)
        setasposSignalChange(event.assembly.positionator.signal_change)
        setasposAction(event.assembly.positionator.action_value)
        setasposActionChange(event.assembly.positionator.action_change)
        setasposChar(event.assembly.positionator.char_value)
        setasposCharChange(event.assembly.positionator.char_change)
        setasposBypass(event.assembly.positionator.bypass_value)
        setasposBypassChange(event.assembly.positionator.bypass_change)
      }
      
      //assembly other items
      if(event.assembly.otherAccesories !== null && event.assembly.otherAccesories !== undefined){
        setaothersTrans(event.assembly.otherAccesories.transductor_value)
        setaothersTransChange(event.assembly.otherAccesories.transductor_change)
        setaothersSignalIn(event.assembly.otherAccesories.signalIn_value)
        setaothersSignalInChange(event.assembly.otherAccesories.signalIn_change)
        setaothersSignalOut(event.assembly.otherAccesories.signalOut_value)
        setaothersSignalOutChange(event.assembly.otherAccesories.signalOut_change)
        setaothersSelen(event.assembly.otherAccesories.solenoide_value)
        setaothersSelenChange(event.assembly.otherAccesories.solenoide_change)
        setaothersAction(event.assembly.otherAccesories.action_value)
        setaothersActionChange(event.assembly.otherAccesories.action_change)
        setaothersVals(event.assembly.otherAccesories.valvs_value)
        setaothersValsChange(event.assembly.otherAccesories.valvs_change)
        setaothersTrip(event.assembly.otherAccesories.trip_value)
        setaothersTripChange(event.assembly.otherAccesories.trip_change)
        setaothersFilter(event.assembly.otherAccesories.filter_value)
        setaothersFilterChange(event.assembly.otherAccesories.filter_change)
        setaothersSwitch(event.assembly.otherAccesories.switch_value)
        setaothersSwitchChange(event.assembly.otherAccesories.switch_change)
        setaothersSpecial(event.assembly.otherAccesories.special_value)
        setaothersSpecialChange(event.assembly.otherAccesories.special_change)
        setaothersMeta(event.assembly.otherAccesories.meta)
      }
    }
    
    if(event.fixes !== undefined && event.fixes !== null){
      //fixes body items
      if(event.fixes.body !== undefined && event.fixes.body !== null){
        setfixbodyBrida(event.fixes.body.brida);
        setfixbodySuper(event.fixes.body.superficie);
        setfixbodyInf(event.fixes.body.brida_inf);
        setfixbodyBon(event.fixes.body.bonete);
        setfixbodyBox(event.fixes.body.caja);
        setfixbodyNotes(event.fixes.body.notes);
      }

      //fixes trim
      if(event.fixes.trim !== undefined && event.fixes.trim !== null){
        setfixtrimTBD(event.fixes.trim.tbd);
        setfixtrimCGR(event.fixes.trim.cgr);
        setfixtrimAni(event.fixes.trim.anillo);
        setfixtrimVf(event.fixes.trim.vf);
        setfixtrimSp(event.fixes.trim.sp);
        setfixtrimBuj(event.fixes.trim.bujes);
        setfixtrimNotes(event.fixes.trim.notes);
      }

      //fixes actor
      if(event.fixes.actor !== undefined && event.fixes.actor !== null){
        setfixactTap(event.fixes.actor.tapas);
        setfixactYug(event.fixes.actor.yugo);
        setfixactFle(event.fixes.actor.flecha);
        setfixactDiafra(event.fixes.actor.diafragma);
        setfixactSellos(event.fixes.actor.sellos);
        setfixactTorn(event.fixes.actor.tornilleria);
        setfixactPlaca(event.fixes.actor.placa);
        setfixactTest(event.fixes.actor.prueba);
        setfixactNotes(event.fixes.actor.notes);
      }

      //fixes other
      if(event.fixes.otherAccesories !== undefined && event.fixes.otherAccesories !== null){
        setfixotherPos(event.fixes.otherAccesories.posicionador);
        setfixotherBypass(event.fixes.otherAccesories.bypass);
        setfixotherIp(event.fixes.otherAccesories.ip);
        setfixotherTrip(event.fixes.otherAccesories.trip);
        setfixotherFilter(event.fixes.otherAccesories.filtro);
        setfixotherMan(event.fixes.otherAccesories.manometro);
        setfixotherSwitch(event.fixes.otherAccesories.switch);
        setfixotherSole(event.fixes.otherAccesories.solenoide);
        setfixotherVol(event.fixes.otherAccesories.volante);
        setfixotherTub(event.fixes.otherAccesories.tubing);
        setfixotherFit(event.fixes.otherAccesories.fittings);
        setfixotherMeta(event.fixes.otherAccesories.meta);
        setfixotherNotes(event.fixes.otherAccesories.notes);
      }
    }

    setPictures(event.pictures !== null && event.pictures !== undefined ? event.pictures : []);
    setHydros(event.hydro !== null && event.hydro !== undefined ? event.hydro : []);

    setPediments(event.pediment !== null && event.pediment !== undefined ? event.pediment: clearPediments)
  }

  const [isEditingEvent, setEditinEvent] = useState(false);
  const [patchingEvent, setPatchingEvent] = useState(false);
  const patchEvent = async (data) => {
    setPatchingEvent(true);

    if (data !== "") {
      if(customerSelected.length<=0){
        toast.error("Asigna un cliente", {position: 'bottom-right', style:{color:'white'}});
        setPatchingEvent(false);
        return;
      }

      if(userSelected.length<=0){
        toast.error("Asigna un responsable", {position: 'bottom-right', style:{color:'white'}});
        setPatchingEvent(false);
        return;
      }

      if(supervisorSelected.length<=0){
        toast.error("Asigna un supervisor", {position: 'bottom-right', style:{color:'white'}});
        setPatchingEvent(false);
        return;
      }

      data.startTime = startdate !== null ? startdate : null;
      data.endTime = enddate !== null ? enddate : null;
      data.customer = customerSelected;
      data.owner = userSelected;
      data.supervisor = supervisorSelected;
      data.report = content;
      data.pediment = pediment;
      
      const dataAssembly = {
          body: {
            brand_value: asbodyBrand,
            brand_change: asbodyBrandChange,
            serie_value: asbodySerie,
            serie_change: asbodySerieChange,
            model_value: asbodyModel,
            model_change: asbodyModelChange,
            size_value: asbodySize,
            size_change: asbodySizeChange,
            rating_value: asbodyRating,
            rating_change: asbodyRatingChange,
            connection_value: asbodyConnection,
            connection_change: asbodyConnectionChange,
            direction_value: asbodyDirection,
            direction_change: asbodyDirectionChange,
            trim_value: asbodyTrim,
            trim_change: asbodyTrimChange,
            port_value: asbodyPort,
            port_change: asbodyPortChange,
            cv_value: asbodyCv,
            cv_change: asbodyCvChange,
            mpackage_value: asbodyMpackage,
            mpackage_change: asbodyMpackageChange,
            mjuntas_value: asbodyMjuntas,
            mjuntas_change: asbodyMjuntasChange,
            mbody_value: asbodyMbody,
            mbody_change: asbodyMbodyChange,
            mseat_value: asbodyMseat,
            mseat_change: asbodyMseatChange,
            mbox_value: asbodyMbox,
            mbox_change: asbodyMboxChange,
            mguide_value: asbodyMguide,
            mguide_change: asbodyMguideChange,
            mtap_value: asbodyMtap,
            mtap_change: asbodyMtapChange,
            mvas_value: asbodyMvas,
            mvas_change: asbodyMvasChange,
          },
          actor: {
            brand_value: asactorBrand,
            brand_change: asactorBrandChange,
            serie_value: asactorSerie,
            serie_change: asactorSerieChange,
            model_value: asactorModel,
            model_change: asactorModelChange,
            size_value: asactorSize,
            size_change: asactorSizeChange,
            action_value: asactorAction,
            action_change: asactorActionChange,
            race_value: asactorRace,
            race_change: asactorRaceChange,
            adjust_value: asactorAjust,
            adjust_change: asactorAjustChange,
            vol_value: asactorVol,
            vol_change: asactorVolChange,
            signal_value: asactorSignal,
            signal_change: asactorSignalChange,
          },
          positionator: {
            brand_value: asposBrand,
            brand_change: asposBrandChange,
            model_value: asposModel,
            model_change: asposModelChange,
            serie_value: asposSerie,
            serie_change: asposSerieChange,
            signal_value: asposSignal,
            signal_change: asposSignalChange,
            action_value: asposAction,
            action_change: asposActionChange,
            char_value: asposChar,
            char_change: asposCharChange,
            bypass_value: asposBypass,
            bypass_change: asposBypassChange,
          },
          otherAccesories: {
            transductor_value: aothersTrans,
            transductor_change: aothersTransChange,
            signalIn_value: aothersSignalIn,
            signalIn_change: aothersSignalInChange,
            signalOut_value: aothersSignalOut,
            signalOut_change: aothersSignalOutChange,
            solenoide_value: aothersSelen,
            solenoide_change: aothersSelenChange,
            action_value: aothersAction,
            action_change: aothersActionChange,
            valvs_value: aothersVals,
            valvs_change: aothersValsChange,
            trip_value: aothersTrip,
            trip_change: aothersTripChange,
            filter_value: aothersFilter,
            filter_change: aothersFilterChange,
            switch_value: aothersSwitch,
            switch_change: aothersSwitchChange,
            special_value: aothersSpecial,
            special_change: aothersSpecialChange,
            meta: aothersMeta,
          },
      }
      data.assembly = dataAssembly;

      const dataFixes = {
          body: {
            brida: fixbodyBrida,
            superficie: fixbodySuper,
            brida_inf: fixbodyInf,
            bonete: fixbodyBon,
            caja: fixbodyBox,
            notes: fixbodyNotes,
          },
          trim: {
            tbd: fixtrimTBD,
            cgr: fixtrimCGR,
            anillo: fixtrimAni,
            vf: fixtrimVf,
            sp: fixtrimSp,
            bujes: fixtrimBuj,
            notes: fixtrimNotes,
          },
          actor: {
            tapas: fixactTap,
            yugo: fixactYug,
            flecha: fixactFle,
            diafragma: fixactDiafra,
            sellos: fixactSellos,
            tornilleria: fixactTorn,
            placa: fixactPlaca,
            prueba: fixactTest,
            notes: fixactNotes,
          },
          otherAccesories: {
            posicionador: fixotherPos,
            bypass: fixotherBypass,
            ip: fixotherIp,
            trip: fixotherTrip,
            filtro: fixotherFilter,
            manometro: fixotherMan,
            switch: fixotherSwitch,
            solenoide: fixotherSole,
            volante: fixotherVol,
            tubing: fixotherTub,
            fittings: fixotherFit,
            meta: fixotherMeta,
            notes: fixotherNotes,
          },
      }

      data.fixes = dataFixes;

      await axios.patch(`${API_SERVICE}/${currentEvent.uuid}`,data).then(response => {
        if(response.data){
          getEventList();
          setPatchingEvent(false);
          toast.success("¡Servicio actualizado con éxito!", {position: 'bottom-right', style:{color:'white'}});
        }
        setPatchingEvent(false);
      }).catch(e => {
        setPatchingEvent(false);
        toast.error("No se pudo actualizar el servicio: " + e.message, {position: 'bottom-right', style:{color:'white'}});
      });
    } else {
      errors.showMessages();
    }
  }

  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(
    false
  );

  const handleDeleteAction = (data) => {
    SweetAlert.fire({
      title: "¿Continuamos?",
      text: `Se eliminará el servicio para: ${data.customer[0].name} las imagenes e información se borrarán, la acción es irreversible.`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Si",
      cancelButtonText: "Cancelar",
      reverseButtons: true,
    }).then(async (result) => {
      if (result.value) {
        if(data.pictures !== null && data.pictures !== undefined){
          await Promise.all(data.pictures.map((img)=>{
            const deleteParams = {
              Bucket: S3_BUCKET,
              Key: `posts/${img.split('/posts/')[1]}`
            }
            myBucket.deleteObject(deleteParams).send();
          }))
        }

        if(data.hydro !== null && data.hydro !== undefined){
          await Promise.all(data.hydro.map((img)=>{
            const deleteParams = {
              Bucket: S3_BUCKET,
              Key: `posts/${img.split('/posts/')[1]}`
            }
            myBucket.deleteObject(deleteParams).send();
          }))
        }

        await axios.delete(`${API_SERVICE}/${data.uuid}`).then(response => {
          getEventList();
          SweetAlert.fire("¡Listo!", "servicio eliminado", "success");
        }).catch(error => {
          setTimeout(() => {
            toast.error(
              "Error al eliminar el servicio: " + error
            );
          }, 200);
        });
      }
    });
  }

  const filteredItems = dataEventList.filter(
    item =>
      JSON.stringify(item)
        .toLowerCase()
        .indexOf(filterText.toLowerCase()) !== -1
  );

  const subHeaderComponent = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <FilterComponent
        onFilter={e => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  const patchAssembly = async () => {
    setPatchingEvent(true);

    const data = {
      assembly: {
        body: {
          brand_value: asbodyBrand,
          brand_change: asbodyBrandChange,
          serie_value: asbodySerie,
          serie_change: asbodySerieChange,
          model_value: asbodyModel,
          model_change: asbodyModelChange,
          size_value: asbodySize,
          size_change: asbodySizeChange,
          rating_value: asbodyRating,
          rating_change: asbodyRatingChange,
          connection_value: asbodyConnection,
          connection_change: asbodyConnectionChange,
          direction_value: asbodyDirection,
          direction_change: asbodyDirectionChange,
          trim_value: asbodyTrim,
          trim_change: asbodyTrimChange,
          port_value: asbodyPort,
          port_change: asbodyPortChange,
          cv_value: asbodyCv,
          cv_change: asbodyCvChange,
          mpackage_value: asbodyMpackage,
          mpackage_change: asbodyMpackageChange,
          mjuntas_value: asbodyMjuntas,
          mjuntas_change: asbodyMjuntasChange,
          mbody_value: asbodyMbody,
          mbody_change: asbodyMbodyChange,
          mseat_value: asbodyMseat,
          mseat_change: asbodyMseatChange,
          mbox_value: asbodyMbox,
          mbox_change: asbodyMboxChange,
          mguide_value: asbodyMguide,
          mguide_change: asbodyMguideChange,
          mtap_value: asbodyMtap,
          mtap_change: asbodyMtapChange,
          mvas_value: asbodyMvas,
          mvas_change: asbodyMvasChange,
        },
        actor: {
          brand_value: asactorBrand,
          brand_change: asactorBrandChange,
          serie_value: asactorSerie,
          serie_change: asactorSerieChange,
          model_value: asactorModel,
          model_change: asactorModelChange,
          size_value: asactorSize,
          size_change: asactorSizeChange,
          action_value: asactorAction,
          action_change: asactorActionChange,
          race_value: asactorRace,
          race_change: asactorRaceChange,
          adjust_value: asactorAjust,
          adjust_change: asactorAjustChange,
          vol_value: asactorVol,
          vol_change: asactorVolChange,
          signal_value: asactorSignal,
          signal_change: asactorSignalChange,
        },
        positionator: {
          brand_value: asposBrand,
          brand_change: asposBrandChange,
          model_value: asposModel,
          model_change: asposModelChange,
          serie_value: asposSerie,
          serie_change: asposSerieChange,
          signal_value: asposSignal,
          signal_change: asposSignalChange,
          action_value: asposAction,
          action_change: asposActionChange,
          char_value: asposChar,
          char_change: asposCharChange,
          bypass_value: asposBypass,
          bypass_change: asposBypassChange,
        },
        otherAccesories: {
          transductor_value: aothersTrans,
          transductor_change: aothersTransChange,
          signalIn_value: aothersSignalIn,
          signalIn_change: aothersSignalInChange,
          signalOut_value: aothersSignalOut,
          signalOut_change: aothersSignalOutChange,
          solenoide_value: aothersSelen,
          solenoide_change: aothersSelenChange,
          action_value: aothersAction,
          action_change: aothersActionChange,
          valvs_value: aothersVals,
          valvs_change: aothersValsChange,
          trip_value: aothersTrip,
          trip_change: aothersTripChange,
          filter_value: aothersFilter,
          filter_change: aothersFilterChange,
          switch_value: aothersSwitch,
          switch_change: aothersSwitchChange,
          special_value: aothersSpecial,
          special_change: aothersSpecialChange,
          meta: aothersMeta,
        },
      }
    }

    await axios.patch(`${API_SERVICE}/${currentEvent.uuid}`,data).then(response => {
      if(response.data){
        setPatchingEvent(false);
        getEventList();
        toast.success("Información de armado actualizada con éxito!", {position: 'bottom-right', style:{color:'white'}});
      }
      setPatchingEvent(false);
    }).catch(e => {
      setPatchingEvent(false);
      toast.error("No se pudo actualizar la información de armado del servicio: " + e.message, {position: 'bottom-right', style:{color:'white'}});
    });
  }

  const patchFixes = async () => {
    setPatchingEvent(true);

    const data = {
      fixes: {
        body: {
          brida: fixbodyBrida,
          superficie: fixbodySuper,
          brida_inf: fixbodyInf,
          bonete: fixbodyBon,
          caja: fixbodyBox,
          notes: fixbodyNotes,
        },
        trim: {
          tbd: fixtrimTBD,
          cgr: fixtrimCGR,
          anillo: fixtrimAni,
          vf: fixtrimVf,
          sp: fixtrimSp,
          bujes: fixtrimBuj,
          notes: fixtrimNotes,
        },
        actor: {
          tapas: fixactTap,
          yugo: fixactYug,
          flecha: fixactFle,
          diafragma: fixactDiafra,
          sellos: fixactSellos,
          tornilleria: fixactTorn,
          placa: fixactPlaca,
          prueba: fixactTest,
          notes: fixactNotes,
        },
        otherAccesories: {
          posicionador: fixotherPos,
          bypass: fixotherBypass,
          ip: fixotherIp,
          trip: fixotherTrip,
          filtro: fixotherFilter,
          manometro: fixotherMan,
          switch: fixotherSwitch,
          solenoide: fixotherSole,
          volante: fixotherVol,
          tubing: fixotherTub,
          fittings: fixotherFit,
          meta: fixotherMeta,
          notes: fixotherNotes,
        },
      }
    }

    await axios.patch(`${API_SERVICE}/${currentEvent.uuid}`,data).then(response => {
      if(response.data){
        setPatchingEvent(false);
        getEventList();
        toast.success("Información de arreglos actualizada con éxito!", {position: 'bottom-right', style:{color:'white'}});
      }
      setPatchingEvent(false);
    }).catch(e => {
      setPatchingEvent(false);
      toast.error("No se pudo actualizar la información de arreglos del servicio: " + e.message, {position: 'bottom-right', style:{color:'white'}});
    });
  }

  const patchPediment = async () => {
    setPatchingEvent(true);

    const data = {
      pediment: pediment,
    }

    await axios.patch(`${API_SERVICE}/${currentEvent.uuid}`,data).then(response => {
      if(response.data){
        setPatchingEvent(false);
        getEventList();
        toast.success("Información de pedimento actualizado con éxito!", {position: 'bottom-right', style:{color:'white'}});
      }
      setPatchingEvent(false);
    }).catch(e => {
      setPatchingEvent(false);
      toast.error("No se pudo actualizar la información pedimento del servicio: " + e.message, {position: 'bottom-right', style:{color:'white'}});
    });
  }

  const [content, setContent] = useState("");
  const onChange = (evt) => {
    const newContent = evt.editor.getData();
    setContent(newContent);
  };

  useEffect(() => {
    if(
      user.role !== 'ADMIN' 
      && user.role !== "SISTEMAS"
      && user.role !== "GERENTE"
      && user.role !== "CAPTURISTA"
    ){
      navigate('/inicio');
    }
    getCustomersList();
    getEventList();
    getUsersList();
  }, [navigate]);

  return (
    <Fragment>
      <Breadcrumb parent="Producción" title="Servicios"/>
      <Container fluid={true}>
        <Row>
          <Col sm="12" style={{display:'flex', justifyContent:'flex-end', marginBottom: 20, paddingRight:30}}>
            <Button color="primary" onClick={toggle}>Crear Servicio</Button>
          </Col>
        </Row>

        <Row>
          <Col sm="12">
            <Card>
              <CardBody className="data-tables">
                <DataTable
                  columns={columns}
                  striped={true}
                  center={true}
                  data={filteredItems}
                  subHeader
                  pagination
                  subHeaderComponent={subHeaderComponent}
                  noDataComponent={<b style={{padding:10}}>No se encontraron servicios</b>}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>

      </Container>

      <Modal isOpen={modal} toggle={toggle} size="lg" style={{minWidth:'55%'}}>
      <ModalHeader toggle={toggle}>{isEditingEvent ? `Editar Servicio "${currentEvent.eventId}"` : `Crear Nuevo Servicio` }
        {isEditingEvent ?
        <>
          <div style={{display:'flex',justifyContent:'left'}}><a style={{color:'blue'}} href={`${process.env.REACT_APP_PUBLIC_URL}/report?uuid=${currentEvent.uuid}&type=SERVICE`} target="_blank">Ver Reporte</a></div>
          <div style={{display:'flex',justifyContent:'left'}}>
            <a style={{color:'blue'}} href={`${process.env.REACT_APP_PUBLIC_URL}/pediment?uuid=${currentEvent.uuid}&type=SERVICE`} target="_blank">Ver Pedimentos</a>
          </div>
        </>
        : null }
        <button className="btn-close invisible" type="button">
          <span aria-hidden="true" className="visible" onClick={toggle}></span>
        </button>
        </ModalHeader>
        <ModalBody>
          <div className="container">
            {
              editDocument ?
                <>
                  {isEditingEvent ?
                  <Nav className="nav-primary" tabs>
                    <NavItem>
                      <NavLink
                        style={{cursor:'pointer'}}
                        className={PrimarycolorLineTab === "1" ? "active" : ""}
                        onClick={() => setPrimarycolorLineTab("1")}
                      >
                        <i className="icofont icofont-list"></i>Detalles
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        id="assemblyTab"
                        style={{cursor:'pointer'}}
                        className={PrimarycolorLineTab === "2" ? "active" : ""}
                        onClick={() => setPrimarycolorLineTab("2")}
                      >
                        <i className="icofont icofont-settings"></i>Armado
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{cursor:'pointer'}}
                        className={PrimarycolorLineTab === "3" ? "active" : ""}
                        onClick={() => setPrimarycolorLineTab("3")}
                      >
                        <i className="icofont icofont-tools"></i>Reparaciones
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{cursor:'pointer'}}
                        className={PrimarycolorLineTab === "4" ? "active" : ""}
                        onClick={() => setPrimarycolorLineTab("4")}
                      >
                        <i className="icofont icofont-image"></i>Galería
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{cursor:'pointer'}}
                        className={PrimarycolorLineTab === "5" ? "active" : ""}
                        onClick={() => setPrimarycolorLineTab("5")}
                      >
                        <i className="icofont icofont-dashboard"></i>Reporte
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{cursor:'pointer'}}
                        className={PrimarycolorLineTab === "6" ? "active" : ""}
                        onClick={() => setPrimarycolorLineTab("6")}
                      >
                        <i className="icofont icofont-list"></i>Pedimento
                      </NavLink>
                    </NavItem>
                  </Nav> : null}
                  <TabContent activeTab={PrimarycolorLineTab}>
                    <TabPane className="fade show" tabId="1">
                      <Form className="needs-validation" noValidate="" onSubmit={handleSubmit( isEditingEvent ? patchEvent : saveEvent)} style={{padding:20}}>
                        <Row className="g-3 dflex mb-3">
                          <Col md="12">
                            <Label className="form-label font-weight-bold" for="validserviceId">
                              Servicio
                            </Label>
                            <input className="form-control" id="validserviceId" type="text" placeholder="Identificador del servicio" name="eventId" {...register("eventId", { required: true })} />
                            <span>{errors.eventId && "Identificador del servicio Requerido"}</span>
                          </Col>
                        </Row>
                        <Row className="g-3 dflex mb-3">
                          <Col md="12">
                            <Label className="form-label font-weight-bold" for="validPlaceId">
                              Lugar
                            </Label>
                            <input className="form-control" value={place} id="validPlaceId" type="text" placeholder="Lugar donde se realiza el servicio" name="place" {...register("place", { required: true })} onChange={(e)=>setPlace(e.target.value)} />
                            <span>{errors.place && "Lugar del servicio Requerido"}</span>
                          </Col>
                        </Row>
                        <Row className="g-3 dflex mb-3">
                          <Col md="12">
                            <Label className="form-label font-weight-bold" for="validContact">
                              Contacto
                            </Label>
                            <input className="form-control" id="validContact" type="text" placeholder="Contacto para el Cliente" name="contact" {...register("contact", { required: true })} />
                            <span>{errors.contact && "Contacto del servicio Requerido"}</span>
                          </Col>
                        </Row>
                        <Row className="g-3 dflex mb-3">
                          <Col md="12">
                            <Label className="form-label font-weight-bold" for="validLine">
                              Línea
                            </Label>
                            <input className="form-control" value="SERVICES" id="validLine" type="text" placeholder="Línea del Servicio" name="line" {...register("line", { required: true })} />
                            <span>{errors.line && "Línea del servicio Requerido"}</span>
                          </Col>
                        </Row>
                        <Row className="g-3 dflex mb-3">
                          <Col md="12">
                            <Label className="form-label font-weight-bold">
                              Fecha y hora Inicio
                            </Label>
                            <DatePicker className="form-control digits"
                                selected={startdate}
                                onChange={handleChangeStartDate}
                                selectsStart
                                locale={es}
                                dateFormat={'dd/MM/yyyy h:mm aa'}
                                showTimeSelect
                              />
                          </Col>
                        </Row>
                        <Row className="g-3 dflex mb-3">
                          <Col md="12">
                            <Label className="form-label font-weight-bold">
                              Fecha y hora Fin
                            </Label>
                            <DatePicker className="form-control digits"
                                selected={enddate}
                                onChange={handleChangeEndDate}
                                selectsStart
                                locale={es}
                                dateFormat={'dd/MM/yyyy h:mm aa'}
                                showTimeSelect
                              />
                          </Col>
                        </Row>
                        <Row className="g-3 dflex mb-3">
                          <Col md="12">
                            <Label className="form-label font-weight-bold">
                              Cliente
                            </Label>
                            <Typeahead
                              id="customers"
                              clearButton
                              defaultSelected={customerSelected}
                              labelKey="name"
                              options={dataCustomers}
                              placeholder="Buscar Cliente..."
                              onChange={(e)=>{setCustomerSelected(e)}}
                            />
                          </Col>
                        </Row>
                        <Row className="g-3 dflex mb-3">
                          <Col md="12">
                            <Label className="form-label font-weight-bold">
                              Realiza
                            </Label>
                            <Typeahead
                              id="users"
                              clearButton
                              defaultSelected={userSelected}
                              labelKey="name"
                              options={dataUsers}
                              placeholder="Buscar Usuarios..."
                              onChange={(e)=>{setUserSelected(e)}}
                            />
                          </Col>
                        </Row>
                        <Row className="g-3 dflex mb-3">
                          <Col md="12">
                            <Label className="form-label font-weight-bold">
                              Supervisa
                            </Label>
                            <Typeahead
                              id="users"
                              clearButton
                              defaultSelected={supervisorSelected}
                              labelKey="name"
                              options={dataUsers}
                              placeholder="Buscar Usuarios..."
                              onChange={(e)=>{setSupervisorSelected(e)}}
                            />
                          </Col>
                        </Row>
                        <Row className="g-3 dflex mb-3">
                          <Col md="12">
                            <Label className="form-label font-weight-bold" for="validdevice">
                              Equipo
                            </Label>
                            <input className="form-control" id="validdevice" type="text" placeholder="Nombre del Equipo" name="device" {...register("device", { required: true })} />
                            <span>{errors.device && "Nombre del Equipo Requerido"}</span>
                          </Col>
                        </Row>
                        <Row className="g-3 dflex mb-3">
                          <Col md="12">
                            <Label className="form-label font-weight-bold" for="validmodel">
                              Modelo
                            </Label>
                            <input className="form-control" id="validmodel" type="text" placeholder="Modelo del Equipo" name="modelo" {...register("modelo", { required: true })} />
                            <span>{errors.modelo && "Modelo del Equipo Requerido"}</span>
                          </Col>
                        </Row>
                        <Row className="g-3 dflex mb-3">
                          <Col md="12">
                            <Label className="form-label font-weight-bold" for="validbrand">
                              Marca
                            </Label>
                            <input className="form-control" id="validbrand" type="text" placeholder="Marca del Equipo" name="brand" {...register("brand", { required: true })} />
                            <span>{errors.brand && "Marca del Equipo Requerido"}</span>
                          </Col>
                        </Row>
                        <Row className="g-3 dflex mb-3">
                          <Col md="12">
                            <Label className="form-label font-weight-bold" for="status">
                              Estado del Servicio
                            </Label>
                            <select className="form-control" name="status" {...register("status", { required: true })} >
                              <option value="WAITING">POR REALIZAR</option>
                              <option value="WORKING">EN PROCESO</option>
                              <option value="DONE">TERMINADO</option>
                              <option value="SENT">EN RUTA DE ENTREGA</option>
                              <option value="FINISHED">ENTREGADO</option>
                              <option value="CANCELLED">CANCELADO</option>
                            </select>
                            <span>{errors.status && "Estado del Servicio es Requerido"}</span>
                          </Col>
                        </Row>
                        <Row className="g-3 dflex mb-3">
                          <Col md="12">
                            <Label className="form-label font-weight-bold" for="validjobDescription">
                              Descripción del Trabajo
                            </Label>
                            <textarea style={{minHeight:100}} className="form-control" id="validjobDescription" type="text" placeholder="Anotaciones" name="jobDescription" {...register("jobDescription")} />
                          </Col>
                        </Row>
                        <Row className="g-3 dflex mb-3">
                          <Col md="12">
                            <Label className="form-label font-weight-bold" for="validtarget">
                              Objetivo
                            </Label>
                            <input className="form-control" id="validtarget" type="text" placeholder="Objetivo" name="target" {...register("target")} />
                          </Col>
                        </Row>
                        <Row className="g-3 dflex mb-3">
                          <Col md="12">
                            <Label className="form-label font-weight-bold" for="validstatein">
                              Estado de los equipos al presentarse al sitio
                            </Label>
                            <input className="form-control" id="validstatein" type="text" placeholder="Estado del Equipo al llegar" name="stateIn" {...register("stateIn")} />
                          </Col>
                        </Row>
                        <Row className="g-3 dflex mb-3">
                          <Col md="12">
                            <Label className="form-label font-weight-bold" for="validstateout">
                              Estado de los equipos al dejar el sitio:
                            </Label>
                            <input className="form-control" id="validstateout" type="text" placeholder="Estado del Equipo al salir" name="stateOut" {...register("stateOut")} />
                          </Col>
                        </Row>
                        <Row className="g-3 dflex mb-3">
                          <Col md="12">
                            <Label className="form-label font-weight-bold" for="validReport">
                              Reporte detallado
                            </Label>                      
                            <CKEditors
                              activeclassName="p10"
                              content={content}
                              events={{
                                change: onChange,
                              }}
                            />
                          </Col>
                        </Row>
                        <Row className="g-3 dflex mb-3">
                          <Col md="12">
                            <Label className="form-label font-weight-bold" for="validLogo">
                              Logo
                            </Label>
                            <input className="form-control" id="validLogo" type="text" placeholder="Enlace a Logo" value={logoCiesa} name="logo" {...register("logo", { required: false })} onChange={(e)=>setlogoCiesa(e.target.value)} />
                          </Col>
                        </Row>
                        <Row className="g-3 dflex mb-3">
                          <Col md="12">
                            <Label className="form-label font-weight-bold" for="validRepName">
                              Representante
                            </Label>
                            <input className="form-control" id="validRepName" type="text" placeholder="Dejar vacio para mostrar CIESA" name="repName" {...register("repName", { required: false })} />
                          </Col>
                        </Row>
                        <Row className="g-3 dflex mb-3">
                          <Col md="12">
                            <Label className="form-label font-weight-bold" for="validRepAdd">
                              Dirección Representante
                            </Label>
                            <input className="form-control" id="validRepAdd" type="text" placeholder="Dejar vacio para mostrar dirección de CIESA" name="repAddress" {...register("repAddress", { required: false })} />
                          </Col>
                        </Row>
                        <Row className="g-3 dflex mb-3">
                          <Col md="12">
                            <Label className="form-label font-weight-bold" for="validRepAdd">
                              RFC Representante
                            </Label>
                            <input className="form-control" id="validRepAdd" type="text" placeholder="Dejar vacio para mostrar RFC de CIESA" name="repRfc" {...register("repRfc", { required: false })} />
                          </Col>
                        </Row>
                        <Row className="g-3 dflex mb-3">
                          <Col md="12">
                            <Label className="form-label font-weight-bold" for="validRepEm">
                              Email Representante
                            </Label>
                            <input className="form-control" id="validRepEm" type="text" placeholder="Dejar vacio para mostrar Email de CIESA" name="repEmail" {...register("repEmail", { required: false })} />
                          </Col>
                        </Row>
                        <Row className="g-3 dflex mb-3">
                          <Col md="12">
                            <Label className="form-label font-weight-bold" for="validRepPhone">
                              Teléfono Representante
                            </Label>
                            <input className="form-control" id="validRepPhone" type="text" placeholder="Dejar vacio para mostrar teléfono de CIESA" name="repPhone" {...register("repPhone", { required: false })} />
                          </Col>
                        </Row>
                        <Row style={{display:'flex', justifyContent:'end', paddingTop:20}}>
                        {
                          isEditingEvent ? 
                            <Button disabled={patchingEvent} className="btn btn-primary" style={{width:150}} color="primary">{"Guardar"}</Button> 
                          : 
                            <Button disabled={savingEvent} className="btn btn-primary" style={{width:150}} color="primary">{"Guardar"}</Button> 
                        }
                        </Row>
                      </Form>
                    </TabPane>
                    <TabPane tabId="2">
                      <div className="default-according" id="accordionclose">
                        <Card>
                          <CardHeader>
                            <h5 className="mb-0">
                              <Button as={Card.Header} className="btn btn-link" color="default" onClick={() => toggleAccordion(1)}>
                                CUERPO
                              </Button>
                            </h5>
                          </CardHeader>
                          <Collapse isOpen={isOpen === 1} style={{flexDirection:'start'}}>
                              <Row className="g-3 mb-3 p-4">
                                <Col md="12 flex d-flex">
                                  <Label className="form-label font-weight-bold" for="validTitle" style={{marginRight:10}}>
                                    Marca
                                  </Label>
                                  <input className="form-control" style={{marginRight:10}} type="text" onChange={(e)=>setasbodyBrand(e.target.value)} value={asbodyBrand} />
                                  <input className="form-control" type="text" placeholder="Cambios durante el embarque" onChange={(e)=>setasbodyBrandChange(e.target.value)} value={asbodyBrandChange}  />
                                </Col>
                                <Col md="12 flex d-flex">
                                  <Label className="form-label font-weight-bold" for="validTitle" style={{marginRight:10}}>
                                    No. Serie
                                  </Label>
                                  <input className="form-control" style={{marginRight:10}} type="text" onChange={(e)=>setasbodySerie(e.target.value)} value={asbodySerie} />
                                  <input className="form-control" type="text" placeholder="Cambios durante el embarque" onChange={(e)=>setasbodySerieChange(e.target.value)} value={asbodySerieChange}  />
                                </Col>
                                <Col md="12 flex d-flex">
                                  <Label className="form-label font-weight-bold" for="validTitle" style={{marginRight:10}}>
                                    Modelo
                                  </Label>
                                  <input className="form-control" style={{marginRight:10}} type="text" onChange={(e)=>setasbodyModel(e.target.value)} value={asbodyModel} />
                                  <input className="form-control" type="text" placeholder="Cambios durante el embarque" onChange={(e)=>setasbodyModelChange(e.target.value)} value={asbodyModelChange}  />
                                </Col>
                                <Col md="12 flex d-flex">
                                  <Label className="form-label font-weight-bold" for="validTitle" style={{marginRight:10}}>
                                    Tamaño
                                  </Label>
                                  <input className="form-control" style={{marginRight:10}} type="text" onChange={(e)=>setasbodySize(e.target.value)} value={asbodySize} />
                                  <input className="form-control" type="text" placeholder="Cambios durante el embarque" onChange={(e)=>setasbodySizeChange(e.target.value)} value={asbodySizeChange}  />
                                </Col>
                                <Col md="12 flex d-flex">
                                  <Label className="form-label font-weight-bold" for="validTitle" style={{marginRight:10}}>
                                    Rating
                                  </Label>
                                  <input className="form-control" style={{marginRight:10}} type="text" onChange={(e)=>setasbodyRating(e.target.value)} value={asbodyRating} />
                                  <input className="form-control" type="text" placeholder="Cambios durante el embarque" onChange={(e)=>setasbodyRatingChange(e.target.value)} value={asbodyRatingChange}  />
                                </Col>
                                <Col md="12 flex d-flex">
                                  <Label className="form-label font-weight-bold" for="validTitle" style={{marginRight:10}}>
                                    Conexión a Proceso
                                  </Label>
                                  <input className="form-control" style={{marginRight:10}} type="text" onChange={(e)=>setasbodyConnection(e.target.value)} value={asbodyConnection} />
                                  <input className="form-control" type="text" placeholder="Cambios durante el embarque" onChange={(e)=>setasbodyConnectionChange(e.target.value)} value={asbodyConnectionChange}  />
                                </Col>
                                <Col md="12 flex d-flex">
                                  <Label className="form-label font-weight-bold" for="validTitle" style={{marginRight:10}}>
                                    Dirección de Flujo
                                  </Label>
                                  <input className="form-control" style={{marginRight:10}} type="text" onChange={(e)=>setasbodyDirection(e.target.value)} value={asbodyDirection} />
                                  <input className="form-control" type="text" placeholder="Cambios durante el embarque" onChange={(e)=>setasbodyDirectionChange(e.target.value)} value={asbodyDirectionChange}  />
                                </Col>
                                <Col md="12 flex d-flex">
                                  <Label className="form-label font-weight-bold" for="validTitle" style={{marginRight:10}}>
                                    Caracteristicas de Trim
                                  </Label>
                                  <input className="form-control" style={{marginRight:10}} type="text" onChange={(e)=>setasbodyTrim(e.target.value)} value={asbodyTrim} />
                                  <input className="form-control" type="text" placeholder="Cambios durante el embarque" onChange={(e)=>setasbodyTrimChange(e.target.value)} value={asbodyTrimChange}  />
                                </Col>
                                <Col md="12 flex d-flex">
                                  <Label className="form-label font-weight-bold" for="validTitle" style={{marginRight:10}}>
                                    Tamaño del Puerto
                                  </Label>
                                  <input className="form-control" style={{marginRight:10}} type="text" onChange={(e)=>setasbodyPort(e.target.value)} value={asbodyPort} />
                                  <input className="form-control" type="text" placeholder="Cambios durante el embarque" onChange={(e)=>setasbodyPortChange(e.target.value)} value={asbodyPortChange}  />
                                </Col>
                                <Col md="12 flex d-flex">
                                  <Label className="form-label font-weight-bold" for="validTitle" style={{marginRight:10}}>
                                    CV
                                  </Label>
                                  <input className="form-control" style={{marginRight:10}} type="text" onChange={(e)=>setasbodyCv(e.target.value)} value={asbodyCv} />
                                  <input className="form-control" type="text" placeholder="Cambios durante el embarque" onChange={(e)=>setasbodyCvChange(e.target.value)} value={asbodyCvChange}  />
                                </Col>
                                <Col md="12 flex d-flex">
                                  <Label className="form-label font-weight-bold" for="validTitle" style={{marginRight:10}}>
                                    Material de Empaque
                                  </Label>
                                  <input className="form-control" style={{marginRight:10}} type="text" onChange={(e)=>setasbodyMpackage(e.target.value)} value={asbodyMpackage} />
                                  <input className="form-control" type="text" placeholder="Cambios durante el embarque" onChange={(e)=>setasbodyMpackageChange(e.target.value)} value={asbodyMpackageChange}  />
                                </Col>
                                <Col md="12 flex d-flex">
                                  <Label className="form-label font-weight-bold" for="validTitle" style={{marginRight:10}}>
                                    Material de las Juntas
                                  </Label>
                                  <input className="form-control" style={{marginRight:10}} type="text" onChange={(e)=>setasbodyMjuntas(e.target.value)} value={asbodyMjuntas} />
                                  <input className="form-control" type="text" placeholder="Cambios durante el embarque" onChange={(e)=>setasbodyMjuntasChange(e.target.value)} value={asbodyMjuntasChange}  />
                                </Col>
                                <Col md="12 flex d-flex">
                                  <Label className="form-label font-weight-bold" for="validTitle" style={{marginRight:10}}>
                                    Material del Cuerpo
                                  </Label>
                                  <input className="form-control" style={{marginRight:10}} type="text" onChange={(e)=>setasbodyMbody(e.target.value)} value={asbodyMbody} />
                                  <input className="form-control" type="text" placeholder="Cambios durante el embarque" onChange={(e)=>setasbodyMbodyChange(e.target.value)} value={asbodyMbodyChange}  />
                                </Col>
                                <Col md="12 flex d-flex">
                                  <Label className="form-label font-weight-bold" for="validTitle" style={{marginRight:10}}>
                                    Material del Asiento
                                  </Label>
                                  <input className="form-control" style={{marginRight:10}} type="text" onChange={(e)=>setasbodyMseat(e.target.value)} value={asbodyMseat} />
                                  <input className="form-control" type="text" placeholder="Cambios durante el embarque" onChange={(e)=>setasbodyMseatChange(e.target.value)} value={asbodyMseatChange}  />
                                </Col>
                                <Col md="12 flex d-flex">
                                  <Label className="form-label font-weight-bold" for="validTitle" style={{marginRight:10}}>
                                    Material de la Caja
                                  </Label>
                                  <input className="form-control" style={{marginRight:10}} type="text" onChange={(e)=>setasbodyMbox(e.target.value)} value={asbodyMbox} />
                                  <input className="form-control" type="text" placeholder="Cambios durante el embarque" onChange={(e)=>setasbodyMboxChange(e.target.value)} value={asbodyMboxChange}  />
                                </Col>
                                <Col md="12 flex d-flex">
                                  <Label className="form-label font-weight-bold" for="validTitle" style={{marginRight:10}}>
                                    Material de la Guía
                                  </Label>
                                  <input className="form-control" style={{marginRight:10}} type="text" onChange={(e)=>setasbodyMguide(e.target.value)} value={asbodyMguide} />
                                  <input className="form-control" type="text" placeholder="Cambios durante el embarque" onChange={(e)=>setasbodyMguideChange(e.target.value)} value={asbodyMguideChange}  />
                                </Col>
                                <Col md="12 flex d-flex">
                                  <Label className="form-label font-weight-bold" for="validTitle" style={{marginRight:10}}>
                                    Material del Tapón
                                  </Label>
                                  <input className="form-control" style={{marginRight:10}} type="text" onChange={(e)=>setasbodyMtap(e.target.value)} value={asbodyMtap} />
                                  <input className="form-control" type="text" placeholder="Cambios durante el embarque" onChange={(e)=>setasbodyMtapChange(e.target.value)} value={asbodyMtapChange}  />
                                </Col>
                                <Col md="12 flex d-flex">
                                  <Label className="form-label font-weight-bold" for="validTitle" style={{marginRight:10}}>
                                    Material del Vastago
                                  </Label>
                                  <input className="form-control" style={{marginRight:10}} type="text" onChange={(e)=>setasbodyMvas(e.target.value)} value={asbodyMvas} />
                                  <input className="form-control" type="text" placeholder="Cambios durante el embarque" onChange={(e)=>setasbodyMvasChange(e.target.value)} value={asbodyMvasChange}  />
                                </Col>
                              </Row>
                          </Collapse>
                        </Card>

                        <Card>
                          <CardHeader>
                            <h5 className="mb-0">
                              <Button as={Card.Header} className="btn btn-link" color="default" onClick={() => toggleAccordion(2)}>
                                ACTUADOR
                              </Button>
                            </h5>
                          </CardHeader>
                          <Collapse isOpen={isOpen === 2} style={{flexDirection:'start'}}>
                            <Row className="g-3 mb-3 p-4">
                              <Col md="12 flex d-flex">
                                <Label className="form-label font-weight-bold" for="validTitle" style={{marginRight:10}}>
                                  Marca
                                </Label>
                                <input className="form-control" style={{marginRight:10}} type="text" onChange={(e)=>setasactorBrand(e.target.value)} value={asactorBrand} />
                                <input className="form-control" type="text" placeholder="Cambios durante el embarque" onChange={(e)=>setasactorBrandChange(e.target.value)} value={asactorBrandChange}  />
                              </Col>
                              <Col md="12 flex d-flex">
                                <Label className="form-label font-weight-bold" for="validTitle" style={{marginRight:10}}>
                                  No. Serie
                                </Label>
                                <input className="form-control" style={{marginRight:10}} type="text" onChange={(e)=>setasactorSerie(e.target.value)} value={asactorSerie} />
                                <input className="form-control" type="text" placeholder="Cambios durante el embarque" onChange={(e)=>setasactorSerieChange(e.target.value)} value={asactorSerieChange}  />
                              </Col>
                              <Col md="12 flex d-flex">
                                <Label className="form-label font-weight-bold" for="validTitle" style={{marginRight:10}}>
                                  Modelo
                                </Label>
                                <input className="form-control" style={{marginRight:10}} type="text" onChange={(e)=>setasactorModel(e.target.value)} value={asactorModel} />
                                <input className="form-control" type="text" placeholder="Cambios durante el embarque" onChange={(e)=>setasactorModelChange(e.target.value)} value={asactorModelChange}  />
                              </Col>
                              <Col md="12 flex d-flex">
                                <Label className="form-label font-weight-bold" for="validTitle" style={{marginRight:10}}>
                                  Tamaño
                                </Label>
                                <input className="form-control" style={{marginRight:10}} type="text" onChange={(e)=>setasactorSize(e.target.value)} value={asactorSize} />
                                <input className="form-control" type="text" placeholder="Cambios durante el embarque" onChange={(e)=>setasactorSizeChange(e.target.value)} value={asactorSizeChange}  />
                              </Col>
                              <Col md="12 flex d-flex">
                                <Label className="form-label font-weight-bold" for="validTitle" style={{marginRight:10}}>
                                  Acción a Falla
                                </Label>
                                <input className="form-control" style={{marginRight:10}} type="text" onChange={(e)=>setasactorAction(e.target.value)} value={asactorAction} />
                                <input className="form-control" type="text" placeholder="Cambios durante el embarque" onChange={(e)=>setasactorActionChange(e.target.value)} value={asactorActionChange}  />
                              </Col>
                              <Col md="12 flex d-flex">
                                <Label className="form-label font-weight-bold" for="validTitle" style={{marginRight:10}}>
                                  Carrera
                                </Label>
                                <input className="form-control" style={{marginRight:10}} type="text" onChange={(e)=>setasactorRace(e.target.value)} value={asactorRace} />
                                <input className="form-control" type="text" placeholder="Cambios durante el embarque" onChange={(e)=>setasactorRaceChange(e.target.value)} value={asactorRaceChange}  />
                              </Col>
                              <Col md="12 flex d-flex">
                                <Label className="form-label font-weight-bold" for="validTitle" style={{marginRight:10}}>
                                  Ajuste de Banco
                                </Label>
                                <input className="form-control" style={{marginRight:10}} type="text" onChange={(e)=>setasactorAjust(e.target.value)} value={asactorAjust} />
                                <input className="form-control" type="text" placeholder="Cambios durante el embarque" onChange={(e)=>setasactorAjustChange(e.target.value)} value={asactorAjustChange}  />
                              </Col>
                              <Col md="12 flex d-flex">
                                <Label className="form-label font-weight-bold" for="validTitle" style={{marginRight:10}}>
                                  Volante
                                </Label>
                                <input className="form-control" style={{marginRight:10}} type="text" onChange={(e)=>setasactorVol(e.target.value)} value={asactorVol} />
                                <input className="form-control" type="text" placeholder="Cambios durante el embarque" onChange={(e)=>setasactorVolChange(e.target.value)} value={asactorVolChange}  />
                              </Col>
                              <Col md="12 flex d-flex">
                                <Label className="form-label font-weight-bold" for="validTitle" style={{marginRight:10}}>
                                  Señal de Control
                                </Label>
                                <input className="form-control" style={{marginRight:10}} type="text" onChange={(e)=>setasactorSignal(e.target.value)} value={asactorSignal} />
                                <input className="form-control" type="text" placeholder="Cambios durante el embarque" onChange={(e)=>setasactorSignalChange(e.target.value)} value={asactorSignalChange}  />
                              </Col>
                            </Row>
                          </Collapse>
                        </Card>

                        <Card>
                          <CardHeader>
                            <h5 className="mb-0">
                              <Button as={Card.Header} className="btn btn-link" color="default" onClick={() => toggleAccordion(3)}>
                                POSICIONADOR
                              </Button>
                            </h5>
                          </CardHeader>
                          <Collapse isOpen={isOpen === 3}>
                            <Row className="g-3 mb-3 p-4">
                              <Col md="12 flex d-flex">
                                <Label className="form-label font-weight-bold" for="validTitle" style={{marginRight:10}}>
                                  Marca
                                </Label>
                                <input className="form-control" style={{marginRight:10}} type="text" onChange={(e)=>setasposBrand(e.target.value)} value={asposBrand} />
                                <input className="form-control" type="text" placeholder="Cambios durante el embarque" onChange={(e)=>setasposBrandChange(e.target.value)} value={asposBrandChange}  />
                              </Col>
                              <Col md="12 flex d-flex">
                                <Label className="form-label font-weight-bold" for="validTitle" style={{marginRight:10}}>
                                  Modelo
                                </Label>
                                <input className="form-control" style={{marginRight:10}} type="text" onChange={(e)=>setasposModel(e.target.value)} value={asposModel} />
                                <input className="form-control" type="text" placeholder="Cambios durante el embarque" onChange={(e)=>setasposModelChange(e.target.value)} value={asposModelChange}  />
                              </Col>
                              <Col md="12 flex d-flex">
                                <Label className="form-label font-weight-bold" for="validTitle" style={{marginRight:10}}>
                                  No. Serie
                                </Label>
                                <input className="form-control" style={{marginRight:10}} type="text" onChange={(e)=>setasposSerie(e.target.value)} value={asposSerie} />
                                <input className="form-control" type="text" placeholder="Cambios durante el embarque" onChange={(e)=>setasposSerieChange(e.target.value)} value={asposSerieChange}  />
                              </Col>
                              <Col md="12 flex d-flex">
                                <Label className="form-label font-weight-bold" for="validTitle" style={{marginRight:10}}>
                                  Señal de Entrada
                                </Label>
                                <input className="form-control" style={{marginRight:10}} type="text" onChange={(e)=>setasposSignal(e.target.value)} value={asposSignal} />
                                <input className="form-control" type="text" placeholder="Cambios durante el embarque" onChange={(e)=>setasposSignalChange(e.target.value)} value={asposSignalChange}  />
                              </Col>
                              <Col md="12 flex d-flex">
                                <Label className="form-label font-weight-bold" for="validTitle" style={{marginRight:10}}>
                                  Acción
                                </Label>
                                <input className="form-control" style={{marginRight:10}} type="text" onChange={(e)=>setasposAction(e.target.value)} value={asposAction} />
                                <input className="form-control" type="text" placeholder="Cambios durante el embarque" onChange={(e)=>setasposActionChange(e.target.value)} value={asposActionChange}  />
                              </Col>
                              <Col md="12 flex d-flex">
                                <Label className="form-label font-weight-bold" for="validTitle" style={{marginRight:10}}>
                                  Caracterización
                                </Label>
                                <input className="form-control" style={{marginRight:10}} type="text" onChange={(e)=>setasposChar(e.target.value)} value={asposChar} />
                                <input className="form-control" type="text" placeholder="Cambios durante el embarque" onChange={(e)=>setasposCharChange(e.target.value)} value={asposCharChange}  />
                              </Col>
                              <Col md="12 flex d-flex">
                                <Label className="form-label font-weight-bold" for="validTitle" style={{marginRight:10}}>
                                  By Pass
                                </Label>
                                <input className="form-control" style={{marginRight:10}} type="text" onChange={(e)=>setasposBypass(e.target.value)} value={asposBypass} />
                                <input className="form-control" type="text" placeholder="Cambios durante el embarque" onChange={(e)=>setasposBypassChange(e.target.value)} value={asposBypassChange}  />
                              </Col>
                            </Row>
                          </Collapse>
                        </Card>

                        <Card>
                          <CardHeader>
                            <h5 className="mb-0">
                              <Button as={Card.Header} className="btn btn-link" color="default" onClick={() => toggleAccordion(4)}>
                                OTROS ACCESORIOS INSTALADOS
                              </Button>
                            </h5>
                          </CardHeader>
                          <Collapse isOpen={isOpen === 4}>
                            <Row className="g-3 mb-3 p-4">
                              <Col md="12 flex d-flex">
                                <Label className="form-label font-weight-bold" for="validTitle" style={{marginRight:10}}>
                                  Transductor I/P
                                </Label>
                                <input className="form-control" style={{marginRight:10}} type="text" onChange={(e)=>setaothersTrans(e.target.value)} value={aothersTrans} />
                                <input className="form-control" type="text" placeholder="Cambios durante el embarque" onChange={(e)=>setaothersTransChange(e.target.value)} value={aothersTransChange}  />
                              </Col>
                              <Col md="12 flex d-flex">
                                <Label className="form-label font-weight-bold" for="validTitle" style={{marginRight:10}}>
                                  Señal de Entrada
                                </Label>
                                <input className="form-control" style={{marginRight:10}} type="text" onChange={(e)=>setaothersSignalIn(e.target.value)} value={aothersSignalIn} />
                                <input className="form-control" type="text" placeholder="Cambios durante el embarque" onChange={(e)=>setaothersSignalInChange(e.target.value)} value={aothersSignalInChange}  />
                              </Col>
                              <Col md="12 flex d-flex">
                                <Label className="form-label font-weight-bold" for="validTitle" style={{marginRight:10}}>
                                  Señal de Salida
                                </Label>
                                <input className="form-control" style={{marginRight:10}} type="text" onChange={(e)=>setaothersSignalOut(e.target.value)} value={aothersSignalOut} />
                                <input className="form-control" type="text" placeholder="Cambios durante el embarque" onChange={(e)=>setaothersSignalOutChange(e.target.value)} value={aothersSignalOutChange}  />
                              </Col>
                              <Col md="12 flex d-flex">
                                <Label className="form-label font-weight-bold" for="validTitle" style={{marginRight:10}}>
                                  Solenoide
                                </Label>
                                <input className="form-control" style={{marginRight:10}} type="text" onChange={(e)=>setaothersSelen(e.target.value)} value={aothersSelen} />
                                <input className="form-control" type="text" placeholder="Cambios durante el embarque" onChange={(e)=>setaothersSelenChange(e.target.value)} value={aothersSelenChange}  />
                              </Col>
                              <Col md="12 flex d-flex">
                                <Label className="form-label font-weight-bold" for="validTitle" style={{marginRight:10}}>
                                  Acción
                                </Label>
                                <input className="form-control" style={{marginRight:10}} type="text" onChange={(e)=>setaothersAction(e.target.value)} value={aothersAction} />
                                <input className="form-control" type="text" placeholder="Cambios durante el embarque" onChange={(e)=>setaothersActionChange(e.target.value)} value={aothersActionChange}  />
                              </Col>
                              <Col md="12 flex d-flex">
                                <Label className="form-label font-weight-bold" for="validTitle" style={{marginRight:10}}>
                                  Valvulas de Look Up
                                </Label>
                                <input className="form-control" style={{marginRight:10}} type="text" onChange={(e)=>setaothersVals(e.target.value)} value={aothersVals} />
                                <input className="form-control" type="text" placeholder="Cambios durante el embarque" onChange={(e)=>setaothersValsChange(e.target.value)} value={aothersValsChange}  />
                              </Col>
                              <Col md="12 flex d-flex">
                                <Label className="form-label font-weight-bold" for="validTitle" style={{marginRight:10}}>
                                  Trip Value
                                </Label>
                                <input className="form-control" style={{marginRight:10}} type="text" onChange={(e)=>setaothersTrip(e.target.value)} value={aothersTrip} />
                                <input className="form-control" type="text" placeholder="Cambios durante el embarque" onChange={(e)=>setaothersTripChange(e.target.value)} value={aothersTripChange}  />
                              </Col>
                              <Col md="12 flex d-flex">
                                <Label className="form-label font-weight-bold" for="validTitle" style={{marginRight:10}}>
                                  Filtro Regulador
                                </Label>
                                <input className="form-control" style={{marginRight:10}} type="text" onChange={(e)=>setaothersFilter(e.target.value)} value={aothersFilter} />
                                <input className="form-control" type="text" placeholder="Cambios durante el embarque" onChange={(e)=>setaothersFilterChange(e.target.value)} value={aothersFilterChange}  />
                              </Col>
                              <Col md="12 flex d-flex">
                                <Label className="form-label font-weight-bold" for="validTitle" style={{marginRight:10}}>
                                  Switch de Posición
                                </Label>
                                <input className="form-control" style={{marginRight:10}} type="text" onChange={(e)=>setaothersSwitch(e.target.value)} value={aothersSwitch} />
                                <input className="form-control" type="text" placeholder="Cambios durante el embarque" onChange={(e)=>setaothersSwitchChange(e.target.value)} value={aothersSwitchChange}  />
                              </Col>
                              <Col md="12 flex d-flex">
                                <Label className="form-label font-weight-bold" for="validTitle" style={{marginRight:10}}>
                                  Construcción especial y comentarios
                                </Label>
                                <input className="form-control" style={{marginRight:10}} type="text" onChange={(e)=>setaothersSpecial(e.target.value)} value={aothersSpecial} />
                                <input className="form-control" type="text" placeholder="Cambios durante el embarque" onChange={(e)=>setaothersSpecialChange(e.target.value)} value={aothersSpecialChange}  />
                              </Col>
                              {/* {
                                aothersMeta.length > 0 ?
                                  aothersMeta.map((item,idx)=>{
                                    return(
                                      <Col key={idx} md="12 flex d-flex">
                                        <Label className="form-label font-weight-bold" for="validTitle" style={{marginRight:10}}>
                                          {item.title}
                                        </Label>
                                        <input className="form-control" style={{marginRight:10}} type="text" onChange={(e)=>setaothersSpecial(e.target.value)} value={item.value} />
                                        <input className="form-control" type="text" placeholder="Cambios durante el embarque" onChange={(e)=>setaothersSpecialChange(e.target.value)} value={item.change}  />
                                      </Col>
                                    )
                                  })
                                : null
                              } */}
                              <div>
                                + Agregar Campo
                              </div>
                            </Row>
                          </Collapse>

                          <Row style={{display:'flex', justifyContent:'end', paddingTop:20}}>
                            {
                              isEditingEvent ? 
                                <Button onClick={patchAssembly} disabled={patchingEvent} className="btn btn-primary" style={{width:150}} color="primary">{"Guardar"}</Button> 
                              : null
                            }
                          </Row>
                        </Card>
                      </div>
                    </TabPane>
                    <TabPane tabId="3">
                    <div className="default-according" id="accordionclose">
                        <Card>
                          <CardHeader>
                            <h5 className="mb-0">
                              <Button as={Card.Header} className="btn btn-link" color="default" onClick={() => toggleAccordion(1)}>
                                CONDICIONES DEL CUERPO
                              </Button>
                            </h5>
                          </CardHeader>
                          <Collapse isOpen={isOpen === 1} style={{flexDirection:'start'}}>
                            <Row className="g-3 mb-3 p-5">
                              <Col md="12 flex d-flex flex-wrap">
                                <Label className="form-label font-weight-bold" for="validTitle" style={{marginRight:10}}>
                                  BRIDAS:
                                </Label>
                                <p style={{marginLeft:10, marginRight:10}}>
                                  <input type="checkbox" onClick={(e)=>handleFixeds(e.target.checked,'brida','ok')} value={fixbodyBrida.ok} defaultChecked={fixbodyBrida.ok}></input> OK
                                </p>
                                <p style={{marginRight:10}}>
                                  <input type="checkbox" onClick={(e)=>handleFixeds(e.target.checked,'brida','des')} value={fixbodyBrida.des} defaultChecked={fixbodyBrida.des}></input> DESGASTADO
                                </p>
                                <p style={{marginRight:10}}>
                                  <input type="checkbox" onClick={(e)=>handleFixeds(e.target.checked,'brida','lav')} value={fixbodyBrida.lav} defaultChecked={fixbodyBrida.lav}></input> LAVADO
                                </p>
                                <p style={{marginRight:10}}>
                                  <input type="checkbox" onClick={(e)=>handleFixeds(e.target.checked,'brida','pic')} value={fixbodyBrida.pic} defaultChecked={fixbodyBrida.pic}></input> PICADO
                                </p>
                                <p style={{marginRight:10}}>
                                  <input type="checkbox" onClick={(e)=>handleFixeds(e.target.checked,'brida','ral')} value={fixbodyBrida.ral} defaultChecked={fixbodyBrida.ral}></input> RALLADO
                                </p>
                                <p style={{marginRight:10}}>
                                  <input type="checkbox" onClick={(e)=>handleFixeds(e.target.checked,'brida','san')} value={fixbodyBrida.san} defaultChecked={fixbodyBrida.san}></input> SANDBLASTEADO
                                </p>
                                <p style={{marginRight:10}}>
                                  <input type="checkbox" onClick={(e)=>handleFixeds(e.target.checked,'brida','pul')} value={fixbodyBrida.pul} defaultChecked={fixbodyBrida.pul}></input> PULIDO
                                </p>
                                <p style={{marginRight:10}}>
                                  <input type="checkbox" onClick={(e)=>handleFixeds(e.target.checked,'brida','sol')} value={fixbodyBrida.sol} defaultChecked={fixbodyBrida.sol}></input> SOLDADO
                                </p>
                                <p style={{marginRight:10}}>
                                  <input type="checkbox" onClick={(e)=>handleFixeds(e.target.checked,'brida','maq')} value={fixbodyBrida.maq} defaultChecked={fixbodyBrida.maq}></input> MAQUINADO
                                </p>
                                <p style={{marginRight:10}}>
                                  <input type="checkbox" onClick={(e)=>handleFixeds(e.target.checked,'brida','rem')} value={fixbodyBrida.rem} defaultChecked={fixbodyBrida.rem}></input> REMPLAZADO
                                </p>
                                <p style={{marginRight:10}}>
                                  <input type="checkbox" onClick={(e)=>handleFixeds(e.target.checked,'brida','pin')} value={fixbodyBrida.pin} defaultChecked={fixbodyBrida.pin}></input> PINTURA
                                </p>
                              </Col>
                              <Col md="12 flex d-flex flex-wrap">
                                <Label className="form-label font-weight-bold" for="validTitle" style={{marginRight:10}}>
                                  SUPERFICIE DE LAS JUNTAS:
                                </Label>
                                <p style={{marginLeft:10, marginRight:10}}>
                                  <input type="checkbox" onClick={(e)=>handleFixeds(e.target.checked,'super','ok')} value={fixbodySuper.ok} defaultChecked={fixbodySuper.ok}></input> OK
                                </p>
                                <p style={{marginRight:10}}>
                                  <input type="checkbox" onClick={(e)=>handleFixeds(e.target.checked,'super','des')} value={fixbodySuper.des} defaultChecked={fixbodySuper.des}></input> DESGASTADO
                                </p>
                                <p style={{marginRight:10}}>
                                  <input type="checkbox" onClick={(e)=>handleFixeds(e.target.checked,'super','lav')} value={fixbodySuper.lav} defaultChecked={fixbodySuper.lav}></input> LAVADO
                                </p>
                                <p style={{marginRight:10}}>
                                  <input type="checkbox" onClick={(e)=>handleFixeds(e.target.checked,'super','pic')} value={fixbodySuper.pic} defaultChecked={fixbodySuper.pic}></input> PICADO
                                </p>
                                <p style={{marginRight:10}}>
                                  <input type="checkbox" onClick={(e)=>handleFixeds(e.target.checked,'super','ral')} value={fixbodySuper.ral} defaultChecked={fixbodySuper.ral}></input> RALLADO
                                </p>
                                <p style={{marginRight:10}}>
                                  <input type="checkbox" onClick={(e)=>handleFixeds(e.target.checked,'super','san')} value={fixbodySuper.san} defaultChecked={fixbodySuper.san}></input> SANDBLASTEADO
                                </p>
                                <p style={{marginRight:10}}>
                                  <input type="checkbox" onClick={(e)=>handleFixeds(e.target.checked,'super','pul')} value={fixbodySuper.pul} defaultChecked={fixbodySuper.pul}></input> PULIDO
                                </p>
                                <p style={{marginRight:10}}>
                                  <input type="checkbox" onClick={(e)=>handleFixeds(e.target.checked,'super','sol')} value={fixbodySuper.sol} defaultChecked={fixbodySuper.sol}></input> SOLDADO
                                </p>
                                <p style={{marginRight:10}}>
                                  <input type="checkbox" onClick={(e)=>handleFixeds(e.target.checked,'super','maq')} value={fixbodySuper.maq} defaultChecked={fixbodySuper.maq}></input> MAQUINADO
                                </p>
                                <p style={{marginRight:10}}>
                                  <input type="checkbox" onClick={(e)=>handleFixeds(e.target.checked,'super','rem')} value={fixbodySuper.rem} defaultChecked={fixbodySuper.rem}></input> REMPLAZADO
                                </p>
                                <p style={{marginRight:10}}>
                                  <input type="checkbox" onClick={(e)=>handleFixeds(e.target.checked,'super','pin')} value={fixbodySuper.pin} defaultChecked={fixbodySuper.pin}></input> PINTURA
                                </p>
                              </Col>
                              <Col md="12 flex d-flex flex-wrap">
                                <Label className="form-label font-weight-bold" for="validTitle" style={{marginRight:10}}>
                                  BRIDA INFERIOR:
                                </Label>
                                <p style={{marginLeft:10, marginRight:10}}>
                                  <input type="checkbox" onClick={(e)=>handleFixeds(e.target.checked,'inf','ok')} value={fixbodyInf.ok} defaultChecked={fixbodyInf.ok}></input> OK
                                </p>
                                <p style={{marginRight:10}}>
                                  <input type="checkbox" onClick={(e)=>handleFixeds(e.target.checked,'inf','des')} value={fixbodyInf.des} defaultChecked={fixbodyInf.des}></input> DESGASTADO
                                </p>
                                <p style={{marginRight:10}}>
                                  <input type="checkbox" onClick={(e)=>handleFixeds(e.target.checked,'inf','lav')} value={fixbodyInf.lav} defaultChecked={fixbodyInf.lav}></input> LAVADO
                                </p>
                                <p style={{marginRight:10}}>
                                  <input type="checkbox" onClick={(e)=>handleFixeds(e.target.checked,'inf','pic')} value={fixbodyInf.pic} defaultChecked={fixbodyInf.pic}></input> PICADO
                                </p>
                                <p style={{marginRight:10}}>
                                  <input type="checkbox" onClick={(e)=>handleFixeds(e.target.checked,'inf','ral')} value={fixbodyInf.ral} defaultChecked={fixbodyInf.ral}></input> RALLADO
                                </p>
                                <p style={{marginRight:10}}>
                                  <input type="checkbox" onClick={(e)=>handleFixeds(e.target.checked,'inf','san')} value={fixbodyInf.san} defaultChecked={fixbodyInf.san}></input> SANDBLASTEADO
                                </p>
                                <p style={{marginRight:10}}>
                                  <input type="checkbox" onClick={(e)=>handleFixeds(e.target.checked,'inf','pul')} value={fixbodyInf.pul} defaultChecked={fixbodyInf.pul}></input> PULIDO
                                </p>
                                <p style={{marginRight:10}}>
                                  <input type="checkbox" onClick={(e)=>handleFixeds(e.target.checked,'inf','sol')} value={fixbodyInf.sol} defaultChecked={fixbodyInf.sol}></input> SOLDADO
                                </p>
                                <p style={{marginRight:10}}>
                                  <input type="checkbox" onClick={(e)=>handleFixeds(e.target.checked,'inf','maq')} value={fixbodyInf.maq} defaultChecked={fixbodyInf.maq}></input> MAQUINADO
                                </p>
                                <p style={{marginRight:10}}>
                                  <input type="checkbox" onClick={(e)=>handleFixeds(e.target.checked,'inf','rem')} value={fixbodyInf.rem} defaultChecked={fixbodyInf.rem}></input> REMPLAZADO
                                </p>
                                <p style={{marginRight:10}}>
                                  <input type="checkbox" onClick={(e)=>handleFixeds(e.target.checked,'inf','pin')} value={fixbodyInf.pin} defaultChecked={fixbodyInf.pin}></input> PINTURA
                                </p>
                              </Col>
                              <Col md="12 flex d-flex flex-wrap">
                                <Label className="form-label font-weight-bold" for="validTitle" style={{marginRight:10}}>
                                  BONETE:
                                </Label>
                                <p style={{marginLeft:10, marginRight:10}}>
                                  <input type="checkbox" onClick={(e)=>handleFixeds(e.target.checked,'bon','ok')} value={fixbodyBon.ok} defaultChecked={fixbodyBon.ok}></input> OK
                                </p>
                                <p style={{marginRight:10}}>
                                  <input type="checkbox" onClick={(e)=>handleFixeds(e.target.checked,'bon','des')} value={fixbodyBon.des} defaultChecked={fixbodyBon.des}></input> DESGASTADO
                                </p>
                                <p style={{marginRight:10}}>
                                  <input type="checkbox" onClick={(e)=>handleFixeds(e.target.checked,'bon','lav')} value={fixbodyBon.lav} defaultChecked={fixbodyBon.lav}></input> LAVADO
                                </p>
                                <p style={{marginRight:10}}>
                                  <input type="checkbox" onClick={(e)=>handleFixeds(e.target.checked,'bon','pic')} value={fixbodyBon.pic} defaultChecked={fixbodyBon.pic}></input> PICADO
                                </p>
                                <p style={{marginRight:10}}>
                                  <input type="checkbox" onClick={(e)=>handleFixeds(e.target.checked,'bon','ral')} value={fixbodyBon.ral} defaultChecked={fixbodyBon.ral}></input> RALLADO
                                </p>
                                <p style={{marginRight:10}}>
                                  <input type="checkbox" onClick={(e)=>handleFixeds(e.target.checked,'bon','san')} value={fixbodyBon.san} defaultChecked={fixbodyBon.san}></input> SANDBLASTEADO
                                </p>
                                <p style={{marginRight:10}}>
                                  <input type="checkbox" onClick={(e)=>handleFixeds(e.target.checked,'bon','pul')} value={fixbodyBon.pul} defaultChecked={fixbodyBon.pul}></input> PULIDO
                                </p>
                                <p style={{marginRight:10}}>
                                  <input type="checkbox" onClick={(e)=>handleFixeds(e.target.checked,'bon','sol')} value={fixbodyBon.sol} defaultChecked={fixbodyBon.sol}></input> SOLDADO
                                </p>
                                <p style={{marginRight:10}}>
                                  <input type="checkbox" onClick={(e)=>handleFixeds(e.target.checked,'bon','maq')} value={fixbodyBon.maq} defaultChecked={fixbodyBon.maq}></input> MAQUINADO
                                </p>
                                <p style={{marginRight:10}}>
                                  <input type="checkbox" onClick={(e)=>handleFixeds(e.target.checked,'bon','rem')} value={fixbodyBon.rem} defaultChecked={fixbodyBon.rem}></input> REMPLAZADO
                                </p>
                                <p style={{marginRight:10}}>
                                  <input type="checkbox" onClick={(e)=>handleFixeds(e.target.checked,'bon','pin')} value={fixbodyBon.pin} defaultChecked={fixbodyBon.pin}></input> PINTURA
                                </p>
                              </Col>
                              <Col md="12 flex d-flex flex-wrap">
                                <Label className="form-label font-weight-bold" for="validTitle" style={{marginRight:10}}>
                                  CAJA DE EMPAQUES:
                                </Label>
                                <p style={{marginLeft:10, marginRight:10}}>
                                  <input type="checkbox" onClick={(e)=>handleFixeds(e.target.checked,'box','ok')} value={fixbodyBox.ok} defaultChecked={fixbodyBox.ok}></input> OK
                                </p>
                                <p style={{marginRight:10}}>
                                  <input type="checkbox" onClick={(e)=>handleFixeds(e.target.checked,'box','des')} value={fixbodyBox.des} defaultChecked={fixbodyBox.des}></input> DESGASTADO
                                </p>
                                <p style={{marginRight:10}}>
                                  <input type="checkbox" onClick={(e)=>handleFixeds(e.target.checked,'box','lav')} value={fixbodyBox.lav} defaultChecked={fixbodyBox.lav}></input> LAVADO
                                </p>
                                <p style={{marginRight:10}}>
                                  <input type="checkbox" onClick={(e)=>handleFixeds(e.target.checked,'box','pic')} value={fixbodyBox.pic} defaultChecked={fixbodyBox.pic}></input> PICADO
                                </p>
                                <p style={{marginRight:10}}>
                                  <input type="checkbox" onClick={(e)=>handleFixeds(e.target.checked,'box','ral')} value={fixbodyBox.ral} defaultChecked={fixbodyBox.ral}></input> RALLADO
                                </p>
                                <p style={{marginRight:10}}>
                                  <input type="checkbox" onClick={(e)=>handleFixeds(e.target.checked,'box','san')} value={fixbodyBox.san} defaultChecked={fixbodyBox.san}></input> SANDBLASTEADO
                                </p>
                                <p style={{marginRight:10}}>
                                  <input type="checkbox" onClick={(e)=>handleFixeds(e.target.checked,'box','pul')} value={fixbodyBox.pul} defaultChecked={fixbodyBox.pul}></input> PULIDO
                                </p>
                                <p style={{marginRight:10}}>
                                  <input type="checkbox" onClick={(e)=>handleFixeds(e.target.checked,'box','sol')} value={fixbodyBox.sol} defaultChecked={fixbodyBox.sol}></input> SOLDADO
                                </p>
                                <p style={{marginRight:10}}>
                                  <input type="checkbox" onClick={(e)=>handleFixeds(e.target.checked,'box','maq')} value={fixbodyBox.maq} defaultChecked={fixbodyBox.maq}></input> MAQUINADO
                                </p>
                                <p style={{marginRight:10}}>
                                  <input type="checkbox" onClick={(e)=>handleFixeds(e.target.checked,'box','rem')} value={fixbodyBox.rem} defaultChecked={fixbodyBox.rem}></input> REMPLAZADO
                                </p>
                                <p style={{marginRight:10}}>
                                  <input type="checkbox" onClick={(e)=>handleFixeds(e.target.checked,'box','pin')} value={fixbodyBox.pin} defaultChecked={fixbodyBox.pin}></input> PINTURA
                                </p>
                              </Col>
                              <Col md="12 flex d-flex flex-wrap">
                                <Label className="form-label font-weight-bold" for="validTitle" style={{marginRight:10}}>
                                  Notas:
                                </Label>
                                <input placeholder="Notas y Comentarios" className="form-control mt-2 mb-3" type="text" value={fixbodyNotes} onChange={(e)=>setfixbodyNotes(e.target.value)} />
                              </Col>
                            </Row>
                          </Collapse>
                        </Card>

                        <Card>
                          <CardHeader>
                            <h5 className="mb-0">
                              <Button as={Card.Header} className="btn btn-link" color="default" onClick={() => toggleAccordion(2)}>
                                CONDICIONES DEL TRIM
                              </Button>
                            </h5>
                          </CardHeader>
                          <Collapse isOpen={isOpen === 2} style={{flexDirection:'start'}}>
                            <Row className="g-3 mb-3 p-5">
                              <Col md="12 flex d-flex flex-wrap">
                                <Label className="form-label font-weight-bold" for="validTitle" style={{marginRight:10}}>
                                  TAPON/BOLA/DISCO:
                                </Label>
                                <p style={{marginLeft:10, marginRight:10}}>
                                  <input type="checkbox" onClick={(e)=>handleFixeds(e.target.checked,'tbd','ok')} value={fixtrimTBD.ok} defaultChecked={fixtrimTBD.ok}></input> OK
                                </p>
                                <p style={{marginRight:10}}>
                                  <input type="checkbox" onClick={(e)=>handleFixeds(e.target.checked,'tbd','des')} value={fixtrimTBD.des} defaultChecked={fixtrimTBD.des}></input> DESGASTADO
                                </p>
                                <p style={{marginRight:10}}>
                                  <input type="checkbox" onClick={(e)=>handleFixeds(e.target.checked,'tbd','lav')} value={fixtrimTBD.lav} defaultChecked={fixtrimTBD.lav}></input> LAVADO
                                </p>
                                <p style={{marginRight:10}}>
                                  <input type="checkbox" onClick={(e)=>handleFixeds(e.target.checked,'tbd','pic')} value={fixtrimTBD.pic} defaultChecked={fixtrimTBD.pic}></input> PICADO
                                </p>
                                <p style={{marginRight:10}}>
                                  <input type="checkbox" onClick={(e)=>handleFixeds(e.target.checked,'tbd','ral')} value={fixtrimTBD.ral} defaultChecked={fixtrimTBD.ral}></input> RALLADO
                                </p>
                                <p style={{marginRight:10}}>
                                  <input type="checkbox" onClick={(e)=>handleFixeds(e.target.checked,'tbd','san')} value={fixtrimTBD.san} defaultChecked={fixtrimTBD.san}></input> SANDBLASTEADO
                                </p>
                                <p style={{marginRight:10}}>
                                  <input type="checkbox" onClick={(e)=>handleFixeds(e.target.checked,'tbd','pul')} value={fixtrimTBD.pul} defaultChecked={fixtrimTBD.pul}></input> PULIDO
                                </p>
                                <p style={{marginRight:10}}>
                                  <input type="checkbox" onClick={(e)=>handleFixeds(e.target.checked,'tbd','sol')} value={fixtrimTBD.sol} defaultChecked={fixtrimTBD.sol}></input> SOLDADO
                                </p>
                                <p style={{marginRight:10}}>
                                  <input type="checkbox" onClick={(e)=>handleFixeds(e.target.checked,'tbd','maq')} value={fixtrimTBD.maq} defaultChecked={fixtrimTBD.maq}></input> MAQUINADO
                                </p>
                                <p style={{marginRight:10}}>
                                  <input type="checkbox" onClick={(e)=>handleFixeds(e.target.checked,'tbd','rem')} value={fixtrimTBD.rem} defaultChecked={fixtrimTBD.rem}></input> REMPLAZADO
                                </p>
                              </Col>
                              <Col md="12 flex d-flex flex-wrap">
                                <Label className="form-label font-weight-bold" for="validTitle" style={{marginRight:10}}>
                                  CAJA/GUIA/RETENEDOR:
                                </Label>
                                <p style={{marginLeft:10, marginRight:10}}>
                                  <input type="checkbox" onClick={(e)=>handleFixeds(e.target.checked,'cgr','ok')} value={fixtrimCGR.ok} defaultChecked={fixtrimCGR.ok}></input> OK
                                </p>
                                <p style={{marginRight:10}}>
                                  <input type="checkbox" onClick={(e)=>handleFixeds(e.target.checked,'cgr','des')} value={fixtrimCGR.des} defaultChecked={fixtrimCGR.des}></input> DESGASTADO
                                </p>
                                <p style={{marginRight:10}}>
                                  <input type="checkbox" onClick={(e)=>handleFixeds(e.target.checked,'cgr','lav')} value={fixtrimCGR.lav} defaultChecked={fixtrimCGR.lav}></input> LAVADO
                                </p>
                                <p style={{marginRight:10}}>
                                  <input type="checkbox" onClick={(e)=>handleFixeds(e.target.checked,'cgr','pic')} value={fixtrimCGR.pic} defaultChecked={fixtrimCGR.pic}></input> PICADO
                                </p>
                                <p style={{marginRight:10}}>
                                  <input type="checkbox" onClick={(e)=>handleFixeds(e.target.checked,'cgr','ral')} value={fixtrimCGR.ral} defaultChecked={fixtrimCGR.ral}></input> RALLADO
                                </p>
                                <p style={{marginRight:10}}>
                                  <input type="checkbox" onClick={(e)=>handleFixeds(e.target.checked,'cgr','san')} value={fixtrimCGR.san} defaultChecked={fixtrimCGR.san}></input> SANDBLASTEADO
                                </p>
                                <p style={{marginRight:10}}>
                                  <input type="checkbox" onClick={(e)=>handleFixeds(e.target.checked,'cgr','pul')} value={fixtrimCGR.pul} defaultChecked={fixtrimCGR.pul}></input> PULIDO
                                </p>
                                <p style={{marginRight:10}}>
                                  <input type="checkbox" onClick={(e)=>handleFixeds(e.target.checked,'cgr','sol')} value={fixtrimCGR.sol} defaultChecked={fixtrimCGR.sol}></input> SOLDADO
                                </p>
                                <p style={{marginRight:10}}>
                                  <input type="checkbox" onClick={(e)=>handleFixeds(e.target.checked,'cgr','maq')} value={fixtrimCGR.maq} defaultChecked={fixtrimCGR.maq}></input> MAQUINADO
                                </p>
                                <p style={{marginRight:10}}>
                                  <input type="checkbox" onClick={(e)=>handleFixeds(e.target.checked,'cgr','rem')} value={fixtrimCGR.rem} defaultChecked={fixtrimCGR.rem}></input> REMPLAZADO
                                </p>
                              </Col>
                              <Col md="12 flex d-flex flex-wrap">
                                <Label className="form-label font-weight-bold" for="validTitle" style={{marginRight:10}}>
                                  ANILLO DE ASIENTO:
                                </Label>
                                <p style={{marginLeft:10, marginRight:10}}>
                                  <input type="checkbox" onClick={(e)=>handleFixeds(e.target.checked,'ani','ok')} value={fixtrimAni.ok} defaultChecked={fixtrimAni.ok}></input> OK
                                </p>
                                <p style={{marginRight:10}}>
                                  <input type="checkbox" onClick={(e)=>handleFixeds(e.target.checked,'ani','des')} value={fixtrimAni.des} defaultChecked={fixtrimAni.des}></input> DESGASTADO
                                </p>
                                <p style={{marginRight:10}}>
                                  <input type="checkbox" onClick={(e)=>handleFixeds(e.target.checked,'ani','lav')} value={fixtrimAni.lav} defaultChecked={fixtrimAni.lav}></input> LAVADO
                                </p>
                                <p style={{marginRight:10}}>
                                  <input type="checkbox" onClick={(e)=>handleFixeds(e.target.checked,'ani','pic')} value={fixtrimAni.pic} defaultChecked={fixtrimAni.pic}></input> PICADO
                                </p>
                                <p style={{marginRight:10}}>
                                  <input type="checkbox" onClick={(e)=>handleFixeds(e.target.checked,'ani','ral')} value={fixtrimAni.ral} defaultChecked={fixtrimAni.ral}></input> RALLADO
                                </p>
                                <p style={{marginRight:10}}>
                                  <input type="checkbox" onClick={(e)=>handleFixeds(e.target.checked,'ani','san')} value={fixtrimAni.san} defaultChecked={fixtrimAni.san}></input> SANDBLASTEADO
                                </p>
                                <p style={{marginRight:10}}>
                                  <input type="checkbox" onClick={(e)=>handleFixeds(e.target.checked,'ani','pul')} value={fixtrimAni.pul} defaultChecked={fixtrimAni.pul}></input> PULIDO
                                </p>
                                <p style={{marginRight:10}}>
                                  <input type="checkbox" onClick={(e)=>handleFixeds(e.target.checked,'ani','sol')} value={fixtrimAni.sol} defaultChecked={fixtrimAni.sol}></input> SOLDADO
                                </p>
                                <p style={{marginRight:10}}>
                                  <input type="checkbox" onClick={(e)=>handleFixeds(e.target.checked,'ani','maq')} value={fixtrimAni.maq} defaultChecked={fixtrimAni.maq}></input> MAQUINADO
                                </p>
                                <p style={{marginRight:10}}>
                                  <input type="checkbox" onClick={(e)=>handleFixeds(e.target.checked,'ani','rem')} value={fixtrimAni.rem} defaultChecked={fixtrimAni.rem}></input> REMPLAZADO
                                </p>
                              </Col>
                              <Col md="12 flex d-flex flex-wrap">
                                <Label className="form-label font-weight-bold" for="validTitle" style={{marginRight:10}}>
                                  VASTAGO/FLECHA:
                                </Label>
                                <p style={{marginLeft:10, marginRight:10}}>
                                  <input type="checkbox" onClick={(e)=>handleFixeds(e.target.checked,'vf','ok')} value={fixtrimVf.ok} defaultChecked={fixtrimVf.ok}></input> OK
                                </p>
                                <p style={{marginRight:10}}>
                                  <input type="checkbox" onClick={(e)=>handleFixeds(e.target.checked,'vf','des')} value={fixtrimVf.des} defaultChecked={fixtrimVf.des}></input> DESGASTADO
                                </p>
                                <p style={{marginRight:10}}>
                                  <input type="checkbox" onClick={(e)=>handleFixeds(e.target.checked,'vf','lav')} value={fixtrimVf.lav} defaultChecked={fixtrimVf.lav}></input> LAVADO
                                </p>
                                <p style={{marginRight:10}}>
                                  <input type="checkbox" onClick={(e)=>handleFixeds(e.target.checked,'vf','pic')} value={fixtrimVf.pic} defaultChecked={fixtrimVf.pic}></input> PICADO
                                </p>
                                <p style={{marginRight:10}}>
                                  <input type="checkbox" onClick={(e)=>handleFixeds(e.target.checked,'vf','ral')} value={fixtrimVf.ral} defaultChecked={fixtrimVf.ral}></input> RALLADO
                                </p>
                                <p style={{marginRight:10}}>
                                  <input type="checkbox" onClick={(e)=>handleFixeds(e.target.checked,'vf','san')} value={fixtrimVf.san} defaultChecked={fixtrimVf.san}></input> SANDBLASTEADO
                                </p>
                                <p style={{marginRight:10}}>
                                  <input type="checkbox" onClick={(e)=>handleFixeds(e.target.checked,'vf','pul')} value={fixtrimVf.pul} defaultChecked={fixtrimVf.pul}></input> PULIDO
                                </p>
                                <p style={{marginRight:10}}>
                                  <input type="checkbox" onClick={(e)=>handleFixeds(e.target.checked,'vf','sol')} value={fixtrimVf.sol} defaultChecked={fixtrimVf.sol}></input> SOLDADO
                                </p>
                                <p style={{marginRight:10}}>
                                  <input type="checkbox" onClick={(e)=>handleFixeds(e.target.checked,'vf','maq')} value={fixtrimVf.maq} defaultChecked={fixtrimVf.maq}></input> MAQUINADO
                                </p>
                                <p style={{marginRight:10}}>
                                  <input type="checkbox" onClick={(e)=>handleFixeds(e.target.checked,'vf','rem')} value={fixtrimVf.rem} defaultChecked={fixtrimVf.rem}></input> REMPLAZADO
                                </p>
                              </Col>
                              <Col md="12 flex d-flex flex-wrap">
                                <Label className="form-label font-weight-bold" for="validTitle" style={{marginRight:10}}>
                                  SEAL RING/PISTON RING:
                                </Label>
                                <p style={{marginLeft:10, marginRight:10}}>
                                  <input type="checkbox" onClick={(e)=>handleFixeds(e.target.checked,'sp','ok')} value={fixtrimSp.ok} defaultChecked={fixtrimSp.ok}></input> OK
                                </p>
                                <p style={{marginRight:10}}>
                                  <input type="checkbox" onClick={(e)=>handleFixeds(e.target.checked,'sp','des')} value={fixtrimSp.des} defaultChecked={fixtrimSp.des}></input> DESGASTADO
                                </p>
                                <p style={{marginRight:10}}>
                                  <input type="checkbox" onClick={(e)=>handleFixeds(e.target.checked,'sp','lav')} value={fixtrimSp.lav} defaultChecked={fixtrimSp.lav}></input> LAVADO
                                </p>
                                <p style={{marginRight:10}}>
                                  <input type="checkbox" onClick={(e)=>handleFixeds(e.target.checked,'sp','pic')} value={fixtrimSp.pic} defaultChecked={fixtrimSp.pic}></input> PICADO
                                </p>
                                <p style={{marginRight:10}}>
                                  <input type="checkbox" onClick={(e)=>handleFixeds(e.target.checked,'sp','ral')} value={fixtrimSp.ral} defaultChecked={fixtrimSp.ral}></input> RALLADO
                                </p>
                                <p style={{marginRight:10}}>
                                  <input type="checkbox" onClick={(e)=>handleFixeds(e.target.checked,'sp','san')} value={fixtrimSp.san} defaultChecked={fixtrimSp.san}></input> SANDBLASTEADO
                                </p>
                                <p style={{marginRight:10}}>
                                  <input type="checkbox" onClick={(e)=>handleFixeds(e.target.checked,'sp','pul')} value={fixtrimSp.pul} defaultChecked={fixtrimSp.pul}></input> PULIDO
                                </p>
                                <p style={{marginRight:10}}>
                                  <input type="checkbox" onClick={(e)=>handleFixeds(e.target.checked,'sp','sol')} value={fixtrimSp.sol} defaultChecked={fixtrimSp.sol}></input> SOLDADO
                                </p>
                                <p style={{marginRight:10}}>
                                  <input type="checkbox" onClick={(e)=>handleFixeds(e.target.checked,'sp','maq')} value={fixtrimSp.maq} defaultChecked={fixtrimSp.maq}></input> MAQUINADO
                                </p>
                                <p style={{marginRight:10}}>
                                  <input type="checkbox" onClick={(e)=>handleFixeds(e.target.checked,'sp','rem')} value={fixtrimSp.rem} defaultChecked={fixtrimSp.rem}></input> REMPLAZADO
                                </p>
                              </Col>
                              <Col md="12 flex d-flex flex-wrap">
                                <Label className="form-label font-weight-bold" for="validTitle" style={{marginRight:10}}>
                                  BUJES:
                                </Label>
                                <p style={{marginLeft:10, marginRight:10}}>
                                  <input type="checkbox" onClick={(e)=>handleFixeds(e.target.checked,'buj','ok')} value={fixtrimBuj.ok} defaultChecked={fixtrimBuj.ok}></input> OK
                                </p>
                                <p style={{marginRight:10}}>
                                  <input type="checkbox" onClick={(e)=>handleFixeds(e.target.checked,'buj','des')} value={fixtrimBuj.des} defaultChecked={fixtrimBuj.des}></input> DESGASTADO
                                </p>
                                <p style={{marginRight:10}}>
                                  <input type="checkbox" onClick={(e)=>handleFixeds(e.target.checked,'buj','lav')} value={fixtrimBuj.lav} defaultChecked={fixtrimBuj.lav}></input> LAVADO
                                </p>
                                <p style={{marginRight:10}}>
                                  <input type="checkbox" onClick={(e)=>handleFixeds(e.target.checked,'buj','pic')} value={fixtrimBuj.pic} defaultChecked={fixtrimBuj.pic}></input> PICADO
                                </p>
                                <p style={{marginRight:10}}>
                                  <input type="checkbox" onClick={(e)=>handleFixeds(e.target.checked,'buj','ral')} value={fixtrimBuj.ral} defaultChecked={fixtrimBuj.ral}></input> RALLADO
                                </p>
                                <p style={{marginRight:10}}>
                                  <input type="checkbox" onClick={(e)=>handleFixeds(e.target.checked,'buj','san')} value={fixtrimBuj.san} defaultChecked={fixtrimBuj.san}></input> SANDBLASTEADO
                                </p>
                                <p style={{marginRight:10}}>
                                  <input type="checkbox" onClick={(e)=>handleFixeds(e.target.checked,'buj','pul')} value={fixtrimBuj.pul} defaultChecked={fixtrimBuj.pul}></input> PULIDO
                                </p>
                                <p style={{marginRight:10}}>
                                  <input type="checkbox" onClick={(e)=>handleFixeds(e.target.checked,'buj','sol')} value={fixtrimBuj.sol} defaultChecked={fixtrimBuj.sol}></input> SOLDADO
                                </p>
                                <p style={{marginRight:10}}>
                                  <input type="checkbox" onClick={(e)=>handleFixeds(e.target.checked,'buj','maq')} value={fixtrimBuj.maq} defaultChecked={fixtrimBuj.maq}></input> MAQUINADO
                                </p>
                                <p style={{marginRight:10}}>
                                  <input type="checkbox" onClick={(e)=>handleFixeds(e.target.checked,'buj','rem')} value={fixtrimBuj.rem} defaultChecked={fixtrimBuj.rem}></input> REMPLAZADO
                                </p>
                              </Col>
                              <Col md="12 flex d-flex flex-wrap">
                                <Label className="form-label font-weight-bold" for="validTitle" style={{marginRight:10}}>
                                  Notas:
                                </Label>
                                <input placeholder="Notas y Comentarios" className="form-control mt-2 mb-3" type="text" value={fixtrimNotes} onChange={(e)=>setfixtrimNotes(e.target.value)} />
                              </Col>
                            </Row>
                          </Collapse>
                        </Card>

                        <Card>
                          <CardHeader>
                            <h5 className="mb-0">
                              <Button as={Card.Header} className="btn btn-link" color="default" onClick={() => toggleAccordion(3)}>
                                CONDICIONES DEL ACTUADOR
                              </Button>
                            </h5>
                          </CardHeader>
                          <Collapse isOpen={isOpen === 3}>
                            <Row className="g-3 mb-3 p-5">
                              <Col md="12 flex d-flex flex-wrap">
                                <Label className="form-label font-weight-bold" for="validTitle" style={{marginRight:10}}>
                                  TAPAS:
                                </Label>
                                <p style={{marginLeft:10, marginRight:10}}>
                                  <input type="checkbox" onClick={(e)=>handleFixeds(e.target.checked,'tap','ok')} value={fixactTap.ok} defaultChecked={fixactTap.ok}></input> OK
                                </p>
                                <p style={{marginRight:10}}>
                                  <input type="checkbox" onClick={(e)=>handleFixeds(e.target.checked,'tap','des')} value={fixactTap.des} defaultChecked={fixactTap.des}></input> DESGASTADO
                                </p>
                                <p style={{marginRight:10}}>
                                  <input type="checkbox" onClick={(e)=>handleFixeds(e.target.checked,'tap','lav')} value={fixactTap.lav} defaultChecked={fixactTap.lav}></input> LAVADO
                                </p>
                                <p style={{marginRight:10}}>
                                  <input type="checkbox" onClick={(e)=>handleFixeds(e.target.checked,'tap','pic')} value={fixactTap.pic} defaultChecked={fixactTap.pic}></input> PICADO
                                </p>
                                <p style={{marginRight:10}}>
                                  <input type="checkbox" onClick={(e)=>handleFixeds(e.target.checked,'tap','ral')} value={fixactTap.ral} defaultChecked={fixactTap.ral}></input> RALLADO
                                </p>
                                <p style={{marginRight:10}}>
                                  <input type="checkbox" onClick={(e)=>handleFixeds(e.target.checked,'tap','san')} value={fixactTap.san} defaultChecked={fixactTap.san}></input> SANDBLASTEADO
                                </p>
                                <p style={{marginRight:10}}>
                                  <input type="checkbox" onClick={(e)=>handleFixeds(e.target.checked,'tap','pul')} value={fixactTap.pul} defaultChecked={fixactTap.pul}></input> PULIDO
                                </p>
                                <p style={{marginRight:10}}>
                                  <input type="checkbox" onClick={(e)=>handleFixeds(e.target.checked,'tap','sol')} value={fixactTap.sol} defaultChecked={fixactTap.sol}></input> SOLDADO
                                </p>
                                <p style={{marginRight:10}}>
                                  <input type="checkbox" onClick={(e)=>handleFixeds(e.target.checked,'tap','maq')} value={fixactTap.maq} defaultChecked={fixactTap.maq}></input> MAQUINADO
                                </p>
                                <p style={{marginRight:10}}>
                                  <input type="checkbox" onClick={(e)=>handleFixeds(e.target.checked,'tap','rem')} value={fixactTap.rem} defaultChecked={fixactTap.rem}></input> REMPLAZADO
                                </p>
                                <p style={{marginRight:10}}>
                                  <input type="checkbox" onClick={(e)=>handleFixeds(e.target.checked,'tap','det')} value={fixactTap.det} defaultChecked={fixactTap.det}></input> DETALLADO
                                </p>
                              </Col>
                              <Col md="12 flex d-flex flex-wrap">
                                <Label className="form-label font-weight-bold" for="validTitle" style={{marginRight:10}}>
                                  YUGO:
                                </Label>
                                <p style={{marginLeft:10, marginRight:10}}>
                                  <input type="checkbox" onClick={(e)=>handleFixeds(e.target.checked,'yug','ok')} value={fixactYug.ok} defaultChecked={fixactYug.ok}></input> OK
                                </p>
                                <p style={{marginRight:10}}>
                                  <input type="checkbox" onClick={(e)=>handleFixeds(e.target.checked,'yug','des')} value={fixactYug.des} defaultChecked={fixactYug.des}></input> DESGASTADO
                                </p>
                                <p style={{marginRight:10}}>
                                  <input type="checkbox" onClick={(e)=>handleFixeds(e.target.checked,'yug','lav')} value={fixactYug.lav} defaultChecked={fixactYug.lav}></input> LAVADO
                                </p>
                                <p style={{marginRight:10}}>
                                  <input type="checkbox" onClick={(e)=>handleFixeds(e.target.checked,'yug','pic')} value={fixactYug.pic} defaultChecked={fixactYug.pic}></input> PICADO
                                </p>
                                <p style={{marginRight:10}}>
                                  <input type="checkbox" onClick={(e)=>handleFixeds(e.target.checked,'yug','ral')} value={fixactYug.ral} defaultChecked={fixactYug.ral}></input> RALLADO
                                </p>
                                <p style={{marginRight:10}}>
                                  <input type="checkbox" onClick={(e)=>handleFixeds(e.target.checked,'yug','san')} value={fixactYug.san} defaultChecked={fixactYug.san}></input> SANDBLASTEADO
                                </p>
                                <p style={{marginRight:10}}>
                                  <input type="checkbox" onClick={(e)=>handleFixeds(e.target.checked,'yug','pul')} value={fixactYug.pul} defaultChecked={fixactYug.pul}></input> PULIDO
                                </p>
                                <p style={{marginRight:10}}>
                                  <input type="checkbox" onClick={(e)=>handleFixeds(e.target.checked,'yug','sol')} value={fixactYug.sol} defaultChecked={fixactYug.sol}></input> SOLDADO
                                </p>
                                <p style={{marginRight:10}}>
                                  <input type="checkbox" onClick={(e)=>handleFixeds(e.target.checked,'yug','maq')} value={fixactYug.maq} defaultChecked={fixactYug.maq}></input> MAQUINADO
                                </p>
                                <p style={{marginRight:10}}>
                                  <input type="checkbox" onClick={(e)=>handleFixeds(e.target.checked,'yug','rem')} value={fixactYug.rem} defaultChecked={fixactYug.rem}></input> REMPLAZADO
                                </p>
                                <p style={{marginRight:10}}>
                                  <input type="checkbox" onClick={(e)=>handleFixeds(e.target.checked,'yug','det')} value={fixactYug.det} defaultChecked={fixactYug.det}></input> DETALLADO
                                </p>
                              </Col>
                              <Col md="12 flex d-flex flex-wrap">
                                <Label className="form-label font-weight-bold" for="validTitle" style={{marginRight:10}}>
                                  FLECHA:
                                </Label>
                                <p style={{marginLeft:10, marginRight:10}}>
                                  <input type="checkbox" onClick={(e)=>handleFixeds(e.target.checked,'fle','ok')} value={fixactFle.ok} defaultChecked={fixactFle.ok}></input> OK
                                </p>
                                <p style={{marginRight:10}}>
                                  <input type="checkbox" onClick={(e)=>handleFixeds(e.target.checked,'fle','des')} value={fixactFle.des} defaultChecked={fixactFle.des}></input> DESGASTADO
                                </p>
                                <p style={{marginRight:10}}>
                                  <input type="checkbox" onClick={(e)=>handleFixeds(e.target.checked,'fle','lav')} value={fixactFle.lav} defaultChecked={fixactFle.lav}></input> LAVADO
                                </p>
                                <p style={{marginRight:10}}>
                                  <input type="checkbox" onClick={(e)=>handleFixeds(e.target.checked,'fle','pic')} value={fixactFle.pic} defaultChecked={fixactFle.pic}></input> PICADO
                                </p>
                                <p style={{marginRight:10}}>
                                  <input type="checkbox" onClick={(e)=>handleFixeds(e.target.checked,'fle','ral')} value={fixactFle.ral} defaultChecked={fixactFle.ral}></input> RALLADO
                                </p>
                                <p style={{marginRight:10}}>
                                  <input type="checkbox" onClick={(e)=>handleFixeds(e.target.checked,'fle','san')} value={fixactFle.san} defaultChecked={fixactFle.san}></input> SANDBLASTEADO
                                </p>
                                <p style={{marginRight:10}}>
                                  <input type="checkbox" onClick={(e)=>handleFixeds(e.target.checked,'fle','pul')} value={fixactFle.pul} defaultChecked={fixactFle.pul}></input> PULIDO
                                </p>
                                <p style={{marginRight:10}}>
                                  <input type="checkbox" onClick={(e)=>handleFixeds(e.target.checked,'fle','sol')} value={fixactFle.sol} defaultChecked={fixactFle.sol}></input> SOLDADO
                                </p>
                                <p style={{marginRight:10}}>
                                  <input type="checkbox" onClick={(e)=>handleFixeds(e.target.checked,'fle','maq')} value={fixactFle.maq} defaultChecked={fixactFle.maq}></input> MAQUINADO
                                </p>
                                <p style={{marginRight:10}}>
                                  <input type="checkbox" onClick={(e)=>handleFixeds(e.target.checked,'fle','rem')} value={fixactFle.rem} defaultChecked={fixactFle.rem}></input> REMPLAZADO
                                </p>
                                <p style={{marginRight:10}}>
                                  <input type="checkbox" onClick={(e)=>handleFixeds(e.target.checked,'fle','det')} value={fixactFle.det} defaultChecked={fixactFle.det}></input> DETALLADO
                                </p>
                              </Col>
                              <Col md="12 flex d-flex flex-wrap">
                                <Label className="form-label font-weight-bold" for="validTitle" style={{marginRight:10}}>
                                  DIAFRAGMA:
                                </Label>
                                <p style={{marginLeft:10, marginRight:10}}>
                                  <input type="checkbox" onClick={(e)=>handleFixeds(e.target.checked,'diafra','ok')} value={fixactDiafra.ok} defaultChecked={fixactDiafra.ok}></input> OK
                                </p>
                                <p style={{marginRight:10}}>
                                  <input type="checkbox" onClick={(e)=>handleFixeds(e.target.checked,'diafra','des')} value={fixactDiafra.des} defaultChecked={fixactDiafra.des}></input> DESGASTADO
                                </p>
                                <p style={{marginRight:10}}>
                                  <input type="checkbox" onClick={(e)=>handleFixeds(e.target.checked,'diafra','lav')} value={fixactDiafra.lav} defaultChecked={fixactDiafra.lav}></input> LAVADO
                                </p>
                                <p style={{marginRight:10}}>
                                  <input type="checkbox" onClick={(e)=>handleFixeds(e.target.checked,'diafra','pic')} value={fixactDiafra.pic} defaultChecked={fixactDiafra.pic}></input> PICADO
                                </p>
                                <p style={{marginRight:10}}>
                                  <input type="checkbox" onClick={(e)=>handleFixeds(e.target.checked,'diafra','ral')} value={fixactDiafra.ral} defaultChecked={fixactDiafra.ral}></input> RALLADO
                                </p>
                                <p style={{marginRight:10}}>
                                  <input type="checkbox" onClick={(e)=>handleFixeds(e.target.checked,'diafra','san')} value={fixactDiafra.san} defaultChecked={fixactDiafra.san}></input> SANDBLASTEADO
                                </p>
                                <p style={{marginRight:10}}>
                                  <input type="checkbox" onClick={(e)=>handleFixeds(e.target.checked,'diafra','pul')} value={fixactDiafra.pul} defaultChecked={fixactDiafra.pul}></input> PULIDO
                                </p>
                                <p style={{marginRight:10}}>
                                  <input type="checkbox" onClick={(e)=>handleFixeds(e.target.checked,'diafra','sol')} value={fixactDiafra.sol} defaultChecked={fixactDiafra.sol}></input> SOLDADO
                                </p>
                                <p style={{marginRight:10}}>
                                  <input type="checkbox" onClick={(e)=>handleFixeds(e.target.checked,'diafra','maq')} value={fixactDiafra.maq} defaultChecked={fixactDiafra.maq}></input> MAQUINADO
                                </p>
                                <p style={{marginRight:10}}>
                                  <input type="checkbox" onClick={(e)=>handleFixeds(e.target.checked,'diafra','rem')} value={fixactDiafra.rem} defaultChecked={fixactDiafra.rem}></input> REMPLAZADO
                                </p>
                                <p style={{marginRight:10}}>
                                  <input type="checkbox" onClick={(e)=>handleFixeds(e.target.checked,'diafra','det')} value={fixactDiafra.det} defaultChecked={fixactDiafra.det}></input> DETALLADO
                                </p>
                              </Col>
                              <Col md="12 flex d-flex flex-wrap">
                                <Label className="form-label font-weight-bold" for="validTitle" style={{marginRight:10}}>
                                  SELLOS/O-RING:
                                </Label>
                                <p style={{marginLeft:10, marginRight:10}}>
                                  <input type="checkbox" onClick={(e)=>handleFixeds(e.target.checked,'sellos','ok')} value={fixactSellos.ok} defaultChecked={fixactSellos.ok}></input> OK
                                </p>
                                <p style={{marginRight:10}}>
                                  <input type="checkbox" onClick={(e)=>handleFixeds(e.target.checked,'sellos','des')} value={fixactSellos.des} defaultChecked={fixactSellos.des}></input> DESGASTADO
                                </p>
                                <p style={{marginRight:10}}>
                                  <input type="checkbox" onClick={(e)=>handleFixeds(e.target.checked,'sellos','lav')} value={fixactSellos.lav} defaultChecked={fixactSellos.lav}></input> LAVADO
                                </p>
                                <p style={{marginRight:10}}>
                                  <input type="checkbox" onClick={(e)=>handleFixeds(e.target.checked,'sellos','pic')} value={fixactSellos.pic} defaultChecked={fixactSellos.pic}></input> PICADO
                                </p>
                                <p style={{marginRight:10}}>
                                  <input type="checkbox" onClick={(e)=>handleFixeds(e.target.checked,'sellos','ral')} value={fixactSellos.ral} defaultChecked={fixactSellos.ral}></input> RALLADO
                                </p>
                                <p style={{marginRight:10}}>
                                  <input type="checkbox" onClick={(e)=>handleFixeds(e.target.checked,'sellos','san')} value={fixactSellos.san} defaultChecked={fixactSellos.san}></input> SANDBLASTEADO
                                </p>
                                <p style={{marginRight:10}}>
                                  <input type="checkbox" onClick={(e)=>handleFixeds(e.target.checked,'sellos','pul')} value={fixactSellos.pul} defaultChecked={fixactSellos.pul}></input> PULIDO
                                </p>
                                <p style={{marginRight:10}}>
                                  <input type="checkbox" onClick={(e)=>handleFixeds(e.target.checked,'sellos','sol')} value={fixactSellos.sol} defaultChecked={fixactSellos.sol}></input> SOLDADO
                                </p>
                                <p style={{marginRight:10}}>
                                  <input type="checkbox" onClick={(e)=>handleFixeds(e.target.checked,'sellos','maq')} value={fixactSellos.maq} defaultChecked={fixactSellos.maq}></input> MAQUINADO
                                </p>
                                <p style={{marginRight:10}}>
                                  <input type="checkbox" onClick={(e)=>handleFixeds(e.target.checked,'sellos','rem')} value={fixactSellos.rem} defaultChecked={fixactSellos.rem}></input> REMPLAZADO
                                </p>
                                <p style={{marginRight:10}}>
                                  <input type="checkbox" onClick={(e)=>handleFixeds(e.target.checked,'sellos','det')} value={fixactSellos.det} defaultChecked={fixactSellos.det}></input> DETALLADO
                                </p>
                              </Col>
                              <Col md="12 flex d-flex flex-wrap">
                                <Label className="form-label font-weight-bold" for="validTitle" style={{marginRight:10}}>
                                  TORNILLERIA:
                                </Label>
                                <p style={{marginLeft:10, marginRight:10}}>
                                  <input type="checkbox" onClick={(e)=>handleFixeds(e.target.checked,'torn','ok')} value={fixactTorn.ok} defaultChecked={fixactTorn.ok}></input> OK
                                </p>
                                <p style={{marginRight:10}}>
                                  <input type="checkbox" onClick={(e)=>handleFixeds(e.target.checked,'torn','des')} value={fixactTorn.des} defaultChecked={fixactTorn.des}></input> DESGASTADO
                                </p>
                                <p style={{marginRight:10}}>
                                  <input type="checkbox" onClick={(e)=>handleFixeds(e.target.checked,'torn','lav')} value={fixactTorn.lav} defaultChecked={fixactTorn.lav}></input> LAVADO
                                </p>
                                <p style={{marginRight:10}}>
                                  <input type="checkbox" onClick={(e)=>handleFixeds(e.target.checked,'torn','pic')} value={fixactTorn.pic} defaultChecked={fixactTorn.pic}></input> PICADO
                                </p>
                                <p style={{marginRight:10}}>
                                  <input type="checkbox" onClick={(e)=>handleFixeds(e.target.checked,'torn','ral')} value={fixactTorn.ral} defaultChecked={fixactTorn.ral}></input> RALLADO
                                </p>
                                <p style={{marginRight:10}}>
                                  <input type="checkbox" onClick={(e)=>handleFixeds(e.target.checked,'torn','san')} value={fixactTorn.san} defaultChecked={fixactTorn.san}></input> SANDBLASTEADO
                                </p>
                                <p style={{marginRight:10}}>
                                  <input type="checkbox" onClick={(e)=>handleFixeds(e.target.checked,'torn','pul')} value={fixactTorn.pul} defaultChecked={fixactTorn.pul}></input> PULIDO
                                </p>
                                <p style={{marginRight:10}}>
                                  <input type="checkbox" onClick={(e)=>handleFixeds(e.target.checked,'torn','sol')} value={fixactTorn.sol} defaultChecked={fixactTorn.sol}></input> SOLDADO
                                </p>
                                <p style={{marginRight:10}}>
                                  <input type="checkbox" onClick={(e)=>handleFixeds(e.target.checked,'torn','maq')} value={fixactTorn.maq} defaultChecked={fixactTorn.maq}></input> MAQUINADO
                                </p>
                                <p style={{marginRight:10}}>
                                  <input type="checkbox" onClick={(e)=>handleFixeds(e.target.checked,'torn','rem')} value={fixactTorn.rem} defaultChecked={fixactTorn.rem}></input> REMPLAZADO
                                </p>
                                <p style={{marginRight:10}}>
                                  <input type="checkbox" onClick={(e)=>handleFixeds(e.target.checked,'torn','det')} value={fixactTorn.det} defaultChecked={fixactTorn.det}></input> DETALLADO
                                </p>
                              </Col>
                              <Col md="12 flex d-flex flex-wrap">
                                <Label className="form-label font-weight-bold" for="validTitle" style={{marginRight:10}}>
                                  PLACA DE IDENTIFICACION:
                                </Label>
                                <p style={{marginLeft:10, marginRight:10}}>
                                  <input type="checkbox" onClick={(e)=>handleFixeds(e.target.checked,'placa','ok')} value={fixactPlaca.ok} defaultChecked={fixactPlaca.ok}></input> OK
                                </p>
                                <p style={{marginRight:10}}>
                                  <input type="checkbox" onClick={(e)=>handleFixeds(e.target.checked,'placa','des')} value={fixactPlaca.des} defaultChecked={fixactPlaca.des}></input> DESGASTADO
                                </p>
                                <p style={{marginRight:10}}>
                                  <input type="checkbox" onClick={(e)=>handleFixeds(e.target.checked,'placa','lav')} value={fixactPlaca.lav} defaultChecked={fixactPlaca.lav}></input> LAVADO
                                </p>
                                <p style={{marginRight:10}}>
                                  <input type="checkbox" onClick={(e)=>handleFixeds(e.target.checked,'placa','pic')} value={fixactPlaca.pic} defaultChecked={fixactPlaca.pic}></input> PICADO
                                </p>
                                <p style={{marginRight:10}}>
                                  <input type="checkbox" onClick={(e)=>handleFixeds(e.target.checked,'placa','ral')} value={fixactPlaca.ral} defaultChecked={fixactPlaca.ral}></input> RALLADO
                                </p>
                                <p style={{marginRight:10}}>
                                  <input type="checkbox" onClick={(e)=>handleFixeds(e.target.checked,'placa','san')} value={fixactPlaca.san} defaultChecked={fixactPlaca.san}></input> SANDBLASTEADO
                                </p>
                                <p style={{marginRight:10}}>
                                  <input type="checkbox" onClick={(e)=>handleFixeds(e.target.checked,'placa','pul')} value={fixactPlaca.pul} defaultChecked={fixactPlaca.pul}></input> PULIDO
                                </p>
                                <p style={{marginRight:10}}>
                                  <input type="checkbox" onClick={(e)=>handleFixeds(e.target.checked,'placa','sol')} value={fixactPlaca.sol} defaultChecked={fixactPlaca.sol}></input> SOLDADO
                                </p>
                                <p style={{marginRight:10}}>
                                  <input type="checkbox" onClick={(e)=>handleFixeds(e.target.checked,'placa','maq')} value={fixactPlaca.maq} defaultChecked={fixactPlaca.maq}></input> MAQUINADO
                                </p>
                                <p style={{marginRight:10}}>
                                  <input type="checkbox" onClick={(e)=>handleFixeds(e.target.checked,'placa','rem')} value={fixactPlaca.rem} defaultChecked={fixactPlaca.rem}></input> REMPLAZADO
                                </p>
                                <p style={{marginRight:10}}>
                                  <input type="checkbox" onClick={(e)=>handleFixeds(e.target.checked,'placa','det')} value={fixactPlaca.det} defaultChecked={fixactPlaca.det}></input> DETALLADO
                                </p>
                              </Col>
                              <Col md="12 flex d-flex flex-wrap">
                                <Label className="form-label font-weight-bold" for="validTitle" style={{marginRight:10}}>
                                  PRUEBA DE BLOQUEO:
                                </Label>
                                <p style={{marginLeft:10, marginRight:10}}>
                                  <input type="checkbox" onClick={(e)=>handleFixeds(e.target.checked,'test','paso')} value={fixactTest.paso} defaultChecked={fixactTest.paso}></input> PASO
                                </p>
                              </Col>
                              <Col md="12 flex d-flex flex-wrap">
                                <Label className="form-label font-weight-bold" for="validTitle" style={{marginRight:10}}>
                                  Notas:
                                </Label>
                                <input placeholder="Notas y Comentarios" className="form-control mt-2 mb-3" type="text" value={fixactNotes} onChange={(e)=>setfixactNotes(e.target.value)} />
                              </Col>
                            </Row>
                          </Collapse>
                        </Card>

                        <Card>
                          <CardHeader>
                            <h5 className="mb-0">
                              <Button as={Card.Header} className="btn btn-link" color="default" onClick={() => toggleAccordion(4)}>
                                CONDICIONES DE LOS ACCESORIOS
                              </Button>
                            </h5>
                          </CardHeader>
                          <Collapse isOpen={isOpen === 4}>
                            <Row className="g-3 mb-3 p-5">
                              <Col md="12 flex d-flex flex-wrap">
                                <Label className="form-label font-weight-bold" for="validTitle" style={{marginRight:10}}>
                                  POSICIONADOR:
                                </Label>
                                <p style={{marginLeft:10, marginRight:10}}>
                                  <input type="checkbox" onClick={(e)=>handleFixeds(e.target.checked,'pos','rep')} value={fixotherPos.rep} defaultChecked={fixotherPos.rep}></input> REPARADO
                                </p>
                                <p style={{marginRight:10}}>
                                  <input type="checkbox" onClick={(e)=>handleFixeds(e.target.checked,'pos','ree')} value={fixotherPos.ree} defaultChecked={fixotherPos.ree}></input> REEMPLAZADO
                                </p>
                                <p style={{marginRight:10}}>
                                  <input type="checkbox" onClick={(e)=>handleFixeds(e.target.checked,'pos','reu')} value={fixotherPos.reu} defaultChecked={fixotherPos.reu}></input> REUSADO
                                </p>
                              </Col>
                              <Col md="12 flex d-flex flex-wrap">
                                <Label className="form-label font-weight-bold" for="validTitle" style={{marginRight:10}}>
                                  BYPASS:
                                </Label>
                                <p style={{marginLeft:10, marginRight:10}}>
                                  <input type="checkbox" onClick={(e)=>handleFixeds(e.target.checked,'bypass','rep')} value={fixotherBypass.rep} defaultChecked={fixotherBypass.rep}></input> REPARADO
                                </p>
                                <p style={{marginRight:10}}>
                                  <input type="checkbox" onClick={(e)=>handleFixeds(e.target.checked,'bypass','ree')} value={fixotherBypass.ree} defaultChecked={fixotherBypass.ree}></input> REEMPLAZADO
                                </p>
                                <p style={{marginRight:10}}>
                                  <input type="checkbox" onClick={(e)=>handleFixeds(e.target.checked,'bypass','reu')} value={fixotherBypass.reu} defaultChecked={fixotherBypass.reu}></input> REUSADO
                                </p>
                              </Col>
                              <Col md="12 flex d-flex flex-wrap">
                                <Label className="form-label font-weight-bold" for="validTitle" style={{marginRight:10}}>
                                  I/P:
                                </Label>
                                <p style={{marginLeft:10, marginRight:10}}>
                                  <input type="checkbox" onClick={(e)=>handleFixeds(e.target.checked,'ip','rep')} value={fixotherIp.rep} defaultChecked={fixotherIp.rep}></input> REPARADO
                                </p>
                                <p style={{marginRight:10}}>
                                  <input type="checkbox" onClick={(e)=>handleFixeds(e.target.checked,'ip','ree')} value={fixotherIp.ree} defaultChecked={fixotherIp.ree}></input> REEMPLAZADO
                                </p>
                                <p style={{marginRight:10}}>
                                  <input type="checkbox" onClick={(e)=>handleFixeds(e.target.checked,'ip','reu')} value={fixotherIp.reu} defaultChecked={fixotherIp.reu}></input> REUSADO
                                </p>
                              </Col>
                              <Col md="12 flex d-flex flex-wrap">
                                <Label className="form-label font-weight-bold" for="validTitle" style={{marginRight:10}}>
                                  TRIP VALVE:
                                </Label>
                                <p style={{marginLeft:10, marginRight:10}}>
                                  <input type="checkbox" onClick={(e)=>handleFixeds(e.target.checked,'trip','rep')} value={fixotherTrip.rep} defaultChecked={fixotherTrip.rep}></input> REPARADO
                                </p>
                                <p style={{marginRight:10}}>
                                  <input type="checkbox" onClick={(e)=>handleFixeds(e.target.checked,'trip','ree')} value={fixotherTrip.ree} defaultChecked={fixotherTrip.ree}></input> REEMPLAZADO
                                </p>
                                <p style={{marginRight:10}}>
                                  <input type="checkbox" onClick={(e)=>handleFixeds(e.target.checked,'trip','reu')} value={fixotherTrip.reu} defaultChecked={fixotherTrip.reu}></input> REUSADO
                                </p>
                              </Col>
                              <Col md="12 flex d-flex flex-wrap">
                                <Label className="form-label font-weight-bold" for="validTitle" style={{marginRight:10}}>
                                  FILTRO REGULADOR:
                                </Label>
                                <p style={{marginLeft:10, marginRight:10}}>
                                  <input type="checkbox" onClick={(e)=>handleFixeds(e.target.checked,'filter','rep')} value={fixotherFilter.rep} defaultChecked={fixotherFilter.rep}></input> REPARADO
                                </p>
                                <p style={{marginRight:10}}>
                                  <input type="checkbox" onClick={(e)=>handleFixeds(e.target.checked,'filter','ree')} value={fixotherFilter.ree} defaultChecked={fixotherFilter.ree}></input> REEMPLAZADO
                                </p>
                                <p style={{marginRight:10}}>
                                  <input type="checkbox" onClick={(e)=>handleFixeds(e.target.checked,'filter','reu')} value={fixotherFilter.reu} defaultChecked={fixotherFilter.reu}></input> REUSADO
                                </p>
                              </Col>
                              <Col md="12 flex d-flex flex-wrap">
                                <Label className="form-label font-weight-bold" for="validTitle" style={{marginRight:10}}>
                                  MANOMETROS:
                                </Label>
                                <p style={{marginLeft:10, marginRight:10}}>
                                  <input type="checkbox" onClick={(e)=>handleFixeds(e.target.checked,'man','rep')} value={fixotherMan.rep} defaultChecked={fixotherMan.rep}></input> REPARADO
                                </p>
                                <p style={{marginRight:10}}>
                                  <input type="checkbox" onClick={(e)=>handleFixeds(e.target.checked,'man','ree')} value={fixotherMan.ree} defaultChecked={fixotherMan.ree}></input> REEMPLAZADO
                                </p>
                                <p style={{marginRight:10}}>
                                  <input type="checkbox" onClick={(e)=>handleFixeds(e.target.checked,'man','reu')} value={fixotherMan.reu} defaultChecked={fixotherMan.reu}></input> REUSADO
                                </p>
                              </Col>
                              <Col md="12 flex d-flex flex-wrap">
                                <Label className="form-label font-weight-bold" for="validTitle" style={{marginRight:10}}>
                                  SWITCH:
                                </Label>
                                <p style={{marginLeft:10, marginRight:10}}>
                                  <input type="checkbox" onClick={(e)=>handleFixeds(e.target.checked,'switch','rep')} value={fixotherSwitch.rep} defaultChecked={fixotherSwitch.rep}></input> REPARADO
                                </p>
                                <p style={{marginRight:10}}>
                                  <input type="checkbox" onClick={(e)=>handleFixeds(e.target.checked,'switch','ree')} value={fixotherSwitch.ree} defaultChecked={fixotherSwitch.ree}></input> REEMPLAZADO
                                </p>
                                <p style={{marginRight:10}}>
                                  <input type="checkbox" onClick={(e)=>handleFixeds(e.target.checked,'switch','reu')} value={fixotherSwitch.reu} defaultChecked={fixotherSwitch.reu}></input> REUSADO
                                </p>
                              </Col>
                              <Col md="12 flex d-flex flex-wrap">
                                <Label className="form-label font-weight-bold" for="validTitle" style={{marginRight:10}}>
                                  SOLENOIDE:
                                </Label>
                                <p style={{marginLeft:10, marginRight:10}}>
                                  <input type="checkbox" onClick={(e)=>handleFixeds(e.target.checked,'sole','rep')} value={fixotherSole.rep} defaultChecked={fixotherSole.rep}></input> REPARADO
                                </p>
                                <p style={{marginRight:10}}>
                                  <input type="checkbox" onClick={(e)=>handleFixeds(e.target.checked,'sole','ree')} value={fixotherSole.ree} defaultChecked={fixotherSole.ree}></input> REEMPLAZADO
                                </p>
                                <p style={{marginRight:10}}>
                                  <input type="checkbox" onClick={(e)=>handleFixeds(e.target.checked,'sole','reu')} value={fixotherSole.reu} defaultChecked={fixotherSole.reu}></input> REUSADO
                                </p>
                              </Col>
                              <Col md="12 flex d-flex flex-wrap">
                                <Label className="form-label font-weight-bold" for="validTitle" style={{marginRight:10}}>
                                  VOLANTE:
                                </Label>
                                <p style={{marginLeft:10, marginRight:10}}>
                                  <input type="checkbox" onClick={(e)=>handleFixeds(e.target.checked,'vol','rep')} value={fixotherVol.rep} defaultChecked={fixotherVol.rep}></input> REPARADO
                                </p>
                                <p style={{marginRight:10}}>
                                  <input type="checkbox" onClick={(e)=>handleFixeds(e.target.checked,'vol','ree')} value={fixotherVol.ree} defaultChecked={fixotherVol.ree}></input> REEMPLAZADO
                                </p>
                                <p style={{marginRight:10}}>
                                  <input type="checkbox" onClick={(e)=>handleFixeds(e.target.checked,'vol','reu')} value={fixotherVol.reu} defaultChecked={fixotherVol.reu}></input> REUSADO
                                </p>
                              </Col>
                              <Col md="12 flex d-flex flex-wrap">
                                <Label className="form-label font-weight-bold" for="validTitle" style={{marginRight:10}}>
                                  TUBING:
                                </Label>
                                <p style={{marginLeft:10, marginRight:10}}>
                                  <input type="checkbox" onClick={(e)=>handleFixeds(e.target.checked,'tub','rep')} value={fixotherTub.rep} defaultChecked={fixotherTub.rep}></input> REPARADO
                                </p>
                                <p style={{marginRight:10}}>
                                  <input type="checkbox" onClick={(e)=>handleFixeds(e.target.checked,'tub','ree')} value={fixotherTub.ree} defaultChecked={fixotherTub.ree}></input> REEMPLAZADO
                                </p>
                                <p style={{marginRight:10}}>
                                  <input type="checkbox" onClick={(e)=>handleFixeds(e.target.checked,'tub','reu')} value={fixotherTub.reu} defaultChecked={fixotherTub.reu}></input> REUSADO
                                </p>
                              </Col>
                              <Col md="12 flex d-flex flex-wrap">
                                <Label className="form-label font-weight-bold" for="validTitle" style={{marginRight:10}}>
                                  FITTINGS:
                                </Label>
                                <p style={{marginLeft:10, marginRight:10}}>
                                  <input type="checkbox" onClick={(e)=>handleFixeds(e.target.checked,'fit','rep')} value={fixotherFit.rep} defaultChecked={fixotherFit.rep}></input> REPARADO
                                </p>
                                <p style={{marginRight:10}}>
                                  <input type="checkbox" onClick={(e)=>handleFixeds(e.target.checked,'fit','ree')} value={fixotherFit.ree} defaultChecked={fixotherFit.ree}></input> REEMPLAZADO
                                </p>
                                <p style={{marginRight:10}}>
                                  <input type="checkbox" onClick={(e)=>handleFixeds(e.target.checked,'fit','reu')} value={fixotherFit.reu} defaultChecked={fixotherFit.reu}></input> REUSADO
                                </p>
                              </Col>
                              <Col md="12 flex d-flex flex-wrap">
                                <Label className="form-label font-weight-bold" for="validTitle" style={{marginRight:10}}>
                                  OTRO:
                                </Label>
                                <p style={{marginLeft:10, marginRight:10}}>
                                  <input type="checkbox" onClick={(e)=>handleFixeds(e.target.checked,'otr','rep')} value={fixotherMeta.rep} defaultChecked={fixotherMeta.rep}></input> REPARADO
                                </p>
                                <p style={{marginRight:10}}>
                                  <input type="checkbox" onClick={(e)=>handleFixeds(e.target.checked,'otr','ree')} value={fixotherMeta.ree} defaultChecked={fixotherMeta.ree}></input> REEMPLAZADO
                                </p>
                                <p style={{marginRight:10}}>
                                  <input type="checkbox" onClick={(e)=>handleFixeds(e.target.checked,'otr','reu')} value={fixotherMeta.reu} defaultChecked={fixotherMeta.reu}></input> REUSADO
                                </p>
                              </Col>
                              <Col md="12 flex d-flex flex-wrap">
                                <Label className="form-label font-weight-bold" for="validTitle" style={{marginRight:10}}>
                                  Notas:
                                </Label>
                                <input placeholder="Notas y Comentarios" className="form-control mt-2 mb-3" type="text" value={fixotherNotes} onChange={(e)=>setfixotherNotes(e.target.value)} />
                              </Col>
                            </Row>
                          </Collapse>
                          <Row style={{display:'flex', justifyContent:'end', paddingTop:20}}>
                            {
                              isEditingEvent ? 
                                <Button onClick={patchFixes} disabled={patchingEvent} className="btn btn-primary" style={{width:150}} color="primary">{"Guardar"}</Button> 
                              : null
                            }
                          </Row>
                        </Card>
                      </div>
                    </TabPane>
                    <TabPane tabId="4">
                      <h4>Capturar Imágenes:</h4>
                      <div style={{display:'flex',justifyContent:'center', marginBottom:30}} >
                        {/* {activeCamera?
                          <WebcamCapture attach={attach} callBack={handleviewCamera}></WebcamCapture>
                        : <button onClick={()=>setactiveCamera(true)}>+ Agregar Foto</button> } */}
                        <HydroCapture attach={attach}/>
                      </div>
                      <Card className="p-3
                      ">
                        <div className="row">
                          {
                            pictures.map((img,i)=>{
                              return(
                                <div key={i} className="flex justify-start mr-5 col-3">
                                  <img className="mb-5 rounded" src={img} alt={"image-" + i} style={{maxWidth:220, maxHeight:220}} />
                                  <div className="text-center" style={{background:'red', color:'white', width:30, height:30, paddingRight:10, paddingLeft:10, paddingTop:3, marginLeft:-15, borderRadius:50, cursor:'pointer', top:0, right: 10, position:'absolute'}} onClick={()=>handleDeleteActionPicture(img)}><b>x</b></div>
                                </div>
                              )
                            })
                          }
                        </div>
                      </Card>
                    </TabPane>
                    <TabPane tabId="5">
                      <h4>Cargar archivos Hydrostatic Test Report:</h4>
                      <HydroCapture attach={attachHydro}/>
                      <Card className="p-3 mt-3">
                        <div className="row">
                          {
                            hydros.map((img,i)=>{
                              return(
                                <div key={i} className="flex justify-start mr-5 col-3">
                                  <img className="mb-5 rounded" src={img} alt={"image-" + i} style={{width:'100%'}} />
                                  <div className="text-center" style={{background:'red', color:'white', width:30, height:30, paddingRight:10, paddingLeft:10, paddingTop:3, marginLeft:-15, borderRadius:50, cursor:'pointer', top:0, right: 10, position:'absolute'}} onClick={()=>handleDeleteActionHydro(img)}><b>x</b></div>
                                </div>
                              )
                            })
                          }
                        </div>
                      </Card>
                    </TabPane>
                    <TabPane tabId="6">
                      <Card className="p-3 mt-3">
                        <div className="row">
                          {
                            pediment.map((pediment,i)=>{
                              return(
                                <Row className="mb-2 p-1" style={{background:'#cfcfcf'}}>
                                  <Col md="3" xs="12">
                                    <input className="form-control" placeholder="No. Parte" type="text" onChange={(e)=>changePedimentValue(pediment.pid,'number',e.target.value)} value={pediment.number} />
                                  </Col>
                                  <Col md="3" xs="12">
                                    <input className="form-control"  type="text" placeholder="Descripción" onChange={(e)=>changePedimentValue(pediment.pid,'description',e.target.value)} value={pediment.description}  />
                                  </Col>
                                  <Col md="3" xs="12">
                                    <input className="form-control" type="number" placeholder="Cantidad" onChange={(e)=>changePedimentValue(pediment.pid,'qty',e.target.value)} value={pediment.qty}  />
                                  </Col>
                                  <Col md="3" xs="12">
                                    <input className="form-control" type="text" placeholder="Pedimento" onChange={(e)=>changePedimentValue(pediment.pid,'pediment',e.target.value)} value={pediment.pediment}  />
                                  </Col>
                                </Row>
                              )
                            })
                          }
                        </div>                          
                          <Row style={{display:'flex', justifyContent:'end', paddingTop:20}}>
                            {
                              isEditingEvent ? 
                                <Button onClick={patchPediment} disabled={patchingEvent} className="btn btn-primary" style={{width:150}} color="primary">{"Guardar"}</Button> 
                              : null
                            }
                          </Row>
                      </Card>
                    </TabPane>
                  </TabContent>
                </>
              :
              null
            }
          </div>
        </ModalBody>
      </Modal>
    </Fragment>
  );
};

export default Services;
