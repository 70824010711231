import React, { useState, useEffect } from "react";
import { Container, Row, Col, CardBody } from "reactstrap";
import "react-toastify/dist/ReactToastify.css";

import { useNavigate } from "react-router-dom";
import { API_QUOTE_PART } from "../redux/apiRoutes";
import axios from "axios";

import { jsPDF } from "jspdf";

import PhotoAlbum from "react-photo-album";

import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";

// import optional lightbox plugins
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import { File, Printer } from "react-feather";
import { estado } from "../utils/functions";

const QuotePart = () => {
  const [currentQuote, setcurrentQuote] = useState(null);

  const [getParamsUrl, setgetparamsUrl] = useState(false);
  const url = new URL((typeof document === "undefined") ? 'http://localhost' : document.URL);
  const navigate = useNavigate();

  const setURLParams = () => {
    if(url.searchParams.get("uuid")){
      getQuoteData(url.searchParams.get("uuid"));
    }else{
      navigate('/inicio');
    }
  }

  const getQuoteData = async (uuid,type) => {
    await axios.get(`${API_QUOTE_PART}?uuid=${uuid}`).then(response => {
      if(response.data.docs.length>0){
        setcurrentQuote(response.data.docs[0]);
      }else{
        navigate('/inicio');
      }
    }).catch(e => {
      console.log(e);
      //navigate('/inicio');
    });
  }

  const handlePrint = () => {
    window.print();
  }

  useEffect(() => {
    if(!getParamsUrl){
        setURLParams();
        setgetparamsUrl(true);
    }
  }, [getParamsUrl,setURLParams,setgetparamsUrl]);

  return (
    <div className="page-wrapper" style={{background:'#444'}}>
      <Container fluid={true} className="p-0">
        <div className="m-0">
          <Row>
            <Col md="12" style={{minWidth:700}}>
            {currentQuote !== null ? 
              <CardBody>
                <div className="mt-4 mb-4 cont text-center b-light">
                  <Row className="mb-3">
                    <Col md="3" xs="3">
                      <img src={require("../assets/images/logo/logo-ciesa.png")} alt="Ciesa" width={150} />
                    </Col>
                    <Col md="9" xs="9">
                      <h4 className="font-weight-bold">COTIZACION No. {currentQuote.quoteId}</h4>
                      <table border={0} style={{width:'100%'}}>
                        <tr>
                          <td className="font-weight-bold" style={{border:'1px solid black'}}>Fecha:</td>
                          <td style={{border:'1px solid black'}}>{currentQuote.createdAt.split('T')[0]}</td>
                          <td style={{border:'1px solid black'}} className="font-weight-bold">Cliente:</td>
                          <td style={{border:'1px solid black'}}>{currentQuote.customer[0].name}</td>
                        </tr>
                        <tr>
                          <td className="font-weight-bold" style={{border:'1px solid black'}}>Vigencia:</td>
                          <td style={{border:'1px solid black'}}>30 Días</td>
                          <td className="font-weight-bold" style={{border:'1px solid black'}}>Pago:</td>
                          <td style={{border:'1px solid black'}}>
                            {currentQuote.creditType === '100_FULL' ? '100% anticipo' : null}
                            {currentQuote.creditType === '30_DAYS' ? '30 días de crédito' : null}
                            {currentQuote.creditType === '50_50' ? '50% anticipo y 50% contra aviso de embarque' : null}
                            {currentQuote.creditType === '100_ADVICE_SHIPPING' ? '100% contra aviso de embarque' : null}
                          </td>
                        </tr>
                        <tr>
                          <td style={{border:'1px solid black'}} className="font-weight-bold">Atención:</td>
                          <td style={{border:'1px solid black'}}>{currentQuote.contact}</td>
                        </tr>
                      </table>
                    </Col>
                  </Row>
                  <Row className="mb-3 p-4">
                    <table border={1}>
                      <tr>
                        <td className="font-weight-bold" style={{border:'1px solid black', padding:8, fontSize:12}}>PARTIDA</td>
                        <td style={{border:'1px solid black', padding:8, fontSize:12}} className="font-weight-bold">CANTIDAD</td>
                        <td style={{border:'1px solid black', padding:8, fontSize:12}} className="font-weight-bold">DESCRIPCION</td>
                        <td style={{border:'1px solid black', padding:8, fontSize:12}} className="font-weight-bold">TIEMPO DE ENTREGA</td>
                        <td style={{border:'1px solid black', padding:8, fontSize:12}} className="font-weight-bold">PRECIO UNITARIO USD</td>
                        <td style={{border:'1px solid black', padding:8, fontSize:12}} className="font-weight-bold">IMPORTE TOTAL USD</td>
                      </tr>
                          {
                            currentQuote.parts.length > 0 ?
                              currentQuote.parts.map((part)=>{
                                return(
                                  <tr key={part.uuid}>
                                    <td style={{border:'1px solid black',padding:8, alignContent:'start', fontWeight:'bold'}}>{part.part}</td>
                                    <td style={{border:'1px solid black',padding:8, alignContent:'start', fontWeight:'bold'}}>{part.qty}</td>
                                    <td style={{border:'1px solid black', textAlign:'left', fontWeight:'bold', padding:8, maxWidth:450}}>{part.key} {part.description}
                                    </td>
                                    <td style={{border:'1px solid black',padding:8, alignContent:'start', fontWeight:'bold'}}>{part.estimatedTime}</td>
                                    <td style={{border:'1px solid black',padding:8, alignContent:'start', fontWeight:'bold'}}>${(part.price).toFixed(2)}</td>
                                    <td style={{border:'1px solid black',padding:8, alignContent:'start', fontWeight:'bold'}}>${(part.qty * part.price).toFixed(2)}</td>
                                  </tr>
                                )
                              })
                            : null
                          }
                          <tr>
                            <td colSpan={4} style={{border:'1px solid black',padding:8, alignContent:'end', fontWeight:'bold', textAlign:'end'}}>TOTAL USD:</td>
                            <td colSpan={4} style={{border:'1px solid black',padding:8, alignContent:'start', fontWeight:'bold', textAlign:'end'}}>${currentQuote.total.toFixed(2)}</td>
                          </tr>
                    </table>
                  </Row>
                  <Row className="mb-3 p-3">
                    <p><b>1. PAGO DE DOLARES. </b>En caso de vernos favorecidos con su pedido, favor de
tomar en cuenta que el tipo de moneda de esta cotización son dólares americanos,
cuya paridad se tomará a la fecha de liquidación de los bienes.</p>
                    <p><b>2. IMPUESTO.</b> Los precios no incluyen el 16% de IVA, por lo que al facturar se
                    adicionará para ser cubierto por el cliente.</p>
                    <p><b>3. PAGO EN PESOS</b> En caso de vernos favorecidos con su pedido, favor de tomar
                    en cuenta que la moeda es en pesos mexicanos.</p>
                    <p><b>4. CANCELACION</b> En caso de cancelación se hará un cargo por el 50% de la orden de
                    compra</p>
                    <br/>
                    <br/>
                    <br/>
                    <h5><b>CONDICIONES COMERCIALES</b></h5>
                    <p>I. El tipo de moneda de la cotización son dólares norteamericanos, pagaderos en Moneda
                    Nacional al tipo de cambio a la fecha de liquidación de los bienes</p>
                    <p>II. La forma de pago de los bienes será:<b>
                      {currentQuote.creditType === '100_FULL' ? '100% anticipo' : null}
                      {currentQuote.creditType === '30_DAYS' ? '30 días de crédito' : null}
                      {currentQuote.creditType === '50_50' ? '50% anticipo y 50% contra aviso de embarque' : null}
                      {currentQuote.creditType === '100_ADVICE_SHIPPING' ? '100% contra aviso de embarque' : null}
                    </b></p>
                    <p>III. El tiempo de entrega de los bienes está indicado en la columna correspondiente y empezará a correr a partir de la aceptación de su pedido, y de la recepción de su anticipo correspondiente</p>
                    <p>IV. El lugar de entrega de los bienes será el sitio de instalación de los mismos.</p>
                    <p>V. La vigencia de la presente es de 30 días.</p>
                    <p>"VI. Garantía del equipo: Control e Instrumentación Empresarial S.A. de C.V.
 garantiza sus productos contra defectos, siempre y cuando estos sean usados bajo las condiciones especificadas para su aplicación. "
Esta es vigente durante un periodo de 12 meses en operación continua.</p>
                    <p>VII. Control e Instrumentación Empresarial S.A. de C.V. quien podrá cotizarles en caso de requerir, capacitación, puesta en marcha y arranque de los equipos.</p>
                    <p>VIII. Al facturar se agregará el 16% del IVA correspondiente.</p>
                    <p>IX. En caso de vernos favorecidos con su pedido, favor de anexar la copia de su Registro Federal de Causantes.</p>
                    <h6>Agradecemos su preferencia por nuestros productos.</h6>
                    <br/>
                    <br/>
                    <br/>
                    <h4 style={{textTransform:'uppercase'}}>{currentQuote.owner ? "ING. " + currentQuote.owner[0].name : "ING. Christian Cuatepotzo"}<br/>
                    DEPARTAMENTO DE VENTAS</h4>
                  </Row>
                </div>
              </CardBody>
            : null }
            </Col>
          </Row>
        </div>

        <div className="hidePrint" onClick={handlePrint} style={{position: 'fixed', fontWeight:'bold', background: 'black', padding: 15, borderRadius: 50, color: 'white', width: 60, height:60, right: 30, bottom: 30, cursor: 'pointer', zIndex:100}}>
          <Printer size={30} />
        </div>
      </Container>
    </div>
  );
};

export default QuotePart;
