import React, { useState, useEffect } from "react";
import { Container, Row, Col, CardBody } from "reactstrap";
import "react-toastify/dist/ReactToastify.css";

import { useNavigate } from "react-router-dom";
import { API_SERVICE } from "../redux/apiRoutes";
import axios from "axios";

import { jsPDF } from "jspdf";

import PhotoAlbum from "react-photo-album";

import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";

// import optional lightbox plugins
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import { File, Printer } from "react-feather";
import { estado } from "../utils/functions";

const Report = () => {
  const [currentService, setcurrentService] = useState(null);
  const [index, setIndex] = useState(-1);

  const [getParamsUrl, setgetparamsUrl] = useState(false);
  const url = new URL((typeof document === "undefined") ? 'http://localhost' : document.URL);
  const navigate = useNavigate();

  const setURLParams = () => {
    if(url.searchParams.get("type") && url.searchParams.get("uuid")){
      getServiceData(url.searchParams.get("uuid"),url.searchParams.get("type"));
    }else{
      navigate('/inicio');
    }
  }

  const [hydros,setHydros] = useState([]);

  const getServiceData = async (uuid,type) => {
    await axios.get(`${API_SERVICE}?uuid=${uuid}&type=${type}`).then(response => {
      if(response.data.docs.length>0){
        setcurrentService(response.data.docs[0]);
        if(response.data.docs[0].pictures !== null && response.data.docs[0].pictures !== undefined){
          generatePhotos(response.data.docs[0].pictures);
        }
        if(response.data.docs[0].hydro !== null && response.data.docs[0].hydro !== undefined){
          setHydros(response.data.docs[0].hydro);
        }
      }else{
        navigate('/inicio');
      }
    }).catch(e => {
      console.log(e);
      //navigate('/inicio');
    });
  }

  const breakpoints = [3840, 2400, 1080, 640, 384, 256, 128, 96, 64, 48];
  const [photos, setPhotos] = useState([]);

  const generatePhotos = (photos) => {
    const photosMapped = [];
    photos.map((photo) => {
      const width = breakpoints[1];
      const height = (photo.height / photo.width) * width;
    
      photosMapped.push({
        src: photo,
        width: 400,
        height: 620,
        srcSet: breakpoints.map((breakpoint) => {
          const height = Math.round((620 / 420) * breakpoint);
          return {
            src: photo,
            width: breakpoint,
            height,
          };
        }),
      });
    });

    setPhotos(photosMapped);
  }

  const handlePrint = () => {
    let divsToHide = document.getElementsByClassName("hidePrint");
    for(let i = 0; i < divsToHide.length; i++){
        divsToHide[i].style.display = "none";
    }
    let divsToShow = document.getElementsByClassName("showPrint");
    for(let i = 0; i < divsToShow.length; i++){
        divsToShow[i].style.display = "flex";
    }

    window.print();
    setTimeout(()=>{
      for(let i = 0; i < divsToHide.length; i++){
        divsToHide[i].style.display = "initial";
      }
      for(let i = 0; i < divsToShow.length; i++){
        divsToShow[i].style.display = "none";
      }
    },2500);
  }

  // const [printingPDF, setprintingPDF] = useState(false);
  // const printPDF = (image) =>{
  //   setprintingPDF(true);
	// 	const pdf = new jsPDF('portrait', 'pt', 'letter');
  //   const elemento = document.getElementById("pdfDownload");

	// 	pdf.html(elemento, {
	// 		callback:(pdf) => {
  //       var img = new Image();
  //       img.src = image;
  //       img.setAttribute("crossorigin","anonymous");
  //       pdf.addImage(img, 'PNG', 215, 425, 180, 180);

  //       pdf.save("qr_fiel.pdf");
  //       setprintingPDF(false);
	// 		}
	// 	});
  // }

  useEffect(() => {
    if(!getParamsUrl){
        setURLParams();
        setgetparamsUrl(true);
    }
  }, [getParamsUrl,setURLParams,setgetparamsUrl]);

  return (
    <div className="page-wrapper" style={{background:'#444'}}>
      <Container fluid={true} className="p-0">
        <div className="m-0">
          <Row id="pdfDownload">
            <Col md="12" style={{minWidth:700}}>
            {currentService !== null ? 
              <CardBody>
                <div className="mt-4 mb-4 cont text-center b-light">
                  <Row className="mb-3">
                    <Col md="3" xs="3">
                      <img src={currentService.logo !== "" && currentService.logo !== undefined && currentService.logo !== null ? currentService.logo : "https://ciesa.site/logo192.png"} alt="Ciesa" width={150} />
                    </Col>
                    <Col md="9" xs="9">
                      <h5 className="font-weight-bold">
                        {currentService.repName !== undefined && currentService.repName !== null && currentService.repName !== "" ? currentService.repName : 
                        'CONTROL E INSTRUMENTACION EMPRESARIAL SA DE CV' }
                      </h5>
                      <p>
                        {currentService.repAddress !== undefined && currentService.repAddress !== null && currentService.repAddress !== "" ? currentService.repAddress : 
                        'Av. Torremolinos 3331 Colinas del Rey Zapopan Jalisco' }
                        ; RFC: 
                        {currentService.repRfc !== undefined && currentService.repRfc !== null && currentService.repRfc !== "" ? currentService.repRfc : 
                        'CIE1011033UA' }
                        ; tel: 
                        {currentService.repPhone !== undefined && currentService.repPhone !== null && currentService.repPhone !== "" ? <a href={`tel:${currentService.repPhone}`}>{currentService.repPhone}</a> : 
                        <a href="tel:3333334985">33334985</a> }
                        <br/>Mail: 
                        {currentService.repEmail !== undefined && currentService.repEmail !== null && currentService.repEmail !== "" ? <a href={`mailto:${currentService.repEmail}`}>{currentService.repEmail}</a> : 
                        <a href="mailto:francisco@grupo-ciesa.com.mx">francisco@grupo-ciesa.com.mx</a> }
                      </p>
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <h4 className="font-weight-bold">{currentService.type === "NEW" || currentService.type === "FIXED" ? 'NÚMERO DE SERIE' : 'REPORTE DE SERVICIO'}</h4>
                    {currentService.type === "NEW" || currentService.type === "FIXED" ? 
                    <h4 className="font-weight-bold">{currentService.autoSerial}</h4>
                    :
                    <h4 className="font-weight-bold">{currentService.eventId}</h4>}
                  </Row>
                  <Row className="mb-3 p-4">
                    <table border={1}>
                      <tr>
                        {currentService.type !== "NEW" ?
                        <>
                        <td className="font-weight-bold" style={{border:'1px solid black'}}>NOMBRE DEL SITIO</td>
                        <td style={{border:'1px solid black'}}>{currentService.place}</td>
                        </>
                        :
                        <>
                        <td className="font-weight-bold" style={{border:'1px solid black'}}>CONTACTO</td>
                        <td style={{border:'1px solid black'}}>{currentService.contact}</td>
                        </>}
                        <td style={{border:'1px solid black'}} className="font-weight-bold">FECHA</td>
                        <td style={{border:'1px solid black'}}>{currentService.createdAt.split('T')[0]} {currentService.createdAt.split('T')[1].slice(0,5)} HRS</td>
                      </tr>
                      <tr>
                      {currentService.type === "NEW" || currentService.type === "FIXED" ?
                        <>
                          <td className="font-weight-bold" style={{border:'1px solid black'}}>REALIZO</td>
                          <td style={{border:'1px solid black'}}>{currentService.owner !== null && currentService.owner !== undefined ? currentService.owner[0].name : ''}</td>
                          <td style={{border:'1px solid black'}} className="font-weight-bold">LÍNEA</td>
                          <td style={{border:'1px solid black'}}>{currentService.line}</td>
                        </>
                      : 
                        <>
                          <td className="font-weight-bold" style={{border:'1px solid black'}}>REALIZO</td>
                          <td style={{border:'1px solid black'}}>{currentService.owner !== null && currentService.owner !== undefined ? currentService.owner[0].name : ''}</td>
                          <td style={{border:'1px solid black'}} className="font-weight-bold">SUPERVISO</td>
                          <td style={{border:'1px solid black'}}>{currentService.supervisor !== null && currentService.supervisor !== undefined ? currentService.supervisor[0].name : ''}</td>
                        </>
                      }
                      </tr>
                      <tr>
                        <td className="font-weight-bold" style={{border:'1px solid black'}}>INICIO:</td>
                        <td style={{border:'1px solid black'}}>{currentService.startTime !== null && currentService.startTime !== undefined ? <>{currentService.startTime.split('T')[0]} {currentService.startTime.split('T')[1].slice(0,5)} HRS</> : 'N/A'}</td>
                        <td style={{border:'1px solid black'}} className="font-weight-bold">FIN:</td>
                        <td style={{border:'1px solid black'}}>{currentService.endTime !== null && currentService.endTime !== undefined ? <>{currentService.endTime.split('T')[0]} {currentService.endTime.split('T')[1].slice(0,5)} HRS</> : 'N/A'}</td>
                      </tr>
                      <tr>
                        <td className="font-weight-bold" style={{border:'1px solid black'}}>EQUIPO:</td>
                        <td style={{border:'1px solid black'}}>{currentService.device}</td>
                        <td style={{border:'1px solid black'}} className="font-weight-bold">MODELO:</td>
                        <td style={{border:'1px solid black'}}>{currentService.modelo}</td>
                      </tr>
                      {currentService.type === "NEW" || currentService.type === "FIXED" ?
                      <>
                        <tr>
                          <td className="font-weight-bold" style={{border:'1px solid black'}}>TAMAÑO:</td>
                          <td style={{border:'1px solid black'}} colSpan={3}>{currentService.size}</td>
                        </tr>
                        <tr>
                          <td className="font-weight-bold" style={{border:'1px solid black'}}>ORDEN DE COMPRA:</td>
                          <td style={{border:'1px solid black'}} colSpan={3}>{currentService.eventId}</td>
                        </tr>
                      </>
                      :
                       <>
                        <tr>
                          <td className="font-weight-bold" style={{border:'1px solid black'}}>MARCA:</td>
                          <td style={{border:'1px solid black'}} colSpan={3}>{currentService.brand}</td>
                        </tr>
                        <tr>
                          <td className="font-weight-bold" style={{border:'1px solid black'}}>DESCRIPCION DEL TRABAJO:</td>
                          <td style={{border:'1px solid black'}} colSpan={3}>{currentService.jobDescription}</td>
                        </tr>
                        <tr>
                          <td className="font-weight-bold" style={{border:'1px solid black'}}>OBJETIVO:</td>
                          <td style={{border:'1px solid black'}} colSpan={3}>{currentService.target}</td>
                        </tr>
                        <tr>
                          <td className="font-weight-bold" style={{border:'1px solid black'}}>ESTADO:</td>
                          <td style={{border:'1px solid black'}} colSpan={3}>{estado(currentService.status)}</td>
                        </tr>
                       </>
                      }
                    </table>
                  </Row>

                  <Row className="showPrint mb-3 p-4" style={{display: 'none'}}>
                    <div style={{height:420}}></div>
                    <Col md="6" xs="6" style={{border:'1px solid gray', borderRadius:10}} className="p-3">
                      <Row>
                        <Col md="12" xs="12">
                          <b>Recibí de conformidad y a plena satisfacción el servicio descrito</b>
                          <br/>
                          <br/>
                          <br/>
                          <br/>
                          <br/>
                          <b>{currentService.customer[0].name}</b>
                        </Col>
                      </Row>
                    </Col>
                    <Col md="6" xs="6" style={{border:'1px solid gray', borderRadius:10}} className="p-3">
                      <Row>
                        <Col md="12" xs="12">
                          <b>CIESA-Servicios</b>
                          <br/>
                          <br/>
                          <br/>
                          <br/>
                          <br/>
                          <br/>
                          <b>{currentService.type === "NEW" || currentService.type === "FIXED" ? "CIESA" : currentService.supervisor[0].name}</b>
                        </Col>
                      </Row>
                    </Col>
                  </Row>

                  {currentService.type === "NEW" || currentService.type === "FIXED" ? null :
                  <Row className="mb-3 p-4">
                    <h4><b>RESUMEN</b></h4>
                    <Col md="6" xs="6" style={{border:'1px solid gray', borderRadius:10}} className="p-3">
                      <Row>
                        <Col md="6" xs="6">
                          <b>Número de Reporte:</b>
                        </Col>
                        <Col md="6" xs="6">
                          {currentService.uuid.slice(0,8)}
                        </Col>
                      </Row>
                      <Row>
                        <Col md="6" xs="6">
                          <b>Fecha:</b>
                        </Col>
                        <Col md="6" xs="6">
                          {currentService.createdAt.split('T')[0]} {currentService.createdAt.split('T')[1].slice(0,5)} HRS
                        </Col>
                      </Row>
                      <Row>
                        <Col md="6" xs="6">
                          <b>Contacto:</b>
                        </Col>
                        <Col md="6" xs="6">
                          {currentService.contact}
                        </Col>
                      </Row>
                      <Row>
                        <Col md="6" xs="6">
                          <b>Línea:</b>
                        </Col>
                        <Col md="6" xs="6">
                          {currentService.line}
                        </Col>
                      </Row>
                    </Col>
                    <Col md="6" xs="6" style={{border:'1px solid gray', borderRadius:10}} className="p-3">
                      <Row>
                        <Col md="12" xs="12">
                          <b>Estado de los equipos al presentarse al sitio:</b>
                        </Col>
                        <Col md="12" xs="12">
                          {currentService.stateIn !== "" ? currentService.stateIn : 'N/A'}
                        </Col>
                        <Col md="12" xs="12">
                          <b>Estado de los equipos al dejar el sitio:</b>
                        </Col>
                        <Col md="12" xs="12">
                          {currentService.stateOut !== "" ? currentService.stateOut : 'N/A'}
                        </Col>
                      </Row>
                    </Col>
                    <Col md="12" className="mt-3" style={{border:'1px solid gray', borderRadius:10}}>
                      <Row>
                        <Col md="12" className="p-3">
                          <h6><b>Reporte detallado:</b></h6>
                          <div style={{marginTop:20}} dangerouslySetInnerHTML={{__html:currentService.report}}></div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>}

                  { currentService.type !== "NEW" ?
                    <>
                      <Row className="mb-3 p-4">
                        {currentService.assembly !== null && currentService.assembly !== undefined ?
                        <table>
                          <tr>
                            <td colSpan={2} style={{border:'2px solid black',fontWeight:'bold',backgroundColor:'#d4d4d4'}}>Armado original de la valvula (como se recibe en el centro de servicio)</td>
                            <td colSpan={1} style={{border:'2px solid black',fontWeight:'bold',backgroundColor:'#d4d4d4'}}>Cambios en la valvula a la hora del embarque</td>
                          </tr>
                          <tr>
                            <td colSpan={3} style={{border:'2px solid black',fontWeight: 'bold', backgroundColor:'#d4d4d4'}}>CUERPO</td>
                          </tr>
                          <tr>
                            <td style={{border:'2px solid black', textAlign:'start', paddingLeft:5, fontWeight: 'bold'}}>MARCA</td>
                            <td style={{border:'2px solid black', textAlign:'start', paddingLeft:5}}>{currentService.assembly.body.brand_value}</td>
                            <td style={{border:'2px solid black', textAlign:'start', paddingLeft:5}}>{currentService.assembly.body.brand_change}</td>
                          </tr>
                          <tr>
                            <td style={{border:'2px solid black', textAlign:'start', paddingLeft:5, fontWeight: 'bold'}}>NUMERO DE SERIE</td>
                            <td style={{border:'2px solid black', textAlign:'start', paddingLeft:5}}>{currentService.assembly.body.serie_value}</td>
                            <td style={{border:'2px solid black', textAlign:'start', paddingLeft:5}}>{currentService.assembly.body.serie_change}</td>
                          </tr>
                          <tr>
                            <td style={{border:'2px solid black', textAlign:'start', paddingLeft:5, fontWeight: 'bold'}}>MODELO</td>
                            <td style={{border:'2px solid black', textAlign:'start', paddingLeft:5}}>{currentService.assembly.body.model_value}</td>
                            <td style={{border:'2px solid black', textAlign:'start', paddingLeft:5}}>{currentService.assembly.body.model_change}</td>
                          </tr>
                          <tr>
                            <td style={{border:'2px solid black', textAlign:'start', paddingLeft:5, fontWeight: 'bold'}}>TAMAÑO</td>
                            <td style={{border:'2px solid black', textAlign:'start', paddingLeft:5}}>{currentService.assembly.body.size_value}</td>
                            <td style={{border:'2px solid black', textAlign:'start', paddingLeft:5}}>{currentService.assembly.body.size_change}</td>
                          </tr>
                          <tr>
                            <td style={{border:'2px solid black', textAlign:'start', paddingLeft:5, fontWeight: 'bold'}}>RATING</td>
                            <td style={{border:'2px solid black', textAlign:'start', paddingLeft:5}}>{currentService.assembly.body.rating_value}</td>
                            <td style={{border:'2px solid black', textAlign:'start', paddingLeft:5}}>{currentService.assembly.body.rating_change}</td>
                          </tr>
                          <tr>
                            <td style={{border:'2px solid black', textAlign:'start', paddingLeft:5, fontWeight: 'bold'}}>CONEXION A PROCESO</td>
                            <td style={{border:'2px solid black', textAlign:'start', paddingLeft:5}}>{currentService.assembly.body.connection_value}</td>
                            <td style={{border:'2px solid black', textAlign:'start', paddingLeft:5}}>{currentService.assembly.body.connection_change}</td>
                          </tr>
                          <tr>
                            <td style={{border:'2px solid black', textAlign:'start', paddingLeft:5, fontWeight: 'bold'}}>DIRECCION DE FLUJO</td>
                            <td style={{border:'2px solid black', textAlign:'start', paddingLeft:5}}>{currentService.assembly.body.direction_value}</td>
                            <td style={{border:'2px solid black', textAlign:'start', paddingLeft:5}}>{currentService.assembly.body.direction_change}</td>
                          </tr>
                          <tr>
                            <td style={{border:'2px solid black', textAlign:'start', paddingLeft:5, fontWeight: 'bold'}}>CARACTERISTICAS DE TRIM</td>
                            <td style={{border:'2px solid black', textAlign:'start', paddingLeft:5}}>{currentService.assembly.body.trim_value}</td>
                            <td style={{border:'2px solid black', textAlign:'start', paddingLeft:5}}>{currentService.assembly.body.trim_change}</td>
                          </tr>
                          <tr>
                            <td style={{border:'2px solid black', textAlign:'start', paddingLeft:5, fontWeight: 'bold'}}>TAMAÑO DEL PUERTO</td>
                            <td style={{border:'2px solid black', textAlign:'start', paddingLeft:5}}>{currentService.assembly.body.port_value}</td>
                            <td style={{border:'2px solid black', textAlign:'start', paddingLeft:5}}>{currentService.assembly.body.port_change}</td>
                          </tr>
                          <tr>
                            <td style={{border:'2px solid black', textAlign:'start', paddingLeft:5, fontWeight: 'bold'}}>CV</td>
                            <td style={{border:'2px solid black', textAlign:'start', paddingLeft:5}}>{currentService.assembly.body.cv_value}</td>
                            <td style={{border:'2px solid black', textAlign:'start', paddingLeft:5}}>{currentService.assembly.body.cv_change}</td>
                          </tr>
                          <tr>
                            <td style={{border:'2px solid black', textAlign:'start', paddingLeft:5, fontWeight: 'bold'}}>MATERIAL DEL EMPAQUE</td>
                            <td style={{border:'2px solid black', textAlign:'start', paddingLeft:5}}>{currentService.assembly.body.mpackage_value}</td>
                            <td style={{border:'2px solid black', textAlign:'start', paddingLeft:5}}>{currentService.assembly.body.mpackage_change}</td>
                          </tr>
                          <tr>
                            <td style={{border:'2px solid black', textAlign:'start', paddingLeft:5, fontWeight: 'bold'}}>MATERIAL DE LAS JUNTAS</td>
                            <td style={{border:'2px solid black', textAlign:'start', paddingLeft:5}}>{currentService.assembly.body.mjuntas_value}</td>
                            <td style={{border:'2px solid black', textAlign:'start', paddingLeft:5}}>{currentService.assembly.body.mjuntas_change}</td>
                          </tr>
                          <tr>
                            <td style={{border:'2px solid black', textAlign:'start', paddingLeft:5, fontWeight: 'bold'}}>MATERIAL DEL CUERPO</td>
                            <td style={{border:'2px solid black', textAlign:'start', paddingLeft:5}}>{currentService.assembly.body.mbody_value}</td>
                            <td style={{border:'2px solid black', textAlign:'start', paddingLeft:5}}>{currentService.assembly.body.mbody_change}</td>
                          </tr>
                          <tr>
                            <td style={{border:'2px solid black', textAlign:'start', paddingLeft:5, fontWeight: 'bold'}}>MATERIAL DEL ASIENTO</td>
                            <td style={{border:'2px solid black', textAlign:'start', paddingLeft:5}}>{currentService.assembly.body.mseat_value}</td>
                            <td style={{border:'2px solid black', textAlign:'start', paddingLeft:5}}>{currentService.assembly.body.mseat_change}</td>
                          </tr>
                          <tr>
                            <td style={{border:'2px solid black', textAlign:'start', paddingLeft:5, fontWeight: 'bold'}}>MATERIAL DE LA CAJA</td>
                            <td style={{border:'2px solid black', textAlign:'start', paddingLeft:5}}>{currentService.assembly.body.mbox_value}</td>
                            <td style={{border:'2px solid black', textAlign:'start', paddingLeft:5}}>{currentService.assembly.body.mbox_change}</td>
                          </tr>
                          <tr>
                            <td style={{border:'2px solid black', textAlign:'start', paddingLeft:5, fontWeight: 'bold'}}>MATERIAL DE LA GUIA</td>
                            <td style={{border:'2px solid black', textAlign:'start', paddingLeft:5}}>{currentService.assembly.body.mguide_value}</td>
                            <td style={{border:'2px solid black', textAlign:'start', paddingLeft:5}}>{currentService.assembly.body.mguide_change}</td>
                          </tr>
                          <tr>
                            <td style={{border:'2px solid black', textAlign:'start', paddingLeft:5, fontWeight: 'bold'}}>MATERIAL DEL TAPON</td>
                            <td style={{border:'2px solid black', textAlign:'start', paddingLeft:5}}>{currentService.assembly.body.mtap_value}</td>
                            <td style={{border:'2px solid black', textAlign:'start', paddingLeft:5}}>{currentService.assembly.body.mtap_change}</td>
                          </tr>
                          <tr>
                            <td style={{border:'2px solid black', textAlign:'start', paddingLeft:5, fontWeight: 'bold'}}>MATERIAL DEL VASTAGO</td>
                            <td style={{border:'2px solid black', textAlign:'start', paddingLeft:5}}>{currentService.assembly.body.mvas_value}</td>
                            <td style={{border:'2px solid black', textAlign:'start', paddingLeft:5}}>{currentService.assembly.body.mvas_change}</td>
                          </tr>
                          <tr>
                            <td colSpan={3} style={{border:'2px solid black',fontWeight: 'bold', backgroundColor:'#d4d4d4'}}>ACTUADOR</td>
                          </tr>
                          <tr>
                            <td style={{border:'2px solid black', textAlign:'start', paddingLeft:5, fontWeight: 'bold'}}>MARCA</td>
                            <td style={{border:'2px solid black', textAlign:'start', paddingLeft:5}}>{currentService.assembly.actor.brand_value}</td>
                            <td style={{border:'2px solid black', textAlign:'start', paddingLeft:5}}>{currentService.assembly.actor.brand_change}</td>
                          </tr>
                          <tr>
                            <td style={{border:'2px solid black', textAlign:'start', paddingLeft:5, fontWeight: 'bold'}}>NUMERO DE SERIE</td>
                            <td style={{border:'2px solid black', textAlign:'start', paddingLeft:5}}>{currentService.assembly.actor.serie_value}</td>
                            <td style={{border:'2px solid black', textAlign:'start', paddingLeft:5}}>{currentService.assembly.actor.serie_change}</td>
                          </tr>
                          <tr>
                            <td style={{border:'2px solid black', textAlign:'start', paddingLeft:5, fontWeight: 'bold'}}>MODELO</td>
                            <td style={{border:'2px solid black', textAlign:'start', paddingLeft:5}}>{currentService.assembly.actor.model_value}</td>
                            <td style={{border:'2px solid black', textAlign:'start', paddingLeft:5}}>{currentService.assembly.actor.model_change}</td>
                          </tr>
                          <tr>
                            <td style={{border:'2px solid black', textAlign:'start', paddingLeft:5, fontWeight: 'bold'}}>TAMAÑO</td>
                            <td style={{border:'2px solid black', textAlign:'start', paddingLeft:5}}>{currentService.assembly.actor.size_value}</td>
                            <td style={{border:'2px solid black', textAlign:'start', paddingLeft:5}}>{currentService.assembly.actor.size_change}</td>
                          </tr>
                          <tr>
                            <td style={{border:'2px solid black', textAlign:'start', paddingLeft:5, fontWeight: 'bold'}}>ACCION A FALLA</td>
                            <td style={{border:'2px solid black', textAlign:'start', paddingLeft:5}}>{currentService.assembly.actor.action_value}</td>
                            <td style={{border:'2px solid black', textAlign:'start', paddingLeft:5}}>{currentService.assembly.actor.action_change}</td>
                          </tr>
                          <tr>
                            <td style={{border:'2px solid black', textAlign:'start', paddingLeft:5, fontWeight: 'bold'}}>CARRERA</td>
                            <td style={{border:'2px solid black', textAlign:'start', paddingLeft:5}}>{currentService.assembly.actor.race_value}</td>
                            <td style={{border:'2px solid black', textAlign:'start', paddingLeft:5}}>{currentService.assembly.actor.race_change}</td>
                          </tr>
                          <tr>
                            <td style={{border:'2px solid black', textAlign:'start', paddingLeft:5, fontWeight: 'bold'}}>AJUSTE DE BANCO</td>
                            <td style={{border:'2px solid black', textAlign:'start', paddingLeft:5}}>{currentService.assembly.actor.adjust_value}</td>
                            <td style={{border:'2px solid black', textAlign:'start', paddingLeft:5}}>{currentService.assembly.actor.adjust_change}</td>
                          </tr>
                          <tr>
                            <td style={{border:'2px solid black', textAlign:'start', paddingLeft:5, fontWeight: 'bold'}}>VOLANTE</td>
                            <td style={{border:'2px solid black', textAlign:'start', paddingLeft:5}}>{currentService.assembly.actor.vol_value}</td>
                            <td style={{border:'2px solid black', textAlign:'start', paddingLeft:5}}>{currentService.assembly.actor.vol_change}</td>
                          </tr>
                          <tr>
                            <td style={{border:'2px solid black', textAlign:'start', paddingLeft:5, fontWeight: 'bold'}}>SEÑAL DE CONTROL</td>
                            <td style={{border:'2px solid black', textAlign:'start', paddingLeft:5}}>{currentService.assembly.actor.signal_value}</td>
                            <td style={{border:'2px solid black', textAlign:'start', paddingLeft:5}}>{currentService.assembly.actor.signal_change}</td>
                          </tr>
                          <tr>
                            <td colSpan={3} style={{border:'2px solid black',fontWeight: 'bold', backgroundColor:'#d4d4d4'}}>POSICIONADOR</td>
                          </tr>
                          <tr>
                            <td style={{border:'2px solid black', textAlign:'start', paddingLeft:5, fontWeight: 'bold'}}>MARCA</td>
                            <td style={{border:'2px solid black', textAlign:'start', paddingLeft:5}}>{currentService.assembly.positionator.brand_value}</td>
                            <td style={{border:'2px solid black', textAlign:'start', paddingLeft:5}}>{currentService.assembly.positionator.brand_change}</td>
                          </tr>
                          <tr>
                            <td style={{border:'2px solid black', textAlign:'start', paddingLeft:5, fontWeight: 'bold'}}>MODELO</td>
                            <td style={{border:'2px solid black', textAlign:'start', paddingLeft:5}}>{currentService.assembly.positionator.model_value}</td>
                            <td style={{border:'2px solid black', textAlign:'start', paddingLeft:5}}>{currentService.assembly.positionator.model_change}</td>
                          </tr>
                          <tr>
                            <td style={{border:'2px solid black', textAlign:'start', paddingLeft:5, fontWeight: 'bold'}}>NUMERO DE SERIE</td>
                            <td style={{border:'2px solid black', textAlign:'start', paddingLeft:5}}>{currentService.assembly.positionator.serie_value}</td>
                            <td style={{border:'2px solid black', textAlign:'start', paddingLeft:5}}>{currentService.assembly.positionator.serie_change}</td>
                          </tr>
                          <tr>
                            <td style={{border:'2px solid black', textAlign:'start', paddingLeft:5, fontWeight: 'bold'}}>SEÑAL DE ENTRADA</td>
                            <td style={{border:'2px solid black', textAlign:'start', paddingLeft:5}}>{currentService.assembly.positionator.signal_value}</td>
                            <td style={{border:'2px solid black', textAlign:'start', paddingLeft:5}}>{currentService.assembly.positionator.signal_change}</td>
                          </tr>
                          <tr>
                            <td style={{border:'2px solid black', textAlign:'start', paddingLeft:5, fontWeight: 'bold'}}>ACCION</td>
                            <td style={{border:'2px solid black', textAlign:'start', paddingLeft:5}}>{currentService.assembly.positionator.action_value}</td>
                            <td style={{border:'2px solid black', textAlign:'start', paddingLeft:5}}>{currentService.assembly.positionator.action_change}</td>
                          </tr>
                          <tr>
                            <td style={{border:'2px solid black', textAlign:'start', paddingLeft:5, fontWeight: 'bold'}}>CARACTERIZACION</td>
                            <td style={{border:'2px solid black', textAlign:'start', paddingLeft:5}}>{currentService.assembly.positionator.char_value}</td>
                            <td style={{border:'2px solid black', textAlign:'start', paddingLeft:5}}>{currentService.assembly.positionator.char_change}</td>
                          </tr>
                          <tr>
                            <td style={{border:'2px solid black', textAlign:'start', paddingLeft:5, fontWeight: 'bold'}}>BY PASS</td>
                            <td style={{border:'2px solid black', textAlign:'start', paddingLeft:5}}>{currentService.assembly.positionator.bypass_value}</td>
                            <td style={{border:'2px solid black', textAlign:'start', paddingLeft:5}}>{currentService.assembly.positionator.bypass_change}</td>
                          </tr>
                          <tr>
                            <td colSpan={3} style={{border:'2px solid black',fontWeight: 'bold', backgroundColor:'#d4d4d4'}}>OTROS ACCESORIOS INSTALADOS</td>
                          </tr>
                          <tr>
                            <td style={{border:'2px solid black', textAlign:'start', paddingLeft:5, fontWeight: 'bold'}}>TRANSDUCTOR I/P</td>
                            <td style={{border:'2px solid black', textAlign:'start', paddingLeft:5}}>{currentService.assembly.otherAccesories.transductor_value}</td>
                            <td style={{border:'2px solid black', textAlign:'start', paddingLeft:5}}>{currentService.assembly.otherAccesories.transductor_change}</td>
                          </tr>
                          <tr>
                            <td style={{border:'2px solid black', textAlign:'start', paddingLeft:5, fontWeight: 'bold'}}>SEÑAL DE ENTRADA</td>
                            <td style={{border:'2px solid black', textAlign:'start', paddingLeft:5}}>{currentService.assembly.otherAccesories.signalIn_value}</td>
                            <td style={{border:'2px solid black', textAlign:'start', paddingLeft:5}}>{currentService.assembly.otherAccesories.signalIn_change}</td>
                          </tr>
                          <tr>
                            <td style={{border:'2px solid black', textAlign:'start', paddingLeft:5, fontWeight: 'bold'}}>SEÑAL DE SALIDA</td>
                            <td style={{border:'2px solid black', textAlign:'start', paddingLeft:5}}>{currentService.assembly.otherAccesories.signalOut_value}</td>
                            <td style={{border:'2px solid black', textAlign:'start', paddingLeft:5}}>{currentService.assembly.otherAccesories.signalOut_change}</td>
                          </tr>
                          <tr>
                            <td style={{border:'2px solid black', textAlign:'start', paddingLeft:5, fontWeight: 'bold'}}>SOLENOIDE</td>
                            <td style={{border:'2px solid black', textAlign:'start', paddingLeft:5}}>{currentService.assembly.otherAccesories.solenoide_value}</td>
                            <td style={{border:'2px solid black', textAlign:'start', paddingLeft:5}}>{currentService.assembly.otherAccesories.solenoide_change}</td>
                          </tr>
                          <tr>
                            <td style={{border:'2px solid black', textAlign:'start', paddingLeft:5, fontWeight: 'bold'}}>ACCION</td>
                            <td style={{border:'2px solid black', textAlign:'start', paddingLeft:5}}>{currentService.assembly.otherAccesories.action_value}</td>
                            <td style={{border:'2px solid black', textAlign:'start', paddingLeft:5}}>{currentService.assembly.otherAccesories.action_change}</td>
                          </tr>
                          <tr>
                            <td style={{border:'2px solid black', textAlign:'start', paddingLeft:5, fontWeight: 'bold'}}>VALVULAS DE LOCK UP</td>
                            <td style={{border:'2px solid black', textAlign:'start', paddingLeft:5}}>{currentService.assembly.otherAccesories.valvs_value}</td>
                            <td style={{border:'2px solid black', textAlign:'start', paddingLeft:5}}>{currentService.assembly.otherAccesories.valvs_change}</td>
                          </tr>
                          <tr>
                            <td style={{border:'2px solid black', textAlign:'start', paddingLeft:5, fontWeight: 'bold'}}>TRIP VALVE</td>
                            <td style={{border:'2px solid black', textAlign:'start', paddingLeft:5}}>{currentService.assembly.otherAccesories.trip_value}</td>
                            <td style={{border:'2px solid black', textAlign:'start', paddingLeft:5}}>{currentService.assembly.otherAccesories.trip_change}</td>
                          </tr>
                          <tr>
                            <td style={{border:'2px solid black', textAlign:'start', paddingLeft:5, fontWeight: 'bold'}}>FILTRO REGULADOR</td>
                            <td style={{border:'2px solid black', textAlign:'start', paddingLeft:5}}>{currentService.assembly.otherAccesories.filter_value}</td>
                            <td style={{border:'2px solid black', textAlign:'start', paddingLeft:5}}>{currentService.assembly.otherAccesories.filter_change}</td>
                          </tr>
                          <tr>
                            <td style={{border:'2px solid black', textAlign:'start', paddingLeft:5, fontWeight: 'bold'}}>SWITCH DE POSICION</td>
                            <td style={{border:'2px solid black', textAlign:'start', paddingLeft:5}}>{currentService.assembly.otherAccesories.switch_value}</td>
                            <td style={{border:'2px solid black', textAlign:'start', paddingLeft:5}}>{currentService.assembly.otherAccesories.switch_change}</td>
                          </tr>
                          <tr>
                            <td style={{border:'2px solid black', textAlign:'start', paddingLeft:5, fontWeight: 'bold'}}>CONSTRUCCION ESPECIAL Y COMENTARIOS</td>
                            <td style={{border:'2px solid black', textAlign:'start', paddingLeft:5}}>{currentService.assembly.otherAccesories.special_value}</td>
                            <td style={{border:'2px solid black', textAlign:'start', paddingLeft:5}}>{currentService.assembly.otherAccesories.special_change}</td>
                          </tr>
                        </table> : null}
                      </Row>

                      <Row className="mb-3 p-4">
                        {currentService.fixes !== null && currentService.fixes !== undefined ?
                        <table>
                          <tr>
                            <td colSpan={2} style={{border:'2px solid black',fontWeight:'bold',backgroundColor:'#d4d4d4'}}>REPARACIONES REQUERIDAS</td>
                          </tr>
                          <tr>
                            <td colSpan={2} style={{border:'2px solid black',fontWeight: 'bold', backgroundColor:'#d4d4d4'}}>CONDICIONES DEL CUERPO Y REPARACIONES REALIZADAS</td>
                          </tr>
                          <tr style={{border:'2px solid black'}}>
                            <td>
                              <table style={{width:'100%'}} border={1}>
                                <tr>
                                  <td colSpan={1} style={{border:'1px solid black'}}></td>
                                  <td colSpan={1} style={{border:'1px solid black', writingMode: 'vertical-rl', textOrientation:'upright', width:30, textAlign:'end', fontSize:11}}>OK</td>
                                  <td colSpan={1} style={{border:'1px solid black', writingMode: 'vertical-rl', textOrientation:'upright', width:30, textAlign:'end', fontSize:11}}>DESGASTADO</td>
                                  <td colSpan={1} style={{border:'1px solid black', writingMode: 'vertical-rl', textOrientation:'upright', width:30, textAlign:'end', fontSize:11}}>LAVADO</td>
                                  <td colSpan={1} style={{border:'1px solid black', writingMode: 'vertical-rl', textOrientation:'upright', width:30, textAlign:'end', fontSize:11}}>PICADO</td>
                                  <td colSpan={1} style={{border:'1px solid black', writingMode: 'vertical-rl', textOrientation:'upright', width:30, textAlign:'end', fontSize:11}}>RALLADO</td>
                                  <td colSpan={1} style={{border:'1px solid black', writingMode: 'vertical-rl', textOrientation:'upright', width:30, textAlign:'end', fontSize:11}}>SANDBLASTEADO</td>
                                  <td colSpan={1} style={{border:'1px solid black', writingMode: 'vertical-rl', textOrientation:'upright', width:30, textAlign:'end', fontSize:11}}>PULIDO</td>
                                  <td colSpan={1} style={{border:'1px solid black', writingMode: 'vertical-rl', textOrientation:'upright', width:30, textAlign:'end', fontSize:11}}>SOLDADO</td>
                                  <td colSpan={1} style={{border:'1px solid black', writingMode: 'vertical-rl', textOrientation:'upright', width:30, textAlign:'end', fontSize:11}}>MAQUINADO</td>
                                  <td colSpan={1} style={{border:'1px solid black', writingMode: 'vertical-rl', textOrientation:'upright', width:30, textAlign:'end', fontSize:11}}>REMPLAZADO</td>
                                  <td colSpan={1} style={{border:'1px solid black', writingMode: 'vertical-rl', textOrientation:'upright', width:30, textAlign:'end', fontSize:11}}>PINTURA</td>
                                </tr>
                                <tr>
                                  <td colSpan={1} style={{border:'1px solid black', textAlign:'start', paddingLeft:5}}>BRIDAS</td>
                                  <td colSpan={1} style={{border:'1px solid black',width:30}}>{currentService.fixes.body.brida.ok ? <i className="fa fa-stop"></i> : '' }</td>
                                  <td colSpan={1} style={{border:'1px solid black',width:30}}>{currentService.fixes.body.brida.des ? <i className="fa fa-stop"></i> : '' }</td>
                                  <td colSpan={1} style={{border:'1px solid black',width:30}}>{currentService.fixes.body.brida.lav ? <i className="fa fa-stop"></i> : '' }</td>
                                  <td colSpan={1} style={{border:'1px solid black',width:30}}>{currentService.fixes.body.brida.pic ? <i className="fa fa-stop"></i> : '' }</td>
                                  <td colSpan={1} style={{border:'1px solid black',width:30}}>{currentService.fixes.body.brida.ral ? <i className="fa fa-stop"></i> : '' }</td>
                                  <td colSpan={1} style={{border:'1px solid black',width:30}}>{currentService.fixes.body.brida.san ? <i className="fa fa-stop"></i> : '' }</td>
                                  <td colSpan={1} style={{border:'1px solid black',width:30}}>{currentService.fixes.body.brida.pul ? <i className="fa fa-stop"></i> : '' }</td>
                                  <td colSpan={1} style={{border:'1px solid black',width:30}}>{currentService.fixes.body.brida.sol ? <i className="fa fa-stop"></i> : '' }</td>
                                  <td colSpan={1} style={{border:'1px solid black',width:30}}>{currentService.fixes.body.brida.maq ? <i className="fa fa-stop"></i> : '' }</td>
                                  <td colSpan={1} style={{border:'1px solid black',width:30}}>{currentService.fixes.body.brida.rem ? <i className="fa fa-stop"></i> : '' }</td>
                                  <td colSpan={1} style={{border:'1px solid black',width:30}}>{currentService.fixes.body.brida.pin ? <i className="fa fa-stop"></i> : '' }</td>
                                </tr>
                                <tr>
                                  <td colSpan={1} style={{border:'1px solid black', textAlign:'start', paddingLeft:5}}>SUPERFICIE DE LAS JUNTAS</td>
                                  <td colSpan={1} style={{border:'1px solid black',width:30}}>{currentService.fixes.body.superficie.ok ? <i className="fa fa-stop"></i> : '' }</td>
                                  <td colSpan={1} style={{border:'1px solid black',width:30}}>{currentService.fixes.body.superficie.des ? <i className="fa fa-stop"></i> : '' }</td>
                                  <td colSpan={1} style={{border:'1px solid black',width:30}}>{currentService.fixes.body.superficie.lav ? <i className="fa fa-stop"></i> : '' }</td>
                                  <td colSpan={1} style={{border:'1px solid black',width:30}}>{currentService.fixes.body.superficie.pic ? <i className="fa fa-stop"></i> : '' }</td>
                                  <td colSpan={1} style={{border:'1px solid black',width:30}}>{currentService.fixes.body.superficie.ral ? <i className="fa fa-stop"></i> : '' }</td>
                                  <td colSpan={1} style={{border:'1px solid black',width:30}}>{currentService.fixes.body.superficie.san ? <i className="fa fa-stop"></i> : '' }</td>
                                  <td colSpan={1} style={{border:'1px solid black',width:30}}>{currentService.fixes.body.superficie.pul ? <i className="fa fa-stop"></i> : '' }</td>
                                  <td colSpan={1} style={{border:'1px solid black',width:30}}>{currentService.fixes.body.superficie.sol ? <i className="fa fa-stop"></i> : '' }</td>
                                  <td colSpan={1} style={{border:'1px solid black',width:30}}>{currentService.fixes.body.superficie.maq ? <i className="fa fa-stop"></i> : '' }</td>
                                  <td colSpan={1} style={{border:'1px solid black',width:30}}>{currentService.fixes.body.superficie.rem ? <i className="fa fa-stop"></i> : '' }</td>
                                  <td colSpan={1} style={{border:'1px solid black',width:30}}>{currentService.fixes.body.superficie.pin ? <i className="fa fa-stop"></i> : '' }</td>
                                </tr>
                                <tr>
                                  <td colSpan={1} style={{border:'1px solid black', textAlign:'start', paddingLeft:5}}>BRIDA INFERIOR</td>
                                  <td colSpan={1} style={{border:'1px solid black',width:30}}>{currentService.fixes.body.brida_inf.ok ? <i className="fa fa-stop"></i> : '' }</td>
                                  <td colSpan={1} style={{border:'1px solid black',width:30}}>{currentService.fixes.body.brida_inf.des ? <i className="fa fa-stop"></i> : '' }</td>
                                  <td colSpan={1} style={{border:'1px solid black',width:30}}>{currentService.fixes.body.brida_inf.lav ? <i className="fa fa-stop"></i> : '' }</td>
                                  <td colSpan={1} style={{border:'1px solid black',width:30}}>{currentService.fixes.body.brida_inf.pic ? <i className="fa fa-stop"></i> : '' }</td>
                                  <td colSpan={1} style={{border:'1px solid black',width:30}}>{currentService.fixes.body.brida_inf.ral ? <i className="fa fa-stop"></i> : '' }</td>
                                  <td colSpan={1} style={{border:'1px solid black',width:30}}>{currentService.fixes.body.brida_inf.san ? <i className="fa fa-stop"></i> : '' }</td>
                                  <td colSpan={1} style={{border:'1px solid black',width:30}}>{currentService.fixes.body.brida_inf.pul ? <i className="fa fa-stop"></i> : '' }</td>
                                  <td colSpan={1} style={{border:'1px solid black',width:30}}>{currentService.fixes.body.brida_inf.sol ? <i className="fa fa-stop"></i> : '' }</td>
                                  <td colSpan={1} style={{border:'1px solid black',width:30}}>{currentService.fixes.body.brida_inf.maq ? <i className="fa fa-stop"></i> : '' }</td>
                                  <td colSpan={1} style={{border:'1px solid black',width:30}}>{currentService.fixes.body.brida_inf.rem ? <i className="fa fa-stop"></i> : '' }</td>
                                  <td colSpan={1} style={{border:'1px solid black',width:30}}>{currentService.fixes.body.brida_inf.pin ? <i className="fa fa-stop"></i> : '' }</td>
                                </tr>
                                <tr>
                                  <td colSpan={1} style={{border:'1px solid black', textAlign:'start', paddingLeft:5}}>BONETE</td>
                                  <td colSpan={1} style={{border:'1px solid black',width:30}}>{currentService.fixes.body.bonete.ok ? <i className="fa fa-stop"></i> : '' }</td>
                                  <td colSpan={1} style={{border:'1px solid black',width:30}}>{currentService.fixes.body.bonete.des ? <i className="fa fa-stop"></i> : '' }</td>
                                  <td colSpan={1} style={{border:'1px solid black',width:30}}>{currentService.fixes.body.bonete.lav ? <i className="fa fa-stop"></i> : '' }</td>
                                  <td colSpan={1} style={{border:'1px solid black',width:30}}>{currentService.fixes.body.bonete.pic ? <i className="fa fa-stop"></i> : '' }</td>
                                  <td colSpan={1} style={{border:'1px solid black',width:30}}>{currentService.fixes.body.bonete.ral ? <i className="fa fa-stop"></i> : '' }</td>
                                  <td colSpan={1} style={{border:'1px solid black',width:30}}>{currentService.fixes.body.bonete.san ? <i className="fa fa-stop"></i> : '' }</td>
                                  <td colSpan={1} style={{border:'1px solid black',width:30}}>{currentService.fixes.body.bonete.pul ? <i className="fa fa-stop"></i> : '' }</td>
                                  <td colSpan={1} style={{border:'1px solid black',width:30}}>{currentService.fixes.body.bonete.sol ? <i className="fa fa-stop"></i> : '' }</td>
                                  <td colSpan={1} style={{border:'1px solid black',width:30}}>{currentService.fixes.body.bonete.maq ? <i className="fa fa-stop"></i> : '' }</td>
                                  <td colSpan={1} style={{border:'1px solid black',width:30}}>{currentService.fixes.body.bonete.rem ? <i className="fa fa-stop"></i> : '' }</td>
                                  <td colSpan={1} style={{border:'1px solid black',width:30}}>{currentService.fixes.body.bonete.pin ? <i className="fa fa-stop"></i> : '' }</td>
                                </tr>
                                <tr>
                                  <td colSpan={1} style={{border:'1px solid black', textAlign:'start', paddingLeft:5}}>CAJA</td>
                                  <td colSpan={1} style={{border:'1px solid black',width:30}}>{currentService.fixes.body.caja.ok ? <i className="fa fa-stop"></i> : '' }</td>
                                  <td colSpan={1} style={{border:'1px solid black',width:30}}>{currentService.fixes.body.caja.des ? <i className="fa fa-stop"></i> : '' }</td>
                                  <td colSpan={1} style={{border:'1px solid black',width:30}}>{currentService.fixes.body.caja.lav ? <i className="fa fa-stop"></i> : '' }</td>
                                  <td colSpan={1} style={{border:'1px solid black',width:30}}>{currentService.fixes.body.caja.pic ? <i className="fa fa-stop"></i> : '' }</td>
                                  <td colSpan={1} style={{border:'1px solid black',width:30}}>{currentService.fixes.body.caja.ral ? <i className="fa fa-stop"></i> : '' }</td>
                                  <td colSpan={1} style={{border:'1px solid black',width:30}}>{currentService.fixes.body.caja.san ? <i className="fa fa-stop"></i> : '' }</td>
                                  <td colSpan={1} style={{border:'1px solid black',width:30}}>{currentService.fixes.body.caja.pul ? <i className="fa fa-stop"></i> : '' }</td>
                                  <td colSpan={1} style={{border:'1px solid black',width:30}}>{currentService.fixes.body.caja.sol ? <i className="fa fa-stop"></i> : '' }</td>
                                  <td colSpan={1} style={{border:'1px solid black',width:30}}>{currentService.fixes.body.caja.maq ? <i className="fa fa-stop"></i> : '' }</td>
                                  <td colSpan={1} style={{border:'1px solid black',width:30}}>{currentService.fixes.body.caja.rem ? <i className="fa fa-stop"></i> : '' }</td>
                                  <td colSpan={1} style={{border:'1px solid black',width:30}}>{currentService.fixes.body.caja.pin ? <i className="fa fa-stop"></i> : '' }</td>
                                </tr>
                              </table>
                            </td>
                            <td style={{textAlign:'center', paddingLeft:10, maxWidth:200, minWidth:200}}>NOTAS Y COMENTARIOS:<br/> {currentService.fixes.body.notes}</td>
                          </tr>
                          <tr>
                            <td colSpan={2} style={{border:'2px solid black',fontWeight: 'bold', backgroundColor:'#d4d4d4'}}>CONDICIONES DEL TRIM Y REPARACIONES REALIZADAS</td>
                          </tr>
                          <tr style={{border:'2px solid black'}}>
                            <td>
                              <table style={{width:'100%'}} border={1}>
                                <tr>
                                  <td colSpan={1} style={{border:'1px solid black'}}></td>
                                  <td colSpan={1} style={{border:'1px solid black', writingMode: 'vertical-rl', textOrientation:'upright', width:30, textAlign:'end', fontSize:11}}>OK</td>
                                  <td colSpan={1} style={{border:'1px solid black', writingMode: 'vertical-rl', textOrientation:'upright', width:30, textAlign:'end', fontSize:11}}>DESGASTADO</td>
                                  <td colSpan={1} style={{border:'1px solid black', writingMode: 'vertical-rl', textOrientation:'upright', width:30, textAlign:'end', fontSize:11}}>LAVADO</td>
                                  <td colSpan={1} style={{border:'1px solid black', writingMode: 'vertical-rl', textOrientation:'upright', width:30, textAlign:'end', fontSize:11}}>PICADO</td>
                                  <td colSpan={1} style={{border:'1px solid black', writingMode: 'vertical-rl', textOrientation:'upright', width:30, textAlign:'end', fontSize:11}}>RALLADO</td>
                                  <td colSpan={1} style={{border:'1px solid black', writingMode: 'vertical-rl', textOrientation:'upright', width:30, textAlign:'end', fontSize:11}}>SANDBLASTEADO</td>
                                  <td colSpan={1} style={{border:'1px solid black', writingMode: 'vertical-rl', textOrientation:'upright', width:30, textAlign:'end', fontSize:11}}>PULIDO</td>
                                  <td colSpan={1} style={{border:'1px solid black', writingMode: 'vertical-rl', textOrientation:'upright', width:30, textAlign:'end', fontSize:11}}>SOLDADO</td>
                                  <td colSpan={1} style={{border:'1px solid black', writingMode: 'vertical-rl', textOrientation:'upright', width:30, textAlign:'end', fontSize:11}}>MAQUINADO</td>
                                  <td colSpan={1} style={{border:'1px solid black', writingMode: 'vertical-rl', textOrientation:'upright', width:30, textAlign:'end', fontSize:11}}>REMPLAZADO</td>
                                  <td colSpan={1} style={{border:'1px solid black', writingMode: 'vertical-rl', textOrientation:'upright', width:30, textAlign:'end', fontSize:11}}></td>
                                </tr>
                                <tr>
                                  <td colSpan={1} style={{border:'1px solid black', textAlign:'start', paddingLeft:5}}>TAPON/BOLA/DISCO</td>
                                  <td colSpan={1} style={{border:'1px solid black',width:30}}>{currentService.fixes.trim.tbd.ok ? <i className="fa fa-stop"></i> : '' }</td>
                                  <td colSpan={1} style={{border:'1px solid black',width:30}}>{currentService.fixes.trim.tbd.des ? <i className="fa fa-stop"></i> : '' }</td>
                                  <td colSpan={1} style={{border:'1px solid black',width:30}}>{currentService.fixes.trim.tbd.lav ? <i className="fa fa-stop"></i> : '' }</td>
                                  <td colSpan={1} style={{border:'1px solid black',width:30}}>{currentService.fixes.trim.tbd.pic ? <i className="fa fa-stop"></i> : '' }</td>
                                  <td colSpan={1} style={{border:'1px solid black',width:30}}>{currentService.fixes.trim.tbd.ral ? <i className="fa fa-stop"></i> : '' }</td>
                                  <td colSpan={1} style={{border:'1px solid black',width:30}}>{currentService.fixes.trim.tbd.san ? <i className="fa fa-stop"></i> : '' }</td>
                                  <td colSpan={1} style={{border:'1px solid black',width:30}}>{currentService.fixes.trim.tbd.pul ? <i className="fa fa-stop"></i> : '' }</td>
                                  <td colSpan={1} style={{border:'1px solid black',width:30}}>{currentService.fixes.trim.tbd.sol ? <i className="fa fa-stop"></i> : '' }</td>
                                  <td colSpan={1} style={{border:'1px solid black',width:30}}>{currentService.fixes.trim.tbd.maq ? <i className="fa fa-stop"></i> : '' }</td>
                                  <td colSpan={1} style={{border:'1px solid black',width:30}}>{currentService.fixes.trim.tbd.rem ? <i className="fa fa-stop"></i> : '' }</td>
                                  <td colSpan={1} style={{border:'1px solid black',width:30}}></td>
                                </tr>
                                <tr>
                                  <td colSpan={1} style={{border:'1px solid black', textAlign:'start', paddingLeft:5}}>CAJA/GUIA/RETENEDOR</td>
                                  <td colSpan={1} style={{border:'1px solid black',width:30}}>{currentService.fixes.trim.cgr.ok ? <i className="fa fa-stop"></i> : '' }</td>
                                  <td colSpan={1} style={{border:'1px solid black',width:30}}>{currentService.fixes.trim.cgr.des ? <i className="fa fa-stop"></i> : '' }</td>
                                  <td colSpan={1} style={{border:'1px solid black',width:30}}>{currentService.fixes.trim.cgr.lav ? <i className="fa fa-stop"></i> : '' }</td>
                                  <td colSpan={1} style={{border:'1px solid black',width:30}}>{currentService.fixes.trim.cgr.pic ? <i className="fa fa-stop"></i> : '' }</td>
                                  <td colSpan={1} style={{border:'1px solid black',width:30}}>{currentService.fixes.trim.cgr.ral ? <i className="fa fa-stop"></i> : '' }</td>
                                  <td colSpan={1} style={{border:'1px solid black',width:30}}>{currentService.fixes.trim.cgr.san ? <i className="fa fa-stop"></i> : '' }</td>
                                  <td colSpan={1} style={{border:'1px solid black',width:30}}>{currentService.fixes.trim.cgr.pul ? <i className="fa fa-stop"></i> : '' }</td>
                                  <td colSpan={1} style={{border:'1px solid black',width:30}}>{currentService.fixes.trim.cgr.sol ? <i className="fa fa-stop"></i> : '' }</td>
                                  <td colSpan={1} style={{border:'1px solid black',width:30}}>{currentService.fixes.trim.cgr.maq ? <i className="fa fa-stop"></i> : '' }</td>
                                  <td colSpan={1} style={{border:'1px solid black',width:30}}>{currentService.fixes.trim.cgr.rem ? <i className="fa fa-stop"></i> : '' }</td>
                                  <td colSpan={1} style={{border:'1px solid black',width:30}}></td>
                                </tr>
                                <tr>
                                  <td colSpan={1} style={{border:'1px solid black', textAlign:'start', paddingLeft:5}}>ANILLO DE ASIENTO</td>
                                  <td colSpan={1} style={{border:'1px solid black',width:30}}>{currentService.fixes.trim.anillo.ok ? <i className="fa fa-stop"></i> : '' }</td>
                                  <td colSpan={1} style={{border:'1px solid black',width:30}}>{currentService.fixes.trim.anillo.des ? <i className="fa fa-stop"></i> : '' }</td>
                                  <td colSpan={1} style={{border:'1px solid black',width:30}}>{currentService.fixes.trim.anillo.lav ? <i className="fa fa-stop"></i> : '' }</td>
                                  <td colSpan={1} style={{border:'1px solid black',width:30}}>{currentService.fixes.trim.anillo.pic ? <i className="fa fa-stop"></i> : '' }</td>
                                  <td colSpan={1} style={{border:'1px solid black',width:30}}>{currentService.fixes.trim.anillo.ral ? <i className="fa fa-stop"></i> : '' }</td>
                                  <td colSpan={1} style={{border:'1px solid black',width:30}}>{currentService.fixes.trim.anillo.san ? <i className="fa fa-stop"></i> : '' }</td>
                                  <td colSpan={1} style={{border:'1px solid black',width:30}}>{currentService.fixes.trim.anillo.pul ? <i className="fa fa-stop"></i> : '' }</td>
                                  <td colSpan={1} style={{border:'1px solid black',width:30}}>{currentService.fixes.trim.anillo.sol ? <i className="fa fa-stop"></i> : '' }</td>
                                  <td colSpan={1} style={{border:'1px solid black',width:30}}>{currentService.fixes.trim.anillo.maq ? <i className="fa fa-stop"></i> : '' }</td>
                                  <td colSpan={1} style={{border:'1px solid black',width:30}}>{currentService.fixes.trim.anillo.rem ? <i className="fa fa-stop"></i> : '' }</td>
                                  <td colSpan={1} style={{border:'1px solid black',width:30}}></td>
                                </tr>
                                <tr>
                                  <td colSpan={1} style={{border:'1px solid black', textAlign:'start', paddingLeft:5}}>VASTAGO/FLECHA</td>
                                  <td colSpan={1} style={{border:'1px solid black',width:30}}>{currentService.fixes.trim.vf.ok ? <i className="fa fa-stop"></i> : '' }</td>
                                  <td colSpan={1} style={{border:'1px solid black',width:30}}>{currentService.fixes.trim.vf.des ? <i className="fa fa-stop"></i> : '' }</td>
                                  <td colSpan={1} style={{border:'1px solid black',width:30}}>{currentService.fixes.trim.vf.lav ? <i className="fa fa-stop"></i> : '' }</td>
                                  <td colSpan={1} style={{border:'1px solid black',width:30}}>{currentService.fixes.trim.vf.pic ? <i className="fa fa-stop"></i> : '' }</td>
                                  <td colSpan={1} style={{border:'1px solid black',width:30}}>{currentService.fixes.trim.vf.ral ? <i className="fa fa-stop"></i> : '' }</td>
                                  <td colSpan={1} style={{border:'1px solid black',width:30}}>{currentService.fixes.trim.vf.san ? <i className="fa fa-stop"></i> : '' }</td>
                                  <td colSpan={1} style={{border:'1px solid black',width:30}}>{currentService.fixes.trim.vf.pul ? <i className="fa fa-stop"></i> : '' }</td>
                                  <td colSpan={1} style={{border:'1px solid black',width:30}}>{currentService.fixes.trim.vf.sol ? <i className="fa fa-stop"></i> : '' }</td>
                                  <td colSpan={1} style={{border:'1px solid black',width:30}}>{currentService.fixes.trim.vf.maq ? <i className="fa fa-stop"></i> : '' }</td>
                                  <td colSpan={1} style={{border:'1px solid black',width:30}}>{currentService.fixes.trim.vf.rem ? <i className="fa fa-stop"></i> : '' }</td>
                                  <td colSpan={1} style={{border:'1px solid black',width:30}}></td>
                                </tr>
                                <tr>
                                  <td colSpan={1} style={{border:'1px solid black', textAlign:'start', paddingLeft:5}}>SEAL RING/PISTON RING</td>
                                  <td colSpan={1} style={{border:'1px solid black',width:30}}>{currentService.fixes.trim.sp.ok ? <i className="fa fa-stop"></i> : '' }</td>
                                  <td colSpan={1} style={{border:'1px solid black',width:30}}>{currentService.fixes.trim.sp.des ? <i className="fa fa-stop"></i> : '' }</td>
                                  <td colSpan={1} style={{border:'1px solid black',width:30}}>{currentService.fixes.trim.sp.lav ? <i className="fa fa-stop"></i> : '' }</td>
                                  <td colSpan={1} style={{border:'1px solid black',width:30}}>{currentService.fixes.trim.sp.pic ? <i className="fa fa-stop"></i> : '' }</td>
                                  <td colSpan={1} style={{border:'1px solid black',width:30}}>{currentService.fixes.trim.sp.ral ? <i className="fa fa-stop"></i> : '' }</td>
                                  <td colSpan={1} style={{border:'1px solid black',width:30}}>{currentService.fixes.trim.sp.san ? <i className="fa fa-stop"></i> : '' }</td>
                                  <td colSpan={1} style={{border:'1px solid black',width:30}}>{currentService.fixes.trim.sp.pul ? <i className="fa fa-stop"></i> : '' }</td>
                                  <td colSpan={1} style={{border:'1px solid black',width:30}}>{currentService.fixes.trim.sp.sol ? <i className="fa fa-stop"></i> : '' }</td>
                                  <td colSpan={1} style={{border:'1px solid black',width:30}}>{currentService.fixes.trim.sp.maq ? <i className="fa fa-stop"></i> : '' }</td>
                                  <td colSpan={1} style={{border:'1px solid black',width:30}}>{currentService.fixes.trim.sp.rem ? <i className="fa fa-stop"></i> : '' }</td>
                                  <td colSpan={1} style={{border:'1px solid black',width:30}}></td>
                                </tr>
                                <tr>
                                  <td colSpan={1} style={{border:'1px solid black', textAlign:'start', paddingLeft:5}}>BUJES</td>
                                  <td colSpan={1} style={{border:'1px solid black',width:30}}>{currentService.fixes.trim.bujes.ok ? <i className="fa fa-stop"></i> : '' }</td>
                                  <td colSpan={1} style={{border:'1px solid black',width:30}}>{currentService.fixes.trim.bujes.des ? <i className="fa fa-stop"></i> : '' }</td>
                                  <td colSpan={1} style={{border:'1px solid black',width:30}}>{currentService.fixes.trim.bujes.lav ? <i className="fa fa-stop"></i> : '' }</td>
                                  <td colSpan={1} style={{border:'1px solid black',width:30}}>{currentService.fixes.trim.bujes.pic ? <i className="fa fa-stop"></i> : '' }</td>
                                  <td colSpan={1} style={{border:'1px solid black',width:30}}>{currentService.fixes.trim.bujes.ral ? <i className="fa fa-stop"></i> : '' }</td>
                                  <td colSpan={1} style={{border:'1px solid black',width:30}}>{currentService.fixes.trim.bujes.san ? <i className="fa fa-stop"></i> : '' }</td>
                                  <td colSpan={1} style={{border:'1px solid black',width:30}}>{currentService.fixes.trim.bujes.pul ? <i className="fa fa-stop"></i> : '' }</td>
                                  <td colSpan={1} style={{border:'1px solid black',width:30}}>{currentService.fixes.trim.bujes.sol ? <i className="fa fa-stop"></i> : '' }</td>
                                  <td colSpan={1} style={{border:'1px solid black',width:30}}>{currentService.fixes.trim.bujes.maq ? <i className="fa fa-stop"></i> : '' }</td>
                                  <td colSpan={1} style={{border:'1px solid black',width:30}}>{currentService.fixes.trim.bujes.rem ? <i className="fa fa-stop"></i> : '' }</td>
                                  <td colSpan={1} style={{border:'1px solid black',width:30}}></td>
                                </tr>
                              </table>
                            </td>
                            <td style={{textAlign:'center', paddingLeft:10, maxWidth:200, minWidth:200}}>NOTAS Y COMENTARIOS:<br/> {currentService.fixes.trim.notes}</td>
                          </tr>
                          <tr>
                            <td colSpan={2} style={{border:'2px solid black',fontWeight: 'bold', backgroundColor:'#d4d4d4'}}>CONDICIONES DEL ACTUADOR Y REPARACIONES REALIZADAS</td>
                          </tr>
                          <tr style={{border:'2px solid black'}}>
                            <td>
                              <table style={{width:'100%'}} border={1}>
                                <tr>
                                  <td colSpan={1} style={{border:'1px solid black'}}></td>
                                  <td colSpan={1} style={{border:'1px solid black', writingMode: 'vertical-rl', textOrientation:'upright', width:30, textAlign:'end', fontSize:11}}>OK</td>
                                  <td colSpan={1} style={{border:'1px solid black', writingMode: 'vertical-rl', textOrientation:'upright', width:30, textAlign:'end', fontSize:11}}>DESGASTADO</td>
                                  <td colSpan={1} style={{border:'1px solid black', writingMode: 'vertical-rl', textOrientation:'upright', width:30, textAlign:'end', fontSize:11}}>LAVADO</td>
                                  <td colSpan={1} style={{border:'1px solid black', writingMode: 'vertical-rl', textOrientation:'upright', width:30, textAlign:'end', fontSize:11}}>PICADO</td>
                                  <td colSpan={1} style={{border:'1px solid black', writingMode: 'vertical-rl', textOrientation:'upright', width:30, textAlign:'end', fontSize:11}}>RALLADO</td>
                                  <td colSpan={1} style={{border:'1px solid black', writingMode: 'vertical-rl', textOrientation:'upright', width:30, textAlign:'end', fontSize:11}}>SANDBLASTEADO</td>
                                  <td colSpan={1} style={{border:'1px solid black', writingMode: 'vertical-rl', textOrientation:'upright', width:30, textAlign:'end', fontSize:11}}>PULIDO</td>
                                  <td colSpan={1} style={{border:'1px solid black', writingMode: 'vertical-rl', textOrientation:'upright', width:30, textAlign:'end', fontSize:11}}>SOLDADO</td>
                                  <td colSpan={1} style={{border:'1px solid black', writingMode: 'vertical-rl', textOrientation:'upright', width:30, textAlign:'end', fontSize:11}}>MAQUINADO</td>
                                  <td colSpan={1} style={{border:'1px solid black', writingMode: 'vertical-rl', textOrientation:'upright', width:30, textAlign:'end', fontSize:11}}>REMPLAZADO</td>
                                  <td colSpan={1} style={{border:'1px solid black', writingMode: 'vertical-rl', textOrientation:'upright', width:30, textAlign:'end', fontSize:11}}>DETALLADO</td>
                                </tr>
                                <tr>
                                  <td colSpan={1} style={{border:'1px solid black', textAlign:'start', paddingLeft:5}}>TAPAS</td>
                                  <td colSpan={1} style={{border:'1px solid black',width:30}}>{currentService.fixes.actor.tapas.ok ? <i className="fa fa-stop"></i> : '' }</td>
                                  <td colSpan={1} style={{border:'1px solid black',width:30}}>{currentService.fixes.actor.tapas.des ? <i className="fa fa-stop"></i> : '' }</td>
                                  <td colSpan={1} style={{border:'1px solid black',width:30}}>{currentService.fixes.actor.tapas.lav ? <i className="fa fa-stop"></i> : '' }</td>
                                  <td colSpan={1} style={{border:'1px solid black',width:30}}>{currentService.fixes.actor.tapas.pic ? <i className="fa fa-stop"></i> : '' }</td>
                                  <td colSpan={1} style={{border:'1px solid black',width:30}}>{currentService.fixes.actor.tapas.ral ? <i className="fa fa-stop"></i> : '' }</td>
                                  <td colSpan={1} style={{border:'1px solid black',width:30}}>{currentService.fixes.actor.tapas.san ? <i className="fa fa-stop"></i> : '' }</td>
                                  <td colSpan={1} style={{border:'1px solid black',width:30}}>{currentService.fixes.actor.tapas.pul ? <i className="fa fa-stop"></i> : '' }</td>
                                  <td colSpan={1} style={{border:'1px solid black',width:30}}>{currentService.fixes.actor.tapas.sol ? <i className="fa fa-stop"></i> : '' }</td>
                                  <td colSpan={1} style={{border:'1px solid black',width:30}}>{currentService.fixes.actor.tapas.maq ? <i className="fa fa-stop"></i> : '' }</td>
                                  <td colSpan={1} style={{border:'1px solid black',width:30}}>{currentService.fixes.actor.tapas.rem ? <i className="fa fa-stop"></i> : '' }</td>
                                  <td colSpan={1} style={{border:'1px solid black',width:30}}>{currentService.fixes.actor.tapas.det ? <i className="fa fa-stop"></i> : '' }</td>
                                </tr>
                                <tr>
                                  <td colSpan={1} style={{border:'1px solid black', textAlign:'start', paddingLeft:5}}>YUGO</td>
                                  <td colSpan={1} style={{border:'1px solid black',width:30}}>{currentService.fixes.actor.yugo.ok ? <i className="fa fa-stop"></i> : '' }</td>
                                  <td colSpan={1} style={{border:'1px solid black',width:30}}>{currentService.fixes.actor.yugo.des ? <i className="fa fa-stop"></i> : '' }</td>
                                  <td colSpan={1} style={{border:'1px solid black',width:30}}>{currentService.fixes.actor.yugo.lav ? <i className="fa fa-stop"></i> : '' }</td>
                                  <td colSpan={1} style={{border:'1px solid black',width:30}}>{currentService.fixes.actor.yugo.pic ? <i className="fa fa-stop"></i> : '' }</td>
                                  <td colSpan={1} style={{border:'1px solid black',width:30}}>{currentService.fixes.actor.yugo.ral ? <i className="fa fa-stop"></i> : '' }</td>
                                  <td colSpan={1} style={{border:'1px solid black',width:30}}>{currentService.fixes.actor.yugo.san ? <i className="fa fa-stop"></i> : '' }</td>
                                  <td colSpan={1} style={{border:'1px solid black',width:30}}>{currentService.fixes.actor.yugo.pul ? <i className="fa fa-stop"></i> : '' }</td>
                                  <td colSpan={1} style={{border:'1px solid black',width:30}}>{currentService.fixes.actor.yugo.sol ? <i className="fa fa-stop"></i> : '' }</td>
                                  <td colSpan={1} style={{border:'1px solid black',width:30}}>{currentService.fixes.actor.yugo.maq ? <i className="fa fa-stop"></i> : '' }</td>
                                  <td colSpan={1} style={{border:'1px solid black',width:30}}>{currentService.fixes.actor.yugo.rem ? <i className="fa fa-stop"></i> : '' }</td>
                                  <td colSpan={1} style={{border:'1px solid black',width:30}}>{currentService.fixes.actor.yugo.det ? <i className="fa fa-stop"></i> : '' }</td>
                                </tr>
                                <tr>
                                  <td colSpan={1} style={{border:'1px solid black', textAlign:'start', paddingLeft:5}}>FLECHA</td>
                                  <td colSpan={1} style={{border:'1px solid black',width:30}}>{currentService.fixes.actor.flecha.ok ? <i className="fa fa-stop"></i> : '' }</td>
                                  <td colSpan={1} style={{border:'1px solid black',width:30}}>{currentService.fixes.actor.flecha.des ? <i className="fa fa-stop"></i> : '' }</td>
                                  <td colSpan={1} style={{border:'1px solid black',width:30}}>{currentService.fixes.actor.flecha.lav ? <i className="fa fa-stop"></i> : '' }</td>
                                  <td colSpan={1} style={{border:'1px solid black',width:30}}>{currentService.fixes.actor.flecha.pic ? <i className="fa fa-stop"></i> : '' }</td>
                                  <td colSpan={1} style={{border:'1px solid black',width:30}}>{currentService.fixes.actor.flecha.ral ? <i className="fa fa-stop"></i> : '' }</td>
                                  <td colSpan={1} style={{border:'1px solid black',width:30}}>{currentService.fixes.actor.flecha.san ? <i className="fa fa-stop"></i> : '' }</td>
                                  <td colSpan={1} style={{border:'1px solid black',width:30}}>{currentService.fixes.actor.flecha.pul ? <i className="fa fa-stop"></i> : '' }</td>
                                  <td colSpan={1} style={{border:'1px solid black',width:30}}>{currentService.fixes.actor.flecha.sol ? <i className="fa fa-stop"></i> : '' }</td>
                                  <td colSpan={1} style={{border:'1px solid black',width:30}}>{currentService.fixes.actor.flecha.maq ? <i className="fa fa-stop"></i> : '' }</td>
                                  <td colSpan={1} style={{border:'1px solid black',width:30}}>{currentService.fixes.actor.flecha.rem ? <i className="fa fa-stop"></i> : '' }</td>
                                  <td colSpan={1} style={{border:'1px solid black',width:30}}>{currentService.fixes.actor.flecha.det ? <i className="fa fa-stop"></i> : '' }</td>
                                </tr>
                                <tr>
                                  <td colSpan={1} style={{border:'1px solid black', textAlign:'start', paddingLeft:5}}>DIAFRAGMA</td>
                                  <td colSpan={1} style={{border:'1px solid black',width:30}}>{currentService.fixes.actor.diafragma.ok ? <i className="fa fa-stop"></i> : '' }</td>
                                  <td colSpan={1} style={{border:'1px solid black',width:30}}>{currentService.fixes.actor.diafragma.des ? <i className="fa fa-stop"></i> : '' }</td>
                                  <td colSpan={1} style={{border:'1px solid black',width:30}}>{currentService.fixes.actor.diafragma.lav ? <i className="fa fa-stop"></i> : '' }</td>
                                  <td colSpan={1} style={{border:'1px solid black',width:30}}>{currentService.fixes.actor.diafragma.pic ? <i className="fa fa-stop"></i> : '' }</td>
                                  <td colSpan={1} style={{border:'1px solid black',width:30}}>{currentService.fixes.actor.diafragma.ral ? <i className="fa fa-stop"></i> : '' }</td>
                                  <td colSpan={1} style={{border:'1px solid black',width:30}}>{currentService.fixes.actor.diafragma.san ? <i className="fa fa-stop"></i> : '' }</td>
                                  <td colSpan={1} style={{border:'1px solid black',width:30}}>{currentService.fixes.actor.diafragma.pul ? <i className="fa fa-stop"></i> : '' }</td>
                                  <td colSpan={1} style={{border:'1px solid black',width:30}}>{currentService.fixes.actor.diafragma.sol ? <i className="fa fa-stop"></i> : '' }</td>
                                  <td colSpan={1} style={{border:'1px solid black',width:30}}>{currentService.fixes.actor.diafragma.maq ? <i className="fa fa-stop"></i> : '' }</td>
                                  <td colSpan={1} style={{border:'1px solid black',width:30}}>{currentService.fixes.actor.diafragma.rem ? <i className="fa fa-stop"></i> : '' }</td>
                                  <td colSpan={1} style={{border:'1px solid black',width:30}}>{currentService.fixes.actor.diafragma.det ? <i className="fa fa-stop"></i> : '' }</td>
                                </tr>
                                <tr>
                                  <td colSpan={1} style={{border:'1px solid black', textAlign:'start', paddingLeft:5}}>SELLOS/O-RING</td>
                                  <td colSpan={1} style={{border:'1px solid black',width:30}}>{currentService.fixes.actor.sellos.ok ? <i className="fa fa-stop"></i> : '' }</td>
                                  <td colSpan={1} style={{border:'1px solid black',width:30}}>{currentService.fixes.actor.sellos.des ? <i className="fa fa-stop"></i> : '' }</td>
                                  <td colSpan={1} style={{border:'1px solid black',width:30}}>{currentService.fixes.actor.sellos.lav ? <i className="fa fa-stop"></i> : '' }</td>
                                  <td colSpan={1} style={{border:'1px solid black',width:30}}>{currentService.fixes.actor.sellos.pic ? <i className="fa fa-stop"></i> : '' }</td>
                                  <td colSpan={1} style={{border:'1px solid black',width:30}}>{currentService.fixes.actor.sellos.ral ? <i className="fa fa-stop"></i> : '' }</td>
                                  <td colSpan={1} style={{border:'1px solid black',width:30}}>{currentService.fixes.actor.sellos.san ? <i className="fa fa-stop"></i> : '' }</td>
                                  <td colSpan={1} style={{border:'1px solid black',width:30}}>{currentService.fixes.actor.sellos.pul ? <i className="fa fa-stop"></i> : '' }</td>
                                  <td colSpan={1} style={{border:'1px solid black',width:30}}>{currentService.fixes.actor.sellos.sol ? <i className="fa fa-stop"></i> : '' }</td>
                                  <td colSpan={1} style={{border:'1px solid black',width:30}}>{currentService.fixes.actor.sellos.maq ? <i className="fa fa-stop"></i> : '' }</td>
                                  <td colSpan={1} style={{border:'1px solid black',width:30}}>{currentService.fixes.actor.sellos.rem ? <i className="fa fa-stop"></i> : '' }</td>
                                  <td colSpan={1} style={{border:'1px solid black',width:30}}>{currentService.fixes.actor.sellos.det ? <i className="fa fa-stop"></i> : '' }</td>
                                </tr>
                                <tr>
                                  <td colSpan={1} style={{border:'1px solid black', textAlign:'start', paddingLeft:5}}>TORNILLERIA</td>
                                  <td colSpan={1} style={{border:'1px solid black',width:30}}>{currentService.fixes.actor.tornilleria.ok ? <i className="fa fa-stop"></i> : '' }</td>
                                  <td colSpan={1} style={{border:'1px solid black',width:30}}>{currentService.fixes.actor.tornilleria.des ? <i className="fa fa-stop"></i> : '' }</td>
                                  <td colSpan={1} style={{border:'1px solid black',width:30}}>{currentService.fixes.actor.tornilleria.lav ? <i className="fa fa-stop"></i> : '' }</td>
                                  <td colSpan={1} style={{border:'1px solid black',width:30}}>{currentService.fixes.actor.tornilleria.pic ? <i className="fa fa-stop"></i> : '' }</td>
                                  <td colSpan={1} style={{border:'1px solid black',width:30}}>{currentService.fixes.actor.tornilleria.ral ? <i className="fa fa-stop"></i> : '' }</td>
                                  <td colSpan={1} style={{border:'1px solid black',width:30}}>{currentService.fixes.actor.tornilleria.san ? <i className="fa fa-stop"></i> : '' }</td>
                                  <td colSpan={1} style={{border:'1px solid black',width:30}}>{currentService.fixes.actor.tornilleria.pul ? <i className="fa fa-stop"></i> : '' }</td>
                                  <td colSpan={1} style={{border:'1px solid black',width:30}}>{currentService.fixes.actor.tornilleria.sol ? <i className="fa fa-stop"></i> : '' }</td>
                                  <td colSpan={1} style={{border:'1px solid black',width:30}}>{currentService.fixes.actor.tornilleria.maq ? <i className="fa fa-stop"></i> : '' }</td>
                                  <td colSpan={1} style={{border:'1px solid black',width:30}}>{currentService.fixes.actor.tornilleria.rem ? <i className="fa fa-stop"></i> : '' }</td>
                                  <td colSpan={1} style={{border:'1px solid black',width:30}}>{currentService.fixes.actor.tornilleria.det ? <i className="fa fa-stop"></i> : '' }</td>
                                </tr>
                                <tr>
                                  <td colSpan={1} style={{border:'1px solid black', textAlign:'start', paddingLeft:5}}>PLACA DE IDENTIFICACION</td>
                                  <td colSpan={1} style={{border:'1px solid black',width:30}}>{currentService.fixes.actor.placa.ok ? <i className="fa fa-stop"></i> : '' }</td>
                                  <td colSpan={1} style={{border:'1px solid black',width:30}}>{currentService.fixes.actor.placa.des ? <i className="fa fa-stop"></i> : '' }</td>
                                  <td colSpan={1} style={{border:'1px solid black',width:30}}>{currentService.fixes.actor.placa.lav ? <i className="fa fa-stop"></i> : '' }</td>
                                  <td colSpan={1} style={{border:'1px solid black',width:30}}>{currentService.fixes.actor.placa.pic ? <i className="fa fa-stop"></i> : '' }</td>
                                  <td colSpan={1} style={{border:'1px solid black',width:30}}>{currentService.fixes.actor.placa.ral ? <i className="fa fa-stop"></i> : '' }</td>
                                  <td colSpan={1} style={{border:'1px solid black',width:30}}>{currentService.fixes.actor.placa.san ? <i className="fa fa-stop"></i> : '' }</td>
                                  <td colSpan={1} style={{border:'1px solid black',width:30}}>{currentService.fixes.actor.placa.pul ? <i className="fa fa-stop"></i> : '' }</td>
                                  <td colSpan={1} style={{border:'1px solid black',width:30}}>{currentService.fixes.actor.placa.sol ? <i className="fa fa-stop"></i> : '' }</td>
                                  <td colSpan={1} style={{border:'1px solid black',width:30}}>{currentService.fixes.actor.placa.maq ? <i className="fa fa-stop"></i> : '' }</td>
                                  <td colSpan={1} style={{border:'1px solid black',width:30}}>{currentService.fixes.actor.placa.rem ? <i className="fa fa-stop"></i> : '' }</td>
                                  <td colSpan={1} style={{border:'1px solid black',width:30}}>{currentService.fixes.actor.placa.det ? <i className="fa fa-stop"></i> : '' }</td>
                                </tr>
                                <tr>
                                  <td colSpan={1} style={{border:'1px solid black', textAlign:'start', paddingLeft:5}}>PRUEBA DE BLOQUEO</td>
                                  <td colSpan={1} style={{border:'1px solid black',width:30}}></td>
                                  <td colSpan={3} style={{border:'1px solid black',width:30}}>PASO</td>
                                  <td colSpan={1} style={{border:'1px solid black',width:30}}>{currentService.fixes.actor.prueba.paso ? <i className="fa fa-stop"></i> : '' }</td>
                                  <td colSpan={1} style={{border:'1px solid black',width:30}}></td>
                                  <td colSpan={3} style={{border:'1px solid black',width:30}}>FALLO</td>
                                  <td colSpan={1} style={{border:'1px solid black',width:30}}>{!currentService.fixes.actor.prueba.paso ? <i className="fa fa-stop"></i> : '' }</td>
                                  <td colSpan={1} style={{border:'1px solid black',width:30}}></td>
                                </tr>
                              </table>
                            </td>
                            <td style={{textAlign:'center', paddingLeft:10, maxWidth:200, minWidth:200}}>NOTAS Y COMENTARIOS:<br/> {currentService.fixes.actor.notes}</td>
                          </tr>
                          <tr>
                            <td colSpan={2} style={{border:'2px solid black',fontWeight: 'bold', backgroundColor:'#d4d4d4'}}>CONDICIONES DE LOS ACCESORIOS Y REPARACIONES REALIZADAS</td>
                          </tr>
                          <tr style={{border:'2px solid black'}}>
                            <td>
                              <table style={{width:'100%'}} border={1}>
                                <tr>
                                  <td colSpan={1} style={{border:'1px solid black'}}></td>
                                  <td colSpan={1} style={{border:'1px solid black', writingMode: 'vertical-rl', textOrientation:'upright', width:30, textAlign:'end', fontSize:11}}>REPARADO</td>
                                  <td colSpan={1} style={{border:'1px solid black', writingMode: 'vertical-rl', textOrientation:'upright', width:30, textAlign:'end', fontSize:11}}>REEMPLAZADO</td>
                                  <td colSpan={1} style={{border:'1px solid black', writingMode: 'vertical-rl', textOrientation:'upright', width:30, textAlign:'end', fontSize:11}}>REUSADO</td>
                                  <td colSpan={1} style={{border:'1px solid black', writingMode: 'vertical-rl', textOrientation:'upright', width:30, textAlign:'end', fontSize:11}}></td>
                                  <td colSpan={1} style={{border:'1px solid black', writingMode: 'vertical-rl', textOrientation:'upright', width:30, textAlign:'end', fontSize:11}}></td>
                                  <td colSpan={1} style={{border:'1px solid black', writingMode: 'vertical-rl', textOrientation:'upright', width:30, textAlign:'end', fontSize:11}}></td>
                                  <td colSpan={1} style={{border:'1px solid black', writingMode: 'vertical-rl', textOrientation:'upright', width:30, textAlign:'end', fontSize:11}}></td>
                                  <td colSpan={1} style={{border:'1px solid black', writingMode: 'vertical-rl', textOrientation:'upright', width:30, textAlign:'end', fontSize:11}}>REPARADO</td>
                                  <td colSpan={1} style={{border:'1px solid black', writingMode: 'vertical-rl', textOrientation:'upright', width:30, textAlign:'end', fontSize:11}}>REEMPLAZADO</td>
                                  <td colSpan={1} style={{border:'1px solid black', writingMode: 'vertical-rl', textOrientation:'upright', width:30, textAlign:'end', fontSize:11}}>REUSADO</td>
                                  <td colSpan={1} style={{border:'1px solid black', writingMode: 'vertical-rl', textOrientation:'upright', width:30, textAlign:'end', fontSize:11}}></td>
                                </tr>
                                <tr>
                                  <td colSpan={1} style={{border:'1px solid black', textAlign:'start', paddingLeft:5}}>POSICIONADOR</td>
                                  <td colSpan={1} style={{border:'1px solid black',width:30}}>{currentService.fixes.otherAccesories.posicionador.rep ? <i className="fa fa-stop"></i> : '' }</td>
                                  <td colSpan={1} style={{border:'1px solid black',width:30}}>{currentService.fixes.otherAccesories.posicionador.ree ? <i className="fa fa-stop"></i> : '' }</td>
                                  <td colSpan={1} style={{border:'1px solid black',width:30}}>{currentService.fixes.otherAccesories.posicionador.reu ? <i className="fa fa-stop"></i> : '' }</td>
                                  <td colSpan={4} style={{border:'1px solid black',width:30}}>SWITCH</td>
                                  <td colSpan={1} style={{border:'1px solid black',width:30}}>{currentService.fixes.otherAccesories.switch.rep ? <i className="fa fa-stop"></i> : '' }</td>
                                  <td colSpan={1} style={{border:'1px solid black',width:30}}>{currentService.fixes.otherAccesories.switch.ree ? <i className="fa fa-stop"></i> : '' }</td>
                                  <td colSpan={1} style={{border:'1px solid black',width:30}}>{currentService.fixes.otherAccesories.switch.reu ? <i className="fa fa-stop"></i> : '' }</td>
                                </tr>
                                <tr>
                                  <td colSpan={1} style={{border:'1px solid black', textAlign:'start', paddingLeft:5}}>BYPASS</td>
                                  <td colSpan={1} style={{border:'1px solid black',width:30}}>{currentService.fixes.otherAccesories.bypass.rep ? <i className="fa fa-stop"></i> : '' }</td>
                                  <td colSpan={1} style={{border:'1px solid black',width:30}}>{currentService.fixes.otherAccesories.bypass.ree ? <i className="fa fa-stop"></i> : '' }</td>
                                  <td colSpan={1} style={{border:'1px solid black',width:30}}>{currentService.fixes.otherAccesories.bypass.reu ? <i className="fa fa-stop"></i> : '' }</td>
                                  <td colSpan={4} style={{border:'1px solid black',width:30}}>SOLENOIDE</td>
                                  <td colSpan={1} style={{border:'1px solid black',width:30}}>{currentService.fixes.otherAccesories.solenoide.rep ? <i className="fa fa-stop"></i> : '' }</td>
                                  <td colSpan={1} style={{border:'1px solid black',width:30}}>{currentService.fixes.otherAccesories.solenoide.ree ? <i className="fa fa-stop"></i> : '' }</td>
                                  <td colSpan={1} style={{border:'1px solid black',width:30}}>{currentService.fixes.otherAccesories.solenoide.reu ? <i className="fa fa-stop"></i> : '' }</td>
                                </tr>
                                <tr>
                                  <td colSpan={1} style={{border:'1px solid black', textAlign:'start', paddingLeft:5}}>I/P</td>
                                  <td colSpan={1} style={{border:'1px solid black',width:30}}>{currentService.fixes.otherAccesories.ip.rep ? <i className="fa fa-stop"></i> : '' }</td>
                                  <td colSpan={1} style={{border:'1px solid black',width:30}}>{currentService.fixes.otherAccesories.ip.ree ? <i className="fa fa-stop"></i> : '' }</td>
                                  <td colSpan={1} style={{border:'1px solid black',width:30}}>{currentService.fixes.otherAccesories.ip.reu ? <i className="fa fa-stop"></i> : '' }</td>
                                  <td colSpan={4} style={{border:'1px solid black',width:30}}>VOLANTE</td>
                                  <td colSpan={1} style={{border:'1px solid black',width:30}}>{currentService.fixes.otherAccesories.volante.rep ? <i className="fa fa-stop"></i> : '' }</td>
                                  <td colSpan={1} style={{border:'1px solid black',width:30}}>{currentService.fixes.otherAccesories.volante.ree ? <i className="fa fa-stop"></i> : '' }</td>
                                  <td colSpan={1} style={{border:'1px solid black',width:30}}>{currentService.fixes.otherAccesories.volante.reu ? <i className="fa fa-stop"></i> : '' }</td>
                                </tr>
                                <tr>
                                  <td colSpan={1} style={{border:'1px solid black', textAlign:'start', paddingLeft:5}}>TRIP VALVE</td>
                                  <td colSpan={1} style={{border:'1px solid black',width:30}}>{currentService.fixes.otherAccesories.trip.rep ? <i className="fa fa-stop"></i> : '' }</td>
                                  <td colSpan={1} style={{border:'1px solid black',width:30}}>{currentService.fixes.otherAccesories.trip.ree ? <i className="fa fa-stop"></i> : '' }</td>
                                  <td colSpan={1} style={{border:'1px solid black',width:30}}>{currentService.fixes.otherAccesories.trip.reu ? <i className="fa fa-stop"></i> : '' }</td>
                                  <td colSpan={4} style={{border:'1px solid black',width:30}}>TUBING</td>
                                  <td colSpan={1} style={{border:'1px solid black',width:30}}>{currentService.fixes.otherAccesories.tubing.rep ? <i className="fa fa-stop"></i> : '' }</td>
                                  <td colSpan={1} style={{border:'1px solid black',width:30}}>{currentService.fixes.otherAccesories.tubing.ree ? <i className="fa fa-stop"></i> : '' }</td>
                                  <td colSpan={1} style={{border:'1px solid black',width:30}}>{currentService.fixes.otherAccesories.tubing.reu ? <i className="fa fa-stop"></i> : '' }</td>
                                </tr>
                                <tr>
                                  <td colSpan={1} style={{border:'1px solid black', textAlign:'start', paddingLeft:5}}>FILTRO REGULADOR</td>
                                  <td colSpan={1} style={{border:'1px solid black',width:30}}>{currentService.fixes.otherAccesories.filtro.rep ? <i className="fa fa-stop"></i> : '' }</td>
                                  <td colSpan={1} style={{border:'1px solid black',width:30}}>{currentService.fixes.otherAccesories.filtro.ree ? <i className="fa fa-stop"></i> : '' }</td>
                                  <td colSpan={1} style={{border:'1px solid black',width:30}}>{currentService.fixes.otherAccesories.filtro.reu ? <i className="fa fa-stop"></i> : '' }</td>
                                  <td colSpan={4} style={{border:'1px solid black',width:30}}>FITTINGS</td>
                                  <td colSpan={1} style={{border:'1px solid black',width:30}}>{currentService.fixes.otherAccesories.fittings.rep ? <i className="fa fa-stop"></i> : '' }</td>
                                  <td colSpan={1} style={{border:'1px solid black',width:30}}>{currentService.fixes.otherAccesories.fittings.ree ? <i className="fa fa-stop"></i> : '' }</td>
                                  <td colSpan={1} style={{border:'1px solid black',width:30}}>{currentService.fixes.otherAccesories.fittings.reu ? <i className="fa fa-stop"></i> : '' }</td>
                                </tr>
                                <tr>
                                  <td colSpan={1} style={{border:'1px solid black', textAlign:'start', paddingLeft:5}}>MANOMETROS</td>
                                  <td colSpan={1} style={{border:'1px solid black',width:30}}>{currentService.fixes.otherAccesories.manometro.rep ? <i className="fa fa-stop"></i> : '' }</td>
                                  <td colSpan={1} style={{border:'1px solid black',width:30}}>{currentService.fixes.otherAccesories.manometro.ree ? <i className="fa fa-stop"></i> : '' }</td>
                                  <td colSpan={1} style={{border:'1px solid black',width:30}}>{currentService.fixes.otherAccesories.manometro.reu ? <i className="fa fa-stop"></i> : '' }</td>
                                  <td colSpan={4} style={{border:'1px solid black',width:30}}>OTRO</td>
                                  <td colSpan={1} style={{border:'1px solid black',width:30}}>{currentService.fixes.otherAccesories.meta.rep ? <i className="fa fa-stop"></i> : '' }</td>
                                  <td colSpan={1} style={{border:'1px solid black',width:30}}>{currentService.fixes.otherAccesories.meta.ree ? <i className="fa fa-stop"></i> : '' }</td>
                                  <td colSpan={1} style={{border:'1px solid black',width:30}}>{currentService.fixes.otherAccesories.meta.reu ? <i className="fa fa-stop"></i> : '' }</td>
                                </tr>
                              </table>
                            </td>
                            <td style={{textAlign:'center', paddingLeft:10, maxWidth:200, minWidth:200}}>NOTAS Y COMENTARIOS:<br/> {currentService.fixes.otherAccesories.notes}</td>
                          </tr>
                        </table> : null}
                      </Row> 
                    </>
                  : null }

                  <Row className="mb-3 p-4">
                    <table>
                      <tr>
                        <td colSpan={2} style={{border:'2px solid black',fontWeight:'bold',backgroundColor:'#d4d4d4'}}>{currentService.type !== "NEW" ? 'FOTOS DE REPARACION DE LA VALVULA' : 'FOTOS DEL ENSAMBLE' }</td>
                      </tr>
                      <tr>
                        <td style={{border:'1px solid black', padding:10}}>
                          <PhotoAlbum photos={photos} layout="rows" targetRowHeight={150} onClick={({ index }) => setIndex(index)} />

                          <Lightbox
                            slides={photos}
                            open={index >= 0}
                            index={index}
                            close={() => setIndex(-1)}
                            // enable optional lightbox plugins
                            plugins={[Fullscreen, Slideshow, Zoom]}
                          />
                        </td>
                      </tr>
                    </table>
                  </Row>

                  <Row className="mb-3 p-4">
                    {
                      hydros.map((hydro)=>{
                        return(
                          <div className="mb-3">
                            <img width={'100%'} src={hydro} />
                          </div>
                        )
                      })
                    }
                  </Row>
                </div>
              </CardBody>
            : null }
            </Col>
          </Row>
        </div>

        <div className="hidePrint" onClick={handlePrint} style={{position: 'fixed', fontWeight:'bold', background: 'black', padding: 15, borderRadius: 50, color: 'white', width: 60, height:60, right: 30, bottom: 30, cursor: 'pointer', zIndex:100}}>
          <Printer size={30} />
        </div>
      </Container>
    </div>
  );
};

export default Report;
